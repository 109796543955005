@charset "UTF-8";
.ht-drawer {
  margin-top: 60px;
  height: 95vh !important;
  overflow-y: scroll;
  padding-bottom: 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: #f4f4f4;
  /* height: calc(100vh -184px) !important; */
}
.ht-drawer .el-drawer__body {
  overflow: auto;
  height: 95vh !important;
  /* height: calc(100vh -184px) !important; */
}

/* 定义keyframe动画，命名为blink */
@-webkit-keyframes blink {
0% {
    opacity: 1;
}
50% {
    opacity: 1;
}
50.01% {
    opacity: 0;
}
100% {
    opacity: 0;
}
}
@keyframes blink {
0% {
    opacity: 1;
}
50% {
    opacity: 1;
}
50.01% {
    opacity: 0;
}
100% {
    opacity: 0;
}
}
.twinkle {
  -webkit-animation: blink 0.5s linear infinite;
          animation: blink 0.5s linear infinite;
}
.floating-box {
  position: absolute;
  width: 200px; /* 悬浮块宽度 */
  height: 150px; /* 悬浮块高度 */
  background-color: rgba(255, 255, 255, 0.7); /* 半透明背景色 */
  border: 1px solid #E4E7ED;
  cursor: move;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; /* 防止选中文本影响拖动 */
  -webkit-transition: width 0.3s, height 0.3s;
  transition: width 0.3s, height 0.3s; /* 平滑过渡效果 */
  z-index: 99999;
  border-radius: 4px;
}
.floating-box.expanded {
  width: 400px; /* 展开后的宽度 */
  height: 200px; /* 展开后的高度 */
}
.toggle-button {
  position: absolute;
  top: -10px;
  left: -10px;
  width: 20px;
  height: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  z-index: 99999;
}
.toggle-button span {
  font-size: 14px;
}
.box-content {
  height: 100%;
}
.noticeTitle {
  width: calc(100% - 12px);
  font-weight: bold;
  font-size: 16px;
  color: #FFF;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #EBEEF5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 6px;
}
.noticeContent {
  width: calc(100% - 12px);
  height: calc(100% - 53px);
  color: #444444;
  font-size: 14px;
  overflow-wrap: break-word;
  white-space: break-spaces;
  overflow: auto;
  background-color: #f4f6f8;
  padding: 6px;
}