.avue-top-sidebar[data-v-959343fe] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.topSidderLogo[data-v-959343fe] {
  width: 210px;
  height: 60px;
}
.topSidderLogo img[data-v-959343fe] {
  width: 100%;
  height: 100%;
}
[data-v-959343fe] .el-submenu__title {
  font-size: 16px;
}