
.el-dropdown-link[data-v-0d036b25] {
  cursor: pointer;
  color: #FFFFFF;
  font-size: 16px;
  line-height: 63px !important;
}
[data-v-0d036b25] .el-menu-item {
  font-size: 16px;
}
.menuItemBox[data-v-0d036b25]{
  max-width: 160px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #FFFFFF;
}
