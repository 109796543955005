.fade-leave-active {
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}
.fade-enter-active {
  -webkit-transition: opacity 2.5s;
  transition: opacity 2.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.avue-logo {
  position: fixed;
  top: 0;
  left: 0;
  width: 240px;
  height: 52px;
  line-height: 52px;
  background-color: #20222a;
  font-size: 20px;
  overflow: hidden;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
          box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.8);
  z-index: 1024;
}
.avue-logo_title {
  display: block;
  text-align: center;
  font-weight: 300;
  font-size: 20px;
}
.avue-logo_subtitle {
  display: block;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}