.suitem[data-v-32aed6c4] {
  width: 100%;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
}
.suitem img[data-v-32aed6c4] {
  margin-bottom: 3px;
  display: block;
}
.noticeTitle[data-v-32aed6c4] {
  width: 100%;
  color: #333333;
  font-weight: bold;
  text-align: center;
  line-height: 30px;
  font-size: 20px;
}
.list[data-v-32aed6c4] {
  margin-left: -40px;
}
.infinite-list-wrapper[data-v-32aed6c4] {
  height: 550px;
}
.list-item[data-v-32aed6c4] {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-bottom: 1px solid #f5f7f9;
  padding: 12px 0;
  cursor: pointer;
}
.messagePng[data-v-32aed6c4] {
  width: 36px;
  height: 36px;
}
.disflex[data-v-32aed6c4] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 60%;
  overflow: hidden;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.msgTile[data-v-32aed6c4] {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  color: #333;
  margin-bottom: 5px;
}
.msgContent[data-v-32aed6c4] {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
  color: #999;
}
.bellBox[data-v-32aed6c4] {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 42px;
  height: 42px;
  border-radius: 50px;
  text-align: center;
  background-color: #1890ff;
}
.bellBox i[data-v-32aed6c4] {
  font-size: 22px;
  color: #fff;
  line-height: 42px;
}
.UnreadBox[data-v-32aed6c4] {
  height: 20px;
  line-height: 20px;
  margin-bottom: 5px;
  margin-left: 55%;
}