.snows-editor-quill[data-v-d4466c5a] .ql-editor {
  min-height: 400px;
  white-space: normal !important;
}
.snows-editor-quill[data-v-d4466c5a] .ql-toolbar.ql-snow {
  border: 1px solid #dcdfe6;
  border-bottom: 0;
  border-radius: 4px 4px 0 0;
}
.snows-editor-quill[data-v-d4466c5a] .ql-container {
  white-space: pre-wrap !important;
}
.snows-editor-quill[data-v-d4466c5a] .ql-container .ql-snow {
  border: 1px solid #dcdfe6;
  border-radius: 0 0 4px 4px;
}