.tree-node[data-v-4684a4f4] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
[data-v-4684a4f4] .phoneBox .el-dialog__header .el-dialog__close {
  line-height: 40px;
}
[data-v-4684a4f4] .el-badge__content.is-fixed.is-dot {
  top: 23px;
}
.con .left[data-v-4684a4f4] {
  padding: 6px;
}
.con .left .conLeftImg[data-v-4684a4f4] {
  width: 88%;
  height: 550px;
}
.con .right[data-v-4684a4f4] {
  padding: 0 12px;
}
.con .right .name[data-v-4684a4f4] {
  font-size: 22px;
  color: #000;
  text-align: center;
  margin: 1.5rem 0;
}
.con .right .ewmIcon[data-v-4684a4f4] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.con .right .ewmIcon .iconItem[data-v-4684a4f4] {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  margin-bottom: 22px;
}
.con .right .ewmIcon .iconItem img[data-v-4684a4f4] {
  height: 70px;
  width: 70px;
}
.con .right .ewmIcon .iconItem p[data-v-4684a4f4] {
  text-align: center;
  font-size: 30px;
}
.con .right .ewmIcon .left[data-v-4684a4f4] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.con .right .ewmIcon .left .ewm[data-v-4684a4f4] {
  width: 200px;
  height: 200px;
  margin: 50px 0;
}
.topSidderLogo[data-v-4684a4f4] {
  width: 210px;
  height: 60px;
}
.topSidderLogo img[data-v-4684a4f4] {
  width: 100%;
  height: 100%;
}
.logo[data-v-4684a4f4] {
  width: 50px;
  height: 50px;
}
.topSearchInput[data-v-4684a4f4] {
  position: fixed;
  right: 560px;
  top: 0;
}
.v-enter[data-v-4684a4f4],
.v-leave-to[data-v-4684a4f4] {
  opacity: 0;
  -webkit-transform: translateX(100px);
          transform: translateX(100px);
}
.v-enter-active[data-v-4684a4f4],
.v-leave-active[data-v-4684a4f4] {
  -webkit-transition: all 0.6s ease;
  transition: all 0.6s ease;
}
.menu_title[data-v-4684a4f4] {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background: #f4f4f4;
}
.menu_title .menu_First_p[data-v-4684a4f4] {
  width: 100%;
  font-weight: 600;
  color: #000;
  font-size: 18px;
  float: left;
  line-height: 28px;
  cursor: pointer;
  margin: 10px;
  border-bottom: 1px dashed #babfc7;
}
.menu_title .menu_First_title[data-v-4684a4f4] {
  width: 50%;
  line-height: 48px;
  float: left;
}
.menu_title .menu_First_title span[data-v-4684a4f4] {
  display: inline-block;
  width: 100%;
  float: left;
  font-size: 14px;
  line-height: 48px;
  cursor: pointer;
  text-indent: 1em;
}
.menu_title .menu_fir_title[data-v-4684a4f4] {
  width: 100%;
  line-height: 28px;
  padding: 0px 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  float: left;
  border-top: 1px dashed #babfc7;
}
.menu_title .menu_fir_title p[data-v-4684a4f4] {
  width: 100%;
  font-weight: 600;
  color: #000;
  font-size: 18px;
  float: left;
  line-height: 28px;
  cursor: pointer;
  margin: 10px;
}
.menu_title .menu_fir_title .menu_sed_title[data-v-4684a4f4] {
  width: 50%;
  line-height: 32px;
  float: left;
}
.menu_title .menu_fir_title .menu_sed_title span[data-v-4684a4f4] {
  display: inline-block;
  width: 100%;
  float: left;
  font-size: 14px;
  line-height: 32px;
  cursor: pointer;
  text-indent: 1em;
}
.menu_thrid_title[data-v-4684a4f4] {
  width: 50%;
  line-height: 32px;
  float: left;
  /* background: red; */
  display: inline-block;
}
.menu_thrid_title span[data-v-4684a4f4] {
  display: inline-block;
  width: 100%;
  float: left;
  font-size: 14px;
  line-height: 32px;
  cursor: pointer;
  text-indent: 1em;
}
.card_posit[data-v-4684a4f4] {
  width: 100%;
  height: 100%;
  padding: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
}
.card_posit .person_top[data-v-4684a4f4] {
  padding: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  height: 76px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.card_posit .person_top .person_img[data-v-4684a4f4] {
  width: 52px;
  height: 52px;
  text-align: left;
}
.card_posit .person_top .person_img .image[data-v-4684a4f4] {
  width: 52px;
  height: 52px;
  border-radius: 50%;
}
.card_posit .person_top .person_title[data-v-4684a4f4] {
  padding-left: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: calc(100% - 76px);
  line-height: 28px;
  font-size: 14px;
  font-weight: bold;
  float: left;
  color: #333333;
}
.card_posit .person_top .person_title span[data-v-4684a4f4] {
  width: 100%;
  display: inline-block;
  float: left;
}
.card_posit .person_top .person_title .person_uid[data-v-4684a4f4] {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.card_posit .deptBox[data-v-4684a4f4] {
  width: 100%;
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 10px;
}
.card_posit .logout[data-v-4684a4f4] {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: calc(100% - 20px);
}
.sudoku_row[data-v-4684a4f4] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: 10px;
}
.sudoku_row .sudoku_item[data-v-4684a4f4] {
  cursor: pointer;
  outline: 1px solid #ebebeb;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 25%;
  padding-top: 10px;
  font-size: 12px;
  padding-bottom: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: #333333;
}
.sudoku_row .sudoku_item img[data-v-4684a4f4] {
  margin-bottom: 3px;
  display: block;
}
.sudoku_row .opacity[data-v-4684a4f4] {
  opacity: 0.4;
  background: #e5e5e5;
}
.tui-tab-item-title-active[data-v-4684a4f4] {
  color: #5677fc;
  font-size: 28rpx;
  font-weight: bold;
  border-bottom: 6rpx solid #5677fc;
}