.common_tree_box .common_tree_handle[data-v-7a83a7e4], .common_tree_box .common_tree_notag[data-v-7a83a7e4] {
  width: 280px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-right: 1px solid #cccccc;
  background: #ffffff;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.common_tree_box .common_tree_handle .el-tree[data-v-7a83a7e4], .common_tree_box .common_tree_notag .el-tree[data-v-7a83a7e4] {
  font-size: 12px;
  border-radius: 4px;
  padding: 0 5px 0 12px;
  overflow-y: scroll;
}
.common_tree_box .common_tree_handle .color-909399[data-v-7a83a7e4], .common_tree_box .common_tree_notag .color-909399[data-v-7a83a7e4] {
  color: #909399;
}
.common_tree_box .common_tree_handle .color-67C23A[data-v-7a83a7e4], .common_tree_box .common_tree_notag .color-67C23A[data-v-7a83a7e4] {
  color: #67C23A;
}
.common_tree_box .common_tree_handle .inputWidth[data-v-7a83a7e4], .common_tree_box .common_tree_notag .inputWidth[data-v-7a83a7e4] {
  width: calc(100% - 34px);
}
.common_tree_box .common_tree_handle .treeLabel[data-v-7a83a7e4], .common_tree_box .common_tree_notag .treeLabel[data-v-7a83a7e4] {
  margin-left: 5px;
}
.common_tree_box .common_tree_handle .searchInput[data-v-7a83a7e4], .common_tree_box .common_tree_notag .searchInput[data-v-7a83a7e4] {
  font-size: 12px;
}
.common_tree_box .common_tree_handle .searchInput[data-v-7a83a7e4] input, .common_tree_box .common_tree_notag .searchInput[data-v-7a83a7e4] input {
  height: 28px;
}
.common_tree_box .common_tree_handle .moreBtn[data-v-7a83a7e4], .common_tree_box .common_tree_notag .moreBtn[data-v-7a83a7e4] {
  position: absolute;
  top: 14px;
  right: 9px;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  padding: 7px;
}
.common_tree_box .header[data-v-7a83a7e4] {
  text-align: right;
  font-size: 12px;
  height: 46px;
  background-color: #ffffff;
  padding: 0 12px;
}
.common_tree_box .typeTitle[data-v-7a83a7e4] {
  font-size: 14px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.common_tree_box .header-row[data-v-7a83a7e4] {
  width: 100%;
  line-height: 36px;
  border-bottom: 1px solid #cccccc;
}
.common_tree_box[data-v-7a83a7e4] .el-tree-node__content {
  line-height: 26px;
}
.common_tree_box .el-popover[data-v-7a83a7e4] {
  min-width: 80px !important;
}
.common_tree_box .rightBtn[data-v-7a83a7e4] {
  width: 48px;
  height: 96px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  position: absolute;
  left: 264px;
  top: calc(50vh - 148px);
  z-index: 99;
  margin-right: 5px;
  clip-path: polygon(25% 28%, 66% 4%, 66% 96%, 25% 68%);
  background: #d2d3d6;
}
.common_tree_box .rightBtn1[data-v-7a83a7e4] {
  margin-right: 5px;
  clip-path: polygon(25% 28%, 66% 4%, 66% 96%, 25% 68%);
  background: #d2d3d6;
  left: -30px;
}
.treePopperClass .popv_p[data-v-7a83a7e4] {
  line-height: 32px;
  text-align: center;
  margin: 0;
  padding: 0;
  cursor: pointer;
}