.select-box[data-v-34d22a8c] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 1px solid #DCDFE6;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top-left-radius: none;
  border-bottom-left-radius: none;
}
[data-v-34d22a8c] .noborder-button {
  border: none !important;
  min-height: 32px;
  background-color: #F5F7FA !important;
  border-left: 1px solid #DCDFE6 !important;
}
[data-v-34d22a8c] .noborder input {
  border: none !important;
}