
.headMenuItem[data-v-f29551fe]{
  max-width: 160px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.elMenuItem[data-v-f29551fe]:hover {
  background-color: transparent !important;
}
.elMenuItem[data-v-f29551fe]:focus {
  background-color: transparent !important;
}
