.SNOWS-common-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 14px 10px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.SNOWS-common-head .SNOWS-common-head-right {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  font-size: 0;
}
.SNOWS-common-head .SNOWS-common-head-right .el-link {
  margin-left: 12px;
}
.SNOWS-common-head .SNOWS-common-head-right .SNOWS-common-head-icon {
  color: #606266;
}

.SNOWS-common-el-tree {
  margin: 10px 0;
}

.SNOWS-common-layout {
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  background: #EBEEF5;
}
.SNOWS-common-layout .SNOWS-common-layout-left {
  width: 220px;
  background-color: #fff;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-right: 10px;
}
.SNOWS-common-layout .SNOWS-common-layout-left .SNOWS-common-title {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  padding: 0 10px;
}
.SNOWS-common-layout .SNOWS-common-layout-left .SNOWS-common-tree-search-box {
  padding: 10px 10px 0;
}
.SNOWS-common-layout .SNOWS-common-layout-left .SNOWS-common-el-tree-scrollbar {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.SNOWS-common-layout .SNOWS-common-layout-left .SNOWS-common-el-tree-scrollbar .el-scrollbar__wrap {
  overflow-x: hidden;
}
.SNOWS-common-layout .SNOWS-common-layout-left .custom-tree-node {
  width: calc(100% - 30px) !important;
}
.SNOWS-common-layout .SNOWS-common-layout-left .SNOWS-common-el-tree {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  overflow: auto;
  overflow-x: hidden;
}
.SNOWS-common-layout .SNOWS-common-layout-left .SNOWS-common-el-tree .el-tree-node__content {
  height: 40px;
  line-height: 40px;
}

.SNOWS-common-layout-center {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  height: 100%;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.SNOWS-common-layout-center .SNOWS-common-layout-main {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding: 0 0 10px;
  background-color: #fff;
  height: 100%;
  overflow: hidden;
}
.SNOWS-common-layout-center .SNOWS-common-layout-main.nohead {
  padding-top: 10px;
}

.SNOWS-common-search-box {
  background: #fff;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  padding: 15px 10px 0;
  position: relative;
}
.SNOWS-common-search-box .el-form-item {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 14px !important;
}
.SNOWS-common-search-box .el-form-item .el-form-item__label {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  padding-right: 12px;
}
.SNOWS-common-search-box .el-form-item .el-form-item__content {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.SNOWS-common-search-box .el-form-item .el-input,
.SNOWS-common-search-box .el-form-item .el-select,
.SNOWS-common-search-box .el-form-item .el-cascader,
.SNOWS-common-search-box .el-form-item .comSelect-container,
.SNOWS-common-search-box .el-form-item .el-date-editor {
  width: 100%;
}
.SNOWS-common-search-box .SNOWS-common-search-box-right {
  position: absolute;
  right: 10px;
  top: 15px;
}

.comSelect-container {
  height: 36px;
}

.el-table {
  border-top: 1px solid #EBEEF5;
}
.el-table thead tr,
.el-table thead tr th {
  background-color: #f5f7fa;
  font-weight: normal;
  color: #606266;
}
.el-table .el-switch {
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
}
.el-table .el-button--mini.el-button--text {
  padding: 3px 0;
}
.el-table.columnTable {
  border-top: none;
}
.el-table.columnTable thead tr,
.el-table.columnTable thead tr th {
  background-color: #fff;
}
.el-table.columnTable td {
  border-bottom: none;
}
.el-table.columnTable.el-table--mini td, .el-table.columnTable.el-table--mini th {
  padding: 3px 0;
}
.el-table.columnTable::before {
  height: 0;
}
.el-table::before {
  height: 0 !important;
}
.el-table .el-table__fixed-right-patch {
  background-color: #f5f7fa;
  top: 0;
}
.el-table .has-gutter .gutter {
  border-bottom: 1px solid #ebeef5;
}
.el-table .el-table__empty-block {
  display: inline-block;
}
.el-table .el-table__column-filter-trigger {
  line-height: 22px;
}
.el-table .caret-wrapper {
  height: 23px !important;
}
.el-table .sort-caret.ascending {
  top: 0px !important;
}
.el-table .sort-caret.descending {
  bottom: 1px !important;
}

.SNOWS-table-delBtn {
  color: #ff3a3a !important;
}
.SNOWS-table-delBtn.el-button--text:hover, .SNOWS-table-delBtn.el-button--text:focus {
  color: #ff3a3a !important;
}
.SNOWS-table-delBtn.is-disabled {
  color: #C0C4CC !important;
}
.SNOWS-table-delBtn.is-disabled.el-button--text:hover, .SNOWS-table-delBtn.is-disabled.el-button--text:focus {
  color: #C0C4CC !important;
}

.SNOWS-dialog .el-input__validateIcon {
  display: none !important;
}
.SNOWS-dialog.SNOWS-dialog_center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.SNOWS-dialog.SNOWS-dialog_center .el-dialog {
  border-radius: 4px;
  margin: 0 !important;
}
.SNOWS-dialog.SNOWS-dialog_center .el-dialog .el-dialog__header {
  border-bottom: 1px solid #dcdfe6;
  padding: 0 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.SNOWS-dialog.SNOWS-dialog_center .el-dialog .el-dialog__header .el-dialog__headerbtn {
  top: 9px;
  right: 16px;
}
.SNOWS-dialog.SNOWS-dialog_center .el-dialog .el-dialog__body {
  overflow: auto;
  overflow-x: hidden;
  max-height: 70vh;
  padding: 20px 50px 2px;
}
.SNOWS-dialog.SNOWS-dialog_center .el-dialog .el-dialog__close {
  font-size: 24px;
}
.SNOWS-dialog.SNOWS-dialog_center.form-script-dialog .el-dialog .el-dialog__body {
  padding: 20px 20px 10px;
}
.SNOWS-dialog.SNOWS-dialog_center.form-script-dialog .form-script-dialog-body {
  height: 600px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
}
.SNOWS-dialog.SNOWS-dialog_center.form-script-dialog .form-script-dialog-body .left-tree {
  height: 600px;
  width: 220px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  margin-right: 10px;
  overflow: hidden auto;
}
.SNOWS-dialog.SNOWS-dialog_center.form-script-dialog .form-script-dialog-body .right-main {
  height: 600px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.SNOWS-dialog.SNOWS-dialog_center.form-script-dialog .form-script-dialog-body .right-main .codeEditor {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  border: 1px solid #dcdfe6;
}
.SNOWS-dialog.SNOWS-dialog_center.form-script-dialog .form-script-dialog-body .right-main .tips {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  padding: 8px 16px;
  background-color: #ecf8ff;
  border-radius: 4px;
  border-left: 5px solid #50bfff;
  margin-top: 20px;
}
.SNOWS-dialog.SNOWS-dialog_center.form-script-dialog .form-script-dialog-body .right-main .tips p {
  line-height: 24px;
  color: #5e6d82;
}
.SNOWS-dialog.SNOWS-dialog_center.form-script-dialog .form-script-dialog-body .right-main .tips p span {
  display: inline-block;
  padding-right: 10px;
}
.SNOWS-dialog.SNOWS-dialog_center.formula-dialog .el-dialog .el-dialog__body {
  padding: 20px 20px 10px;
}
.SNOWS-dialog.SNOWS-dialog_center.formula-dialog .formula-dialog-body .code-editor-area {
  height: 250px;
  border: 1px solid #dcdfe6;
  border-radius: 6px;
  margin-bottom: 10px;
  overflow: hidden;
}
.SNOWS-dialog.SNOWS-dialog_center.formula-dialog .formula-dialog-body .operation-area {
  height: 250px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.SNOWS-dialog.SNOWS-dialog_center.formula-dialog .formula-dialog-body .operation-area .area-item {
  height: 250px;
  border: 1px solid #dcdfe6;
  border-radius: 6px;
  width: 220px;
  overflow: hidden;
}
.SNOWS-dialog.SNOWS-dialog_center.formula-dialog .formula-dialog-body .operation-area .area-item.formula-area {
  margin: 0 20px;
}
.SNOWS-dialog.SNOWS-dialog_center.formula-dialog .formula-dialog-body .operation-area .area-item.formula-desc-area {
  width: 280px;
}
.SNOWS-dialog.SNOWS-dialog_center.formula-dialog .formula-dialog-body .operation-area .area-item.formula-desc-area .area-content {
  padding: 10px;
}
.SNOWS-dialog.SNOWS-dialog_center.formula-dialog .formula-dialog-body .operation-area .area-item .area-title {
  padding: 0 10px;
  height: 34px;
  line-height: 34px;
  border-bottom: 1px solid #dcdfe6;
}
.SNOWS-dialog.SNOWS-dialog_center.formula-dialog .formula-dialog-body .operation-area .area-item .area-content {
  padding: 10px 0;
  height: 216px;
  overflow: hidden auto;
}
.SNOWS-dialog.SNOWS-dialog_center.formula-dialog .formula-dialog-body .operation-area .area-item .area-content .formula-desc-wrapper {
  color: #5e6d82;
  overflow-y: auto;
}
.SNOWS-dialog.SNOWS-dialog_center.formula-dialog .formula-dialog-body .operation-area .area-item .area-content .formula-desc-wrapper > li {
  margin-bottom: 4px;
  word-break: break-all;
  word-wrap: break-word;
  list-style-type: none;
  font-size: 12px;
  line-height: 18px;
}
.SNOWS-dialog.SNOWS-dialog_center.formula-dialog .formula-dialog-body .operation-area .area-item .area-content .formula-desc-wrapper .formula-name {
  color: #1890FF;
}
.SNOWS-dialog.SNOWS-dialog_center.transfer-dialog .el-dialog .el-dialog__body {
  padding: 10px;
}
.SNOWS-dialog.SNOWS-dialog_center.transfer-dialog .transfer-pane__body-tab {
  height: 100%;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.SNOWS-dialog.SNOWS-dialog_center.transfer-dialog .transfer-pane__body-tab .el-tabs__header {
  margin-bottom: 0;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.SNOWS-dialog.SNOWS-dialog_center.transfer-dialog .transfer-pane__body-tab .el-tabs__header .el-tabs__nav {
  width: 100%;
}
.SNOWS-dialog.SNOWS-dialog_center.transfer-dialog .transfer-pane__body-tab .el-tabs__header .el-tabs__nav .el-tabs__item {
  width: 33.33%;
  text-align: center;
  padding: 0 20px;
}
.SNOWS-dialog.SNOWS-dialog_center.transfer-dialog .transfer-pane__body-tab.hasSys-tab .el-tabs__header .el-tabs__nav .el-tabs__item {
  width: 25%;
}
.SNOWS-dialog.SNOWS-dialog_center.transfer-dialog .transfer-pane__body-tab .el-tabs__content {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.SNOWS-dialog.SNOWS-dialog_center.transfer-dialog .transfer-pane__body-tab .el-tabs__content .el-tab-pane {
  height: 100%;
  padding: 10px;
  overflow: auto;
}
.SNOWS-dialog.SNOWS-dialog_center.transfer-dialog .transfer-pane__body-tab .el-tabs__content .el-tab-pane .SNOWS-common-el-tree {
  margin: 0;
}
.SNOWS-dialog.SNOWS-dialog_center.SNOWS-dialog-tree-select .el-dialog__body {
  height: 60vh;
  padding: 0 0 10px !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  overflow: hidden;
}
.SNOWS-dialog.SNOWS-dialog_center.SNOWS-dialog-tree-select .el-dialog__body .SNOWS-common-layout-left {
  margin-right: 1px;
}
.SNOWS-dialog.SNOWS-dialog_center.SNOWS-dialog-tree-select .el-dialog__body .SNOWS-common-search-box {
  margin-bottom: 0;
}
.SNOWS-dialog.SNOWS-dialog_center.SNOWS-dialog-tree-select .el-dialog__body .SNOWS-common-search-box .SNOWS-common-search-box-right {
  padding: 6px 10px 0 0;
}
.SNOWS-dialog.enlarge-dialog .el-dialog .el-dialog__header {
  border-bottom: none;
}
.SNOWS-dialog .el-select {
  width: 100%;
}

.SNOWS-dialog-notice .el-dialog__body {
  padding: 0 20px !important;
}
.SNOWS-dialog-notice .notice-wrapper .title {
  font-size: 18px;
  font-weight: normal;
  text-align: center;
}
.SNOWS-dialog-notice .notice-wrapper .info {
  line-height: 35px;
  border-bottom: 1px solid #dcdfe6;
  text-align: center;
}
.SNOWS-dialog-notice .notice-wrapper .info span {
  padding: 0 10px;
}
.SNOWS-dialog-notice .notice-wrapper .main {
  margin: 10px 0;
  line-height: 22px;
  min-height: 300px;
  overflow: auto;
}
.SNOWS-dialog-notice .notice-wrapper .file-list {
  padding: 10px 0;
  border-top: 1px solid #dcdfe6;
}

.SNOWS-dialog-tree .el-dialog .el-dialog__body .el-tree {
  overflow: auto;
  overflow-x: hidden;
  height: 340px;
}

.SNOWS-dialog-export .el-dialog .el-dialog__body {
  padding: 20px 20px 0 !important;
}
.SNOWS-dialog-export .column-item.el-checkbox {
  width: calc(33.33% - 30px);
}
.SNOWS-dialog-export .export-label {
  font-size: 18px;
  font-weight: bold;
  width: 100%;
}
.SNOWS-dialog-export .export-label span {
  margin-left: 10px;
  font-size: 14px;
  font-weight: normal;
}
.SNOWS-dialog-export .export-line .el-form-item__content {
  border-top: 1px solid #dcdfe6;
}
.SNOWS-dialog-export .upload-line {
  line-height: 32px;
  border-bottom: 1px solid #dcdfe6;
  margin-bottom: 5px;
}
.SNOWS-dialog-export .footer-tip {
  float: left;
  font-size: 14px;
  color: #999999;
  padding-top: 7px;
}

.SNOWS-dialog-import .el-dialog__body {
  padding: 20px 40px 2px !important;
}
.SNOWS-dialog-import .import-main {
  height: 500px;
  margin-top: 20px;
  overflow: hidden;
  position: relative;
}
.SNOWS-dialog-import .import-main .upload {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid #dcdfe6;
  margin-bottom: 25px;
}
.SNOWS-dialog-import .import-main .upload.error-show {
  margin-top: 10px;
  margin-bottom: 15px;
}
.SNOWS-dialog-import .import-main .upload.error-show .up_left {
  height: 120px;
}
.SNOWS-dialog-import .import-main .upload.error-show .up_right {
  padding-top: 20px;
  font-size: 16px;
}
.SNOWS-dialog-import .import-main .upload.error-show .up_right .el-link {
  font-size: 16px;
}
.SNOWS-dialog-import .import-main .upload .up_left {
  width: 126px;
  height: 140px;
  background: #f9f9f9;
  text-align: center;
  padding-top: 20px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.SNOWS-dialog-import .import-main .upload .up_left img {
  width: 80px;
  height: 80px;
}
.SNOWS-dialog-import .import-main .upload .up_right {
  color: #333;
  margin-left: 30px;
  font-size: 14px;
  padding-top: 30px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.SNOWS-dialog-import .import-main .upload .up_right .title {
  font-size: 18px;
  font-weight: bold;
}
.SNOWS-dialog-import .import-main .upload .up_right .tip {
  margin: 15px 0;
}
.SNOWS-dialog-import .import-main .upload .upload-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-right: 200px;
}
.SNOWS-dialog-import .import-main .upload .upload-area .el-upload {
  margin-right: 50px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.SNOWS-dialog-import .import-main .upload .upload-area .el-upload-list {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.SNOWS-dialog-import .import-main .upload .upload-area .el-upload-list__item:first-child {
  margin-top: 5px;
}
.SNOWS-dialog-import .import-main .success {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 110px;
}
.SNOWS-dialog-import .import-main .success .success-title {
  margin: 20px 0;
  font-size: 18px;
  font-weight: bold;
}
.SNOWS-dialog-import .import-main .contips {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 15px;
}

.SNOWS-common-title {
  border-bottom: 1px solid #ebeef5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  color: #606266;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.SNOWS-common-title.noBorder {
  border: none;
}
.SNOWS-common-title h2 {
  font-size: 16px;
  line-height: 50px;
  font-weight: 500;
}
.SNOWS-common-title h2.bold {
  font-weight: 600;
}
.SNOWS-common-title .options > i,
.SNOWS-common-title .options .el-link {
  font-size: 16px;
  margin-left: 6px;
  cursor: pointer;
}
.SNOWS-common-title + .el-table {
  border-top: none;
}

.SNOWS-dialog-add .el-dialog__body {
  padding: 20px !important;
}
.SNOWS-dialog-add .add-main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.SNOWS-dialog-add .add-main .add-item {
  width: 270px;
  height: 136px;
  background: #fef3e6;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  padding-left: 20px;
}
.SNOWS-dialog-add .add-main .add-item:hover {
  opacity: 0.9;
}
.SNOWS-dialog-add .add-main .add-item.add-item-left {
  background: #f1f5ff;
}
.SNOWS-dialog-add .add-main .add-item.add-item-left .add-icon {
  background: #ccd9ff;
  color: #537eff;
}
.SNOWS-dialog-add .add-main .add-item .add-icon {
  width: 56px;
  height: 56px;
  margin-right: 10px;
  background: #fce1bf;
  border-radius: 10px;
  color: #ea986c;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  font-size: 30px;
  line-height: 56px;
  text-align: center;
}
.SNOWS-dialog-add .add-main .add-item .add-txt {
  height: 56px;
}
.SNOWS-dialog-add .add-main .add-item .add-txt P {
  line-height: 28px;
}
.SNOWS-dialog-add .add-main .add-item .add-txt .add-title {
  font-size: 18px;
  font-weight: bold;
}
.SNOWS-dialog-add .add-main .add-item .add-txt .add-desc {
  color: #8d8989;
  font-size: 12px;
}

.custom-tree-node {
  width: calc(100% - 24px);
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.custom-tree-node i {
  font-size: 14px;
}
.custom-tree-node .text {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  font-size: 14px;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.custom-tree-node i + .text {
  padding-left: 6px;
}
.custom-tree-node .more {
  float: right;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mt-8 {
  margin-top: 8px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.ml-8 {
  margin-left: 8px;
}

.pd-10 {
  padding: 10px;
}

.pd-10-20 {
  padding: 10px 20px;
}

.m-0-10 {
  margin: 0 10px;
}

.SNOWS-common-page-header {
  padding: 14px 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px solid #dcdfe6;
}
.SNOWS-common-page-header .options {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  margin-left: auto;
}
.SNOWS-common-page-header .el-page-header .el-page-header__left {
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.SNOWS-common-page-header .el-page-header .el-page-header__content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.SNOWS-common-page-header .steps {
  width: 300px;
  padding: 6px 20px;
  background: #fff;
  justify-items: flex-start;
}
.SNOWS-common-page-header .steps .el-step__title {
  cursor: pointer;
}

.SNOWS-preview-main {
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  overflow: auto;
  overflow-x: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.SNOWS-preview-main.nohead {
  padding-top: 10px;
}
.SNOWS-preview-main .btns {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 100;
}
.SNOWS-preview-main .el-table--mini td {
  padding: 2px 0;
}
.SNOWS-preview-main .el-table {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.SNOWS-preview-main .SNOWS-common-search-box {
  margin-bottom: 0;
}
.SNOWS-preview-main .main {
  padding: 10px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  overflow: auto;
  overflow-x: hidden;
}

.SNOWS-flex-main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.SNOWS-flex-main .el-table {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.SNOWS-flex-main .el-table::before {
  border-bottom: 0;
  height: 0px;
}
.SNOWS-flex-main .el-table__fixed::before,
.SNOWS-flex-main .el-table__fixed-right::before {
  border-bottom: 0;
  height: 0px;
}

.SNOWS-el_tabs {
  height: 100%;
}
.SNOWS-el_tabs.el-tabs--top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.SNOWS-el_tabs .el-tabs__content {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  overflow: auto;
  overflow-x: hidden;
}
.SNOWS-el_tabs .el-tabs__content .el-tab-pane {
  height: 100%;
}

.text-primary {
  color: #188ae2 !important;
}

.text-success {
  color: #0eac5c !important;
}

.text-info {
  color: #35b8e0 !important;
}

.text-warning {
  color: #f9c851 !important;
}

.text-danger {
  color: #ff5b5b !important;
}

.text-pink {
  color: #ff8acc !important;
}

.text-purple {
  color: #5b69bc !important;
}

.text-inverse {
  color: #3b3e47 !important;
}

.text-dark {
  color: #282828 !important;
}

.text-white {
  color: #ffffff !important;
}

.text-color {
  color: #6a6c6f !important;
}

.text-grey {
  color: #999 !important;
}

.i-default {
  color: #6b7a99 !important;
}

.title-color {
  color: #475059 !important;
}

.SNOWS-select-tags {
  display: none !important;
}

.flow-form-main {
  z-index: 110;
}
.flow-form-main .el-date-editor {
  width: 100%;
}
.flow-form-main .approve-result {
  position: absolute;
  right: 10px;
  top: 100px;
  z-index: 100;
  width: 100px;
  height: 100px;
  opacity: 0.7;
}
.flow-form-main .approve-result .approve-result-img {
  width: 100%;
  height: 100%;
}
.flow-form-main .flow-mask {
  width: 100%;
  height: 100%;
  opacity: 0;
  filter: alpha(opacity=0);
  background: #fff;
  position: absolute;
  top: 0px;
  left: 0;
  z-index: 100;
}
.flow-form-main .com-title {
  height: 60px;
  line-height: 60px;
  text-align: center;
  position: relative;
  color: #606266;
}
.flow-form-main .com-title h1 {
  font-size: 18px;
  margin: 0;
}
.flow-form-main .com-title .number {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
}
.flow-form-main .flow-form .el-input.is-disabled .el-input__inner {
  background-color: #fff !important;
  border-color: #DCDFE6 !important;
  color: #606266;
}
.flow-form-main .flow-form .el-textarea.is-disabled .el-textarea__inner {
  background-color: #fff !important;
  border-color: #DCDFE6 !important;
  color: #606266 !important;
}
.flow-form-main .flow-form .is-checked .el-radio__input.is-disabled + span.el-radio__label {
  color: #1890ff !important;
}
.flow-form-main .el-form {
  padding-top: 10px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  overflow: auto;
  overflow-x: hidden;
}
.flow-form-main .el-form .el-select,
.flow-form-main .el-form .el-date-editor {
  width: 100%;
}
.flow-form-main .timeline-cell {
  line-height: 30px;
}
.flow-form-main .timeline-cell .signImg {
  width: 300px;
  vertical-align: top;
}
.flow-form-main .timeline-icon {
  font-size: 16px;
  background-color: #fff;
  margin-left: -2px;
}
.flow-form-main .el-tabs__header {
  padding: 0 10px;
}
.flow-form-main .SNOWS-el_tabs .el-tabs__content {
  overflow: hidden;
}
.flow-form-main .SNOWS-el_tabs .el-tabs__content .el-tab-pane {
  overflow: auto;
  overflow-x: hidden;
  padding: 0 10px 10px;
}

.dynamic-form-main {
  height: calc(100% - 61px);
  overflow: auto;
  overflow-x: hidden;
  padding: 10px;
}

.functional .navbar.dark,
.blend .navbar.dark {
  background: #031e39;
  border-bottom: none;
}
.functional .navbar.dark .functional-logo-container,
.blend .navbar.dark .functional-logo-container {
  background: #031e39;
}
.functional .navbar.dark .right-menu .right-menu-item,
.blend .navbar.dark .right-menu .right-menu-item {
  color: #fff;
}
.functional .navbar.dark .icon-ym,
.blend .navbar.dark .icon-ym {
  color: #fff;
}
.functional .navbar.dark .top-menu .el-menu .el-menu-item,
.functional .navbar.dark .top-menu .el-menu .el-submenu__title,
.blend .navbar.dark .top-menu .el-menu .el-menu-item,
.blend .navbar.dark .top-menu .el-menu .el-submenu__title {
  color: #fff;
}
.functional .navbar.dark .top-menu .el-menu .el-menu-item i,
.functional .navbar.dark .top-menu .el-menu .el-submenu__title i,
.blend .navbar.dark .top-menu .el-menu .el-menu-item i,
.blend .navbar.dark .top-menu .el-menu .el-submenu__title i {
  color: #fff;
}
.functional .navbar.dark .top-menu .el-menu .el-menu-item.is-active,
.functional .navbar.dark .top-menu .el-menu .el-submenu__title.is-active,
.blend .navbar.dark .top-menu .el-menu .el-menu-item.is-active,
.blend .navbar.dark .top-menu .el-menu .el-submenu__title.is-active {
  color: #1890ff;
}
.functional .navbar.dark .top-menu .el-menu .el-menu-item.is-active i,
.functional .navbar.dark .top-menu .el-menu .el-submenu__title.is-active i,
.blend .navbar.dark .top-menu .el-menu .el-menu-item.is-active i,
.blend .navbar.dark .top-menu .el-menu .el-submenu__title.is-active i {
  color: #1890ff;
}
.functional .navbar.dark .top-menu .el-menu .el-menu-item:hover,
.functional .navbar.dark .top-menu .el-menu .el-submenu__title:hover,
.blend .navbar.dark .top-menu .el-menu .el-menu-item:hover,
.blend .navbar.dark .top-menu .el-menu .el-submenu__title:hover {
  color: #1890ff;
}
.functional .navbar.dark .top-menu .el-menu .el-menu-item:hover i,
.functional .navbar.dark .top-menu .el-menu .el-submenu__title:hover i,
.blend .navbar.dark .top-menu .el-menu .el-menu-item:hover i,
.blend .navbar.dark .top-menu .el-menu .el-submenu__title:hover i {
  color: #1890ff;
}
.functional .top-menu .el-menu,
.blend .top-menu .el-menu {
  height: 60px;
  background: transparent;
}
.functional .top-menu .el-menu.el-menu--horizontal,
.blend .top-menu .el-menu.el-menu--horizontal {
  border-bottom: none;
}
.functional .top-menu .el-menu.el-menu--horizontal .el-icon-arrow-down,
.blend .top-menu .el-menu.el-menu--horizontal .el-icon-arrow-down {
  display: none !important;
}
.functional .top-menu .el-menu .el-menu-item,
.functional .top-menu .el-menu .el-submenu__title,
.blend .top-menu .el-menu .el-menu-item,
.blend .top-menu .el-menu .el-submenu__title {
  height: 61px !important;
  line-height: 60px !important;
  float: left;
  text-align: center;
  background: transparent !important;
  color: #333;
}
.functional .top-menu .el-menu .el-menu-item i,
.functional .top-menu .el-menu .el-submenu__title i,
.blend .top-menu .el-menu .el-menu-item i,
.blend .top-menu .el-menu .el-submenu__title i {
  font-weight: normal;
  color: #333;
}
.functional .top-menu .el-menu .el-menu-item .left-icon,
.functional .top-menu .el-menu .el-submenu__title .left-icon,
.blend .top-menu .el-menu .el-menu-item .left-icon,
.blend .top-menu .el-menu .el-submenu__title .left-icon {
  margin-left: 0 !important;
  margin-right: 4px;
}
.functional .top-menu .el-menu .el-menu-item.is-active,
.functional .top-menu .el-menu .el-submenu__title.is-active,
.blend .top-menu .el-menu .el-menu-item.is-active,
.blend .top-menu .el-menu .el-submenu__title.is-active {
  color: #1890ff;
}
.functional .top-menu .el-menu .el-menu-item.is-active i,
.functional .top-menu .el-menu .el-submenu__title.is-active i,
.blend .top-menu .el-menu .el-menu-item.is-active i,
.blend .top-menu .el-menu .el-submenu__title.is-active i {
  color: #1890ff;
}
.functional .top-menu .el-menu .el-menu-item:hover,
.functional .top-menu .el-menu .el-submenu__title:hover,
.blend .top-menu .el-menu .el-menu-item:hover,
.blend .top-menu .el-menu .el-submenu__title:hover {
  color: #1890ff;
}
.functional .top-menu .el-menu .el-menu-item:hover i,
.functional .top-menu .el-menu .el-submenu__title:hover i,
.blend .top-menu .el-menu .el-menu-item:hover i,
.blend .top-menu .el-menu .el-submenu__title:hover i {
  color: #1890ff;
}
.functional .top-menu .el-menu .el-submenu,
.blend .top-menu .el-menu .el-submenu {
  float: left;
  text-align: center;
}
.functional .top-menu .el-menu .el-submenu:hover .el-submenu__title,
.blend .top-menu .el-menu .el-submenu:hover .el-submenu__title {
  color: #1890ff;
  background: transparent;
}
.functional .top-menu .el-menu .el-submenu:hover .el-submenu__title i,
.blend .top-menu .el-menu .el-submenu:hover .el-submenu__title i {
  color: #1890ff;
}
.functional .top-menu .el-menu .el-submenu.is-active .el-submenu__title,
.blend .top-menu .el-menu .el-submenu.is-active .el-submenu__title {
  color: #1890ff;
}
.functional .top-menu .el-menu .el-submenu.is-active .el-submenu__title i,
.blend .top-menu .el-menu .el-submenu.is-active .el-submenu__title i {
  color: #1890ff;
}
.functional .top-menu .el-menu--horizontal .el-menu-item:not(.is-disabled):hover,
.blend .top-menu .el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  background: transparent;
}
.functional.el-menu--horizontal .el-menu.el-menu--popup,
.blend.el-menu--horizontal .el-menu.el-menu--popup {
  min-width: 160px;
  max-height: calc(100vh - 80px);
  overflow-y: auto;
}
.functional.el-menu--horizontal .el-menu .nest-menu .el-submenu.is-active .el-submenu__title,
.blend.el-menu--horizontal .el-menu .nest-menu .el-submenu.is-active .el-submenu__title {
  color: #1890ff;
}
.functional.el-menu--horizontal .el-menu .nest-menu .el-submenu.is-active .el-submenu__title i,
.blend.el-menu--horizontal .el-menu .nest-menu .el-submenu.is-active .el-submenu__title i {
  color: #1890ff;
}
.functional.el-menu--horizontal .el-menu .nest-menu .el-menu-item .el-submenu__icon-arrow,
.functional.el-menu--horizontal .el-menu .nest-menu .el-submenu__title .el-submenu__icon-arrow,
.blend.el-menu--horizontal .el-menu .nest-menu .el-menu-item .el-submenu__icon-arrow,
.blend.el-menu--horizontal .el-menu .nest-menu .el-submenu__title .el-submenu__icon-arrow {
  margin-top: -5px;
}
.functional.el-menu--horizontal .el-menu .nest-menu .el-menu-item .left-icon,
.functional.el-menu--horizontal .el-menu .nest-menu .el-submenu__title .left-icon,
.blend.el-menu--horizontal .el-menu .nest-menu .el-menu-item .left-icon,
.blend.el-menu--horizontal .el-menu .nest-menu .el-submenu__title .left-icon {
  font-size: 20px;
  margin-right: 6px;
  margin-left: 15px;
}
.functional.el-menu--horizontal .el-menu .nest-menu .el-menu-item:hover,
.functional.el-menu--horizontal .el-menu .nest-menu .el-submenu__title:hover,
.blend.el-menu--horizontal .el-menu .nest-menu .el-menu-item:hover,
.blend.el-menu--horizontal .el-menu .nest-menu .el-submenu__title:hover {
  color: #1890ff;
}
.functional.el-menu--horizontal .el-menu .nest-menu .el-menu-item:hover i,
.functional.el-menu--horizontal .el-menu .nest-menu .el-submenu__title:hover i,
.blend.el-menu--horizontal .el-menu .nest-menu .el-menu-item:hover i,
.blend.el-menu--horizontal .el-menu .nest-menu .el-submenu__title:hover i {
  color: #1890ff;
}
.functional.el-menu--horizontal .el-menu .nest-menu .el-menu-item.is-active,
.functional.el-menu--horizontal .el-menu .nest-menu .el-submenu__title.is-active,
.blend.el-menu--horizontal .el-menu .nest-menu .el-menu-item.is-active,
.blend.el-menu--horizontal .el-menu .nest-menu .el-submenu__title.is-active {
  color: #1890ff;
}
.functional.el-menu--horizontal .el-menu .nest-menu .el-menu-item.is-active i,
.functional.el-menu--horizontal .el-menu .nest-menu .el-submenu__title.is-active i,
.blend.el-menu--horizontal .el-menu .nest-menu .el-menu-item.is-active i,
.blend.el-menu--horizontal .el-menu .nest-menu .el-submenu__title.is-active i {
  color: #1890ff;
}
.functional.el-menu--horizontal.dark .el-menu,
.blend.el-menu--horizontal.dark .el-menu {
  background: #031e39;
}
.functional.el-menu--horizontal.dark .el-menu .el-menu-item,
.functional.el-menu--horizontal.dark .el-menu .el-submenu__title,
.blend.el-menu--horizontal.dark .el-menu .el-menu-item,
.blend.el-menu--horizontal.dark .el-menu .el-submenu__title {
  background: #031e39;
  color: #fff;
}
.functional.el-menu--horizontal.dark .el-menu .el-menu-item i,
.functional.el-menu--horizontal.dark .el-menu .el-submenu__title i,
.blend.el-menu--horizontal.dark .el-menu .el-menu-item i,
.blend.el-menu--horizontal.dark .el-menu .el-submenu__title i {
  color: #fff;
}

#app .plain .navbar-platform {
  color: #666;
  line-height: 60px;
  font-size: 16px;
  margin-left: 20px;
}
#app .plain .el-menu {
  border: none;
  height: 100%;
  width: 100% !important;
  background: #001529;
  color: #fff;
}
#app .plain .plain-sidebar-container {
  width: 80px;
  background-color: #001529;
  height: 100%;
  position: fixed;
  font-size: 0px;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  overflow: hidden;
  -webkit-box-shadow: 0 66px 12px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 66px 12px 0 rgba(0, 0, 0, 0.1);
}
#app .plain .plain-sidebar-container .el-scrollbar {
  height: calc(100% - 60px);
}
#app .plain .plain-sidebar-container .submenu-title-noDropdown {
  padding: 0 !important;
  position: relative;
}
#app .plain .plain-sidebar-container .submenu-title-noDropdown .el-tooltip {
  padding: 0 !important;
}
#app .plain .plain-sidebar-container .submenu-title-noDropdown .el-tooltip .left-icon {
  font-size: 28px;
}
#app .plain .plain-sidebar-container .el-menu .el-menu-item {
  height: 70px !important;
  text-align: center;
}
#app .plain .plain-sidebar-container .el-menu .left-icon {
  margin-right: 0;
  font-size: 30px;
  margin-bottom: 0;
}
#app .plain .plain-sidebar-container .el-menu .mainTitle {
  font-size: 12px;
  line-height: 24px;
  margin-top: -8px;
}
#app .plain .plain-sidebar-container .el-submenu {
  overflow: hidden;
  height: 70px !important;
  text-align: center;
}
#app .plain .plain-sidebar-container .el-submenu > .el-submenu__title {
  padding: 0 !important;
  height: 70px !important;
}
#app .plain .plain-sidebar-container .el-submenu > .el-submenu__title .el-submenu__icon-arrow {
  display: none;
}
#app .plain .main-container {
  margin-left: 80px;
}

.plain.el-menu--vertical {
  top: 0;
}
.plain.el-menu--vertical > .el-menu--popup {
  max-height: 100vh;
  overflow-y: auto;
}

.SNOWS-full-dialog .el-dialog__header {
  padding: 0 !important;
}
.SNOWS-full-dialog .el-dialog__body {
  padding: 0;
  height: 100%;
  overflow: hidden;
}
.SNOWS-full-dialog .SNOWS-full-dialog-header {
  padding: 0 20px;
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.SNOWS-full-dialog .SNOWS-full-dialog-header .header-title {
  height: 60px;
  width: 320px;
}
.SNOWS-full-dialog .SNOWS-full-dialog-header .header-logo {
  width: auto;
  height: 60px;
  vertical-align: top;
  margin-right: 3px;
  font-size: 30px;
  color: #fff;
}
.SNOWS-full-dialog .SNOWS-full-dialog-header .header-txt {
  line-height: 60px;
  display: inline-block;
  margin: 0;
  color: #333;
  font-size: 18px;
}
.SNOWS-full-dialog .SNOWS-full-dialog-header .steps {
  width: 450px;
  padding: 6px 20px;
  background: #fff;
  justify-items: flex-start;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.SNOWS-full-dialog .SNOWS-full-dialog-header .steps.steps2 {
  width: 300px;
}
.SNOWS-full-dialog .SNOWS-full-dialog-header .steps.steps4 {
  width: 600px;
}
.SNOWS-full-dialog .SNOWS-full-dialog-header .steps .el-step__title {
  cursor: pointer;
}
.SNOWS-full-dialog .SNOWS-full-dialog-header .options {
  width: 320px;
  text-align: right;
}
.SNOWS-full-dialog .main {
  height: calc(100vh - 60px);
  overflow: hidden;
  background: #EBEEF5;
  padding: 10px;
}
.SNOWS-full-dialog .main .basic-box {
  height: 100%;
  overflow: hidden;
}
.SNOWS-full-dialog .main .basicForm {
  background: #fff;
  padding: 20px;
  border-radius: 4px;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
}
.SNOWS-full-dialog .main .basicForm .el-select {
  width: 100%;
}

.table-actions {
  margin-top: 10px;
  border: #ebeef5 1px dashed;
  text-align: center;
}

.SNOWS-common-drawer .el-drawer__header {
  height: 60px;
  border-bottom: 1px solid #dcdfe6;
  padding: 0 20px;
  margin-bottom: 0;
}
.SNOWS-common-drawer .el-drawer__header > span {
  line-height: 60px;
}
.SNOWS-common-drawer .el-drawer__body {
  overflow: hidden;
}
.SNOWS-common-drawer .SNOWS-flex-main {
  height: calc(100vh - 60px);
}
.SNOWS-common-drawer .SNOWS-flex-main .dynamicForm {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding: 10px;
  overflow: auto;
}
.SNOWS-common-drawer .SNOWS-flex-main .drawer-footer {
  padding: 10px 20px 20px;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  text-align: right;
}
.SNOWS-common-drawer .SNOWS-common-search-box {
  margin-bottom: 0;
}
.SNOWS-common-drawer .SNOWS-common-search-box .SNOWS-common-search-box-right {
  padding: 6px 10px 0 0;
}

.portal-common-title {
  font-size: 16px;
}
.portal-common-title span {
  border-left: 4px solid #1890ff;
  padding-left: 8px;
}

.portal-common-noData {
  text-align: center;
  padding-top: 50px;
}
.portal-common-noData.portal-common-noData-eChart {
  padding-top: 70px;
}
.portal-common-noData.portal-common-noData-eChart .noData-img {
  width: 200px;
  height: 200px;
}
.portal-common-noData .noData-img {
  width: 100px;
  height: 100px;
}
.portal-common-noData .noData-txt {
  font-size: 14px;
  color: #909399;
  line-height: 20px;
}

.portal-layout-nodata {
  text-align: center;
  position: absolute;
  top: calc(50% - 200px);
  left: calc(50% - 200px);
}
.portal-layout-nodata .layout-nodata-img {
  width: 400px;
  height: 400px;
}
.portal-layout-nodata .layout-nodata-txt {
  margin-top: -60px;
  font-size: 20px;
  color: #909399;
  line-height: 30px;
}

.portal-todoList-box .el-card__header {
  height: 55px;
}
.portal-todoList-box .el-card__body {
  width: 100%;
  height: calc(100% - 55px);
}
.portal-todoList-box .portal-todoList-box-body {
  padding: 16px 20px;
  height: 100%;
  overflow: hidden;
}
.portal-todoList-box .portal-todoList-box-body .item {
  display: block;
  line-height: 20px;
  font-size: 0;
  margin-bottom: 12px;
  cursor: pointer;
}
.portal-todoList-box .portal-todoList-box-body .item::after {
  content: "";
  clear: both;
  overflow: hidden;
}
.portal-todoList-box .portal-todoList-box-body .item .name {
  font-size: 14px;
  display: inline-block;
  width: calc(100% - 200px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  vertical-align: top;
}
.portal-todoList-box .portal-todoList-box-body .item .time {
  font-size: 14px;
  display: inline-block;
  color: #999;
  width: 200px !important;
  text-align: right;
}

.portal-eChart-box .el-card__header {
  height: 55px;
}
.portal-eChart-box .el-card__body {
  width: 100%;
  height: calc(100% - 55px);
}
.portal-eChart-box .eChart-box-body,
.portal-eChart-box #chart {
  width: 100%;
  height: 100%;
}
.portal-eChart-box #chart {
  z-index: 10;
}

.dynamicDetail .dynamicDetail-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.dynamicDetail .dy-fileList .dy-fileList-item {
  color: #606266;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  white-space: nowrap;
  cursor: pointer;
  margin-top: 10px;
  line-height: 25px;
}
.dynamicDetail .dy-fileList .dy-fileList-item .el-icon-document {
  margin-right: 7px;
  line-height: inherit;
}
.dynamicDetail .dy-fileList .dy-fileList-item:hover {
  background-color: #f5f7fa;
}
.dynamicDetail .dy-img {
  width: 120px;
  height: 120px;
  overflow: hidden;
  margin: 0 8px 8px 0;
  border: 1px solid #c0ccda;
  border-radius: 6px;
}
.dynamicDetail .el-color-picker__mask {
  background: none;
}
.dynamicDetail .el-switch.is-disabled {
  opacity: 1;
}
.dynamicDetail .slider-box {
  position: relative;
}
.dynamicDetail .slider-box .slider-mark {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: transparent;
  z-index: 1100;
}
.dynamicDetail .el-table th {
  line-height: 23px !important;
}

.el-message-box__wrapper .el-message-box {
  border-width: 0;
}

.el-rate {
  display: inline-block;
  vertical-align: text-top;
}

.el-message-box .el-textarea textarea {
  min-height: 88px !important;
}

.el-table__expanded-cell[class*=cell] {
  padding: 5px 50px 5px 25px !important;
}

.el-button + .el-popconfirm,
.el-button + .el-dropdown,
.el-popconfirm + .el-button,
.el-popconfirm + .el-dropdown {
  margin-left: 10px;
}

textarea.el-textarea__inner {
  resize: none;
}

.el-form--label-top .el-form-item__label {
  padding: 0 !important;
}

.el-input-number.is-controls-right .el-input__inner {
  text-align: left !important;
}

.el-popconfirm__main {
  margin: 14px 0;
}

.el-button [class*=icon-ym-] + span {
  margin-left: 5px;
}

.el-form .el-form-item__content .el-alert__content {
  line-height: 20px;
}

.el-input-number.el-input-number--small {
  width: 160px;
}

.el-col .el-form-item--small .el-form-item__label {
  line-height: 33px;
}
.el-col .el-form-item--mini .el-form-item__label {
  line-height: 29px;
}

.item-table .el-link {
  font-size: 12px;
}

.word-form {
  padding: 0 !important;
  margin: 0 !important;
  border-top: 1px solid #dcdfe6;
  border-left: 1px solid #dcdfe6;
}
.word-form.word-from-detail .el-form-item__label + .el-form-item__content {
  padding: 0 15px;
}
.word-form.word-from-detail .el-form-item__label + .el-form-item__content .el-rate,
.word-form.word-from-detail .el-form-item__label + .el-form-item__content .el-color-picker,
.word-form.word-from-detail .el-form-item__label + .el-form-item__content .el-switch {
  padding-left: 0;
}
.word-form.word-from-detail .el-col.item-table {
  border-bottom: none;
}
.word-form .el-col {
  border-right: 1px solid #dcdfe6;
  border-bottom: 1px solid #dcdfe6;
  padding: 0 !important;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.word-form .el-col.item-card {
  border: none;
}
.word-form .el-form-item {
  margin-bottom: 0 !important;
}
.word-form .el-form-item .el-form-item__label + .el-form-item__content {
  border-left: 1px solid #dcdfe6;
  padding-top: 5px;
  padding-bottom: 5px;
}
.word-form .el-form-item .el-form-item__error {
  top: 68% !important;
}
.word-form .el-form-item .el-form-item__label {
  padding-top: 5px;
  padding-bottom: 5px;
}
.word-form .el-input__inner {
  border: 0 !important;
}
.word-form textarea {
  border: 0 !important;
}
.word-form .table-actions {
  border: none;
  margin-top: 0;
}
.word-form .el-row {
  margin: 0 !important;
}
.word-form .el-row .el-col {
  border: 0;
}
.word-form .el-card {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  border: 0;
  margin-bottom: 0;
  border-radius: 0;
}
.word-form .el-card .el-card__body {
  padding: 0;
}
.word-form .el-card .el-row .el-col {
  border: 0;
}
.word-form h1 {
  border: 0 !important;
  border-bottom: 0 !important;
}
.word-form .ql-toolbar {
  border: 0 !important;
  border-bottom: 1px solid #dcdfe6 !important;
}
.word-form .ql-container {
  border: 0 !important;
}
.word-form .el-radio-group,
.word-form .el-checkbox-group,
.word-form .el-rate,
.word-form .el-color-picker,
.word-form .el-switch {
  padding-left: 15px;
}
.word-form .el-slider,
.word-form .UploadFile-container {
  padding: 0 15px;
}
.word-form .el-input-number {
  margin-left: 15px;
}
.word-form .SNOWSAmount {
  padding: 0 15px;
}
.word-form .SNOWSAmount .el-input-number {
  margin-left: 0;
}
.word-form .el-table td,
.word-form .el-table th {
  border-right: 1px solid #dcdfe6;
  border-bottom: 1px solid #dcdfe6 !important;
}
.word-form .el-table td:last-child,
.word-form .el-table th:last-child {
  border-right: none !important;
}
.word-form .el-table td:hover::after, .word-form .el-table td:hover::before,
.word-form .el-table th:hover::after,
.word-form .el-table th:hover::before {
  border-color: transparent !important;
}
.word-form .el-table .el-input-number {
  margin-left: 0;
}
.word-form .el-table .el-color-picker,
.word-form .el-table .el-switch {
  padding-left: 0;
}
.word-form .el-table th:nth-last-child(2) {
  border-right: none !important;
}
.word-form .SNOWS-common-title {
  border-bottom: 1px solid #dcdfe6 !important;
}

.table-switch.el-switch.is-disabled {
  opacity: 1;
}
.table-switch.el-switch.is-disabled .el-switch__core,
.table-switch.el-switch.is-disabled .el-switch__label {
  cursor: pointer;
}

.table-checkbox.el-checkbox.is-disabled .el-checkbox__inner,
.table-checkbox.el-checkbox.is-disabled .el-checkbox__inner::after,
.table-checkbox.el-checkbox.is-disabled input,
.table-checkbox.el-checkbox.is-disabled .el-checkbox__label {
  cursor: pointer !important;
}
.table-checkbox.el-checkbox.is-disabled .el-checkbox__inner {
  background-color: #fff !important;
}
.table-checkbox.el-checkbox.is-disabled .el-checkbox__inner:hover {
  border-color: #1890ff !important;
}
.table-checkbox.el-checkbox.is-disabled.is-checked .el-checkbox__inner {
  background-color: #1890ff !important;
  border-color: #1890ff !important;
}
.table-checkbox.el-checkbox.is-disabled.is-checked .el-checkbox__inner::after {
  border-color: #fff !important;
}
.table-checkbox.el-checkbox.is-disabled.is-checked input {
  color: #fff !important;
  border-color: #fff !important;
}

.el-dropdown-menu__item .icon-ym {
  vertical-align: -1px;
}

.transfer__body {
  line-height: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  padding-top: 10px;
  height: 400px;
}
.transfer__body .transfer-pane {
  width: 360px;
}
.transfer__body .transfer-pane__tools {
  margin-bottom: 8px;
  height: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.transfer__body .transfer-pane__tools .removeAllBtn {
  color: #f56c6c;
}
.transfer__body .transfer-pane__body {
  position: relative;
  width: 100%;
  height: calc(100% - 40px);
  overflow: auto;
  overflow-x: hidden;
  font-size: 14px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
}
.transfer__body .transfer-pane__body .el-tab-pane {
  padding: 10px 0 !important;
}
.transfer__body .transfer-pane__body.left-pane .custom-title {
  height: 39px;
  padding: 0 12px;
  line-height: 39px;
  font-size: 14px;
  font-weight: 500;
  color: #303133;
  border-bottom: 1px solid #dcdfe6;
}
.transfer__body .transfer-pane__body.left-pane .custom-title + .single-list {
  height: calc(100% - 40px);
}
.transfer__body .transfer-pane__body.left-pane .single-list {
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
}
.transfer__body .transfer-pane__body.left-pane .single-list .selected-item-user {
  cursor: pointer;
}
.transfer__body .transfer-pane__body.left-pane .single-list .selected-item {
  padding: 0px 12px;
  cursor: pointer;
}
.transfer__body .transfer-pane__body.left-pane .single-list .selected-item:hover {
  background-color: #f5f7fa;
}
.transfer__body .selected-item-user {
  padding: 0 12px;
}
.transfer__body .selected-item-user:hover {
  background-color: #f5f7fa;
}
.transfer__body .selected-item-user .selected-item-main {
  border-bottom: 1px solid #F4F6F9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 50px;
}
.transfer__body .selected-item-user .selected-item-headIcon {
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.transfer__body .selected-item-user .selected-item-headIcon.icon {
  width: 36px;
  height: 36px;
  text-align: center;
}
.transfer__body .selected-item-user .selected-item-headIcon.icon i {
  font-size: 22px;
  line-height: 36px;
}
.transfer__body .selected-item-user .selected-item-text {
  min-width: 0;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin-left: 10px;
}
.transfer__body .selected-item-user .selected-item-text .name {
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  margin-bottom: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.transfer__body .selected-item-user .selected-item-text .organize {
  height: 17px;
  line-height: 17px;
  color: #999999;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.transfer__body .selected-item-user .el-icon-delete:hover {
  color: #f56c6c;
  cursor: pointer;
}
.transfer__body .selected-item-user-multiple {
  padding-left: 12px;
  position: relative;
}
.transfer__body .selected-item-user-multiple .selected-item-title {
  font-size: 14px;
  color: #303133;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.transfer__body .selected-item-user-multiple .selected-item-title span {
  padding-left: 6px;
}
.transfer__body .selected-item-user-multiple .selected-item-main {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 64px;
  padding-left: 21px;
  position: relative;
}
.transfer__body .selected-item-user-multiple .selected-item-main:hover {
  background-color: #f5f7fa;
}
.transfer__body .selected-item-user-multiple .selected-item-main:last-child {
  border-bottom: 1px solid #F2F5F8;
}
.transfer__body .selected-item-user-multiple .selected-item-main:not(:last-child):before {
  position: absolute;
  content: "";
  bottom: 0;
  right: 0;
  width: calc(100% - 21px);
  height: 1px;
  background-color: #F2F5F8;
}
.transfer__body .selected-item-user-multiple .selected-item-headIcon {
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.transfer__body .selected-item-user-multiple .selected-item-headIcon.icon {
  width: 36px;
  height: 36px;
  text-align: center;
}
.transfer__body .selected-item-user-multiple .selected-item-headIcon.icon i {
  font-size: 22px;
  line-height: 36px;
}
.transfer__body .selected-item-user-multiple .selected-item-icon {
  width: 36px;
  height: 36px;
  background: linear-gradient(193deg, #A7D6FF 0%, #1990FA 100%);
  border-radius: 50%;
  line-height: 36px;
  color: #FFFFFF;
  font-size: 14px;
  text-align: center;
}
.transfer__body .selected-item-user-multiple .selected-item-text {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin-left: 10px;
  min-width: 0;
}
.transfer__body .selected-item-user-multiple .selected-item-text .name {
  color: #333333;
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  margin-bottom: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.transfer__body .selected-item-user-multiple .selected-item-text .organize {
  color: #999999;
  height: 17px;
  line-height: 17px;
  color: #999999;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.transfer__body .selected-item-user-multiple .delete {
  margin-right: 15px;
  font-size: 15px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.transfer__body .selected-item-user-multiple .delete:hover {
  color: #f56c6c;
  cursor: pointer;
}
.transfer__body .right-pane {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow: auto;
  border: 1px solid #dcdfe6;
}
.transfer__body .right-pane .selected-item {
  padding: 0px 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.transfer__body .right-pane .selected-item:hover {
  background-color: #f5f7fa;
}
.transfer__body .right-pane .selected-item span {
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.transfer__body .right-pane .selected-item .el-icon-delete:hover {
  color: #f56c6c;
  cursor: pointer;
}

.blend .navbar.dark {
  background: #031e39;
  border-bottom: none;
}
.blend .navbar.dark .right-menu .right-menu-item {
  color: #fff;
}
.blend .navbar.dark .icon-ym {
  color: #fff;
}
.blend .navbar.dark .el-tabs__item {
  color: #fff;
}
.blend #topMenu .el-menu-item.submenu-title-noDropdown,
.blend #topMenu .el-menu .el-submenu > .el-submenu__title {
  padding: 0 20px !important;
}

.popupSelect-container {
  width: 100%;
}
.popupSelect-container .popover-container {
  width: 100%;
}
.popupSelect-container .popover-container .el-popover__reference-wrapper,
.popupSelect-container .popover-container .el-select {
  width: 100%;
}
.popupSelect-container > .el-select {
  width: 100%;
}

.required-sign {
  color: #f56c6c;
}

.child-table-column .child-table__row {
  background: transparent;
  border-bottom: 1px solid #ebeef5 !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: 39px;
}
.child-table-column .child-table__row td {
  border: none !important;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.child-table-column .child-table__row td.td-flex-1 {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.child-table-column .child-table__row td .cell {
  min-height: 23px !important;
}
.child-table-column .child-table__row:last-child {
  border-bottom: none !important;
}
.child-table-column .expand-more-btn {
  height: 39px;
  text-align: center;
  padding-top: 4px;
}

.el-table td.child-table-box {
  padding: 0 !important;
  vertical-align: top !important;
}
.el-table td.child-table-box > .cell {
  padding: 0 !important;
}
.el-table .table-child-first {
  border-right: none !important;
}
.el-table .table-child-first .td-child-first {
  width: 1px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

.snows-table__empty-text {
  padding: 30px;
  line-height: 30px;
}

.snows-detail-text {
  color: #999;
}

.sign-main {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.sign-main .sign-img {
  width: 100px;
  height: 50px;
}
.sign-main .add-sign {
  height: 50px;
  font-size: 36px;
  margin-top: 10px;
  color: #2188ff;
}
.sign-main .sign-title {
  font-size: 16px;
  color: #2188ff;
}