.numRange[data-v-218906d2] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  max-width: 220px;
}
.numRange .el-input-number[data-v-218906d2] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  width: auto;
}
.numRange .el-input-number[data-v-218906d2] .el-input__inner {
  text-align: left;
}
.numRange .separator[data-v-218906d2] {
  margin: 0 5px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}