.quick-app-list[data-v-2e6a9199] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 10px 14px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  overflow-y: scroll;
}
.quick-app-list .quick-app-item[data-v-2e6a9199] {
  width: 25%;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  margin-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
}
.quick-app-list .quick-app-item .imageBox[data-v-2e6a9199] {
  width: 40px;
  height: 40px;
  border-radius: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 10px;
}
.quick-app-list .quick-app-item .imageBox i[data-v-2e6a9199] {
  color: #ffffff;
  font-weight: bold;
  font-size: 20px;
}
.quick-app-list .quick-app-item span[data-v-2e6a9199] {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 80px;
}