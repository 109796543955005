.im-container[data-v-401c6b98] {
  position: fixed;
  right: 290px;
  bottom: 10px;
  z-index: 3000;
  background: #e6e9f0;
  border-radius: 4px;
  overflow: hidden;
  -webkit-box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.1) !important;
          box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.1) !important;
}
.im-container *[data-v-401c6b98] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.im-container .header[data-v-401c6b98] {
  background: #fff;
  height: 50px;
  border-bottom: 1px solid #dcdfe6;
  padding: 0 10px;
  font-size: 0;
  color: #475059;
}
.im-container .header .el-avatar[data-v-401c6b98] {
  margin-top: 10px;
  vertical-align: top;
  margin-right: 10px;
}
.im-container .header .el-avatar.offLine[data-v-401c6b98] {
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
}
.im-container .header span[data-v-401c6b98] {
  line-height: 49px;
  font-size: 16px;
  color: #475059;
}
.im-container .header .el-link[data-v-401c6b98] {
  float: right;
}
.im-container .header .el-link[data-v-401c6b98] .el-icon-close {
  font-size: 20px;
  margin-top: 15px;
}
.im-container .main[data-v-401c6b98] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.im-container .main .chatBox[data-v-401c6b98] {
  width: 600px;
  height: 480px;
}
.im-container .main .chatBox .chatList[data-v-401c6b98] {
  height: 335px;
  overflow: auto;
  overflow-x: hidden;
  padding: 5px 15px 5px;
  background: #fff;
}
.im-container .main .chatBox .toolBox[data-v-401c6b98] {
  height: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 10px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 0;
}
.im-container .main .chatBox .toolBox i[data-v-401c6b98] {
  line-height: 35px;
  font-size: 20px;
  margin-right: 10px;
  color: #6b7a99;
  cursor: pointer;
}
.im-container .main .chatBox .toolBox .toolBox-left[data-v-401c6b98] .el-link {
  line-height: 35px;
}
.im-container .main .chatBox .toolBox .toolBox-left[data-v-401c6b98] .el-link i {
  font-size: 20px;
  margin-right: 10px;
}
.im-container .main .chatBox .toolBox .toolBox-left .uploadImg-btn[data-v-401c6b98] {
  display: inline-block;
}
.im-container .main .chatBox .toolBox .toolBox-right[data-v-401c6b98] {
  color: #6b7a99;
  line-height: 35px;
  font-size: 14px;
}
.im-container .main .chatBox .toolBox .toolBox-right[data-v-401c6b98] .el-link {
  line-height: 35px;
}
.im-container .main .chatBox .toolBox .toolBox-right[data-v-401c6b98] .el-link i {
  font-size: 18px;
  margin-right: 5px;
}
.im-container .main .chatBox .writeBox[data-v-401c6b98] {
  background: #fff;
  height: 110px;
}
.im-container .main .chatBox .writeBox[data-v-401c6b98] .el-textarea .el-textarea__inner {
  border: none;
  resize: none;
}
.im-container .main .chatBox .writeBox .btns[data-v-401c6b98] {
  padding-right: 10px;
  text-align: right;
  line-height: initial;
  font-size: initial;
}
.im-container .main .historyBox[data-v-401c6b98] {
  width: 350px;
  height: 480px;
  margin-left: 10px;
  background: #fff;
}
.im-container .main .historyBox .el-input[data-v-401c6b98] .el-input__inner {
  border-radius: 0;
  border-right: none;
  border-left: none;
  border-top: none;
}
.im-container .main .historyBox .el-input .el-input__icon[data-v-401c6b98] {
  cursor: pointer;
}
.im-container .main .historyBox .el-input .el-input__icon[data-v-401c6b98]:hover {
  color: #46a6ff;
}
.im-container .main .historyBox .historyList-box[data-v-401c6b98] {
  height: 448px;
  overflow: auto;
  overflow-x: hidden;
  padding: 5px;
  border-bottom-right-radius: 4px;
}
.im-container .main .historyBox .historyList-box .chatList-item .chatList-text[data-v-401c6b98] {
  max-width: 94%;
}
.im-container .main .historyBox .historyList-box .chatList__msg--audio[data-v-401c6b98] {
  width: 230px;
}
.im-container .chatList-item[data-v-401c6b98] {
  position: relative;
  font-size: 0;
  margin-bottom: 10px;
  padding-left: 60px;
  min-height: 68px;
  text-align: left;
}
.im-container .chatList-item .chatList-user[data-v-401c6b98],
.im-container .chatList-item .chatList-text[data-v-401c6b98] {
  display: inline-block;
  /* @css { * }display: inline;
  @css { * }zoom: 1; */
  vertical-align: top;
  font-size: 14px;
}
.im-container .chatList-item .chatList-user[data-v-401c6b98] {
  position: absolute;
  cursor: pointer;
  left: 3px;
}
.im-container .chatList-item .chatList-user img.offLine[data-v-401c6b98] {
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
}
.im-container .chatList-item .chatList-user cite[data-v-401c6b98] {
  position: absolute;
  left: 60px;
  top: -2px;
  /* width: 500px; */
  line-height: 24px;
  font-size: 12px;
  white-space: nowrap;
  color: #999;
  text-align: left;
  font-style: normal;
}
.im-container .chatList-item .chatList-user cite i[data-v-401c6b98] {
  padding-left: 15px;
  font-style: normal;
}
.im-container .chatList-item .chatList-text[data-v-401c6b98] {
  max-width: 75%;
  position: relative;
  line-height: 22px;
  margin-top: 25px;
  padding: 8px 15px;
  background-color: #f3f3f3;
  border-radius: 3px;
  border: 1px solid #f0f0f0;
  color: #000;
  word-break: break-all;
}
.im-container .chatList-item .chatList__msg--text[data-v-401c6b98] {
  line-height: 24px;
}
.im-container .chatList-item .chatList__msg--text[data-v-401c6b98] img {
  vertical-align: top;
  width: 24px;
  height: 24px;
  display: inline-block;
}
.im-container .chatList-item .chatList-arrow[data-v-401c6b98] {
  top: 6px;
  left: -8px;
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 8px;
  border-left-width: 0;
  border-right-color: #ebeef5;
}
.im-container .chatList-item .chatList-arrow[data-v-401c6b98]::after {
  content: " ";
  top: -7px;
  left: 1px;
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 7px;
  border-left-width: 0;
  border-right-color: #ebeef5;
}
.im-container .chatList-item.chatList-item--mine .chatList-text .chatList-arrow[data-v-401c6b98] {
  left: auto;
  right: -5px;
  border-color: transparent;
  border-style: solid;
  border-width: 8px;
  border-right-width: 0;
  border-left-color: #409eff;
}
.im-container .chatList-item.chatList-item--mine .chatList-text .chatList-arrow[data-v-401c6b98]::after {
  left: auto;
  right: -2px;
  border-color: transparent;
  border-style: solid;
  border-width: 7px;
  border-right-width: 0;
  border-left-color: #409eff;
}
.im-container .chatList-item.chatList-item--mine[data-v-401c6b98] {
  text-align: right;
  padding-left: 0;
  padding-right: 60px;
}
.im-container .chatList-item.chatList-item--mine .chatList-user[data-v-401c6b98] {
  left: auto;
  right: 3px;
}
.im-container .chatList-item.chatList-item--mine .chatList-user cite[data-v-401c6b98] {
  left: auto;
  right: 60px;
  text-align: right;
}
.im-container .chatList-item.chatList-item--mine .chatList-user cite i[data-v-401c6b98] {
  padding-left: 0;
  padding-right: 15px;
}
.im-container .chatList-item.chatList-item--mine .chatList-text[data-v-401c6b98] {
  margin-left: 0;
  text-align: left;
  background-color: #409eff;
  color: #fff;
}
.im-container .chatList-item .chatList__msg--video[data-v-401c6b98],
.im-container .chatList-item .chatList__msg--file[data-v-401c6b98] {
  position: relative;
  max-width: 100%;
  min-width: 200px;
  width: 100%;
  margin: 10px 0;
  overflow: hidden;
  border-radius: 5px;
  cursor: pointer;
  display: block;
}
.im-container .chatList-item .chatList__msg--img[data-v-401c6b98] {
  position: relative;
  max-width: 200px;
  width: 100%;
  margin: 10px 0;
  overflow: hidden;
  border-radius: 5px;
  cursor: pointer;
  display: block;
}
.emjioBox[data-v-401c6b98] {
  background: #fff;
  height: 150px;
  width: 300px;
  overflow: auto;
  text-align: left;
}
.emjioBox .emjio[data-v-401c6b98] {
  padding: 0;
}
.emjioBox li[data-v-401c6b98] {
  display: inline-block;
  width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  cursor: pointer;
}