@charset "UTF-8";
#common-file-uploader[data-v-2f8b7038] {
  margin: 0;
  padding: 0;
  font-size: 0;
}
#common-file-uploader.hasDefault .el-upload-list__item[data-v-2f8b7038]:first-child {
  margin-top: 5px;
}
#common-file-uploader .el-upload-list[data-v-2f8b7038].uploader-file {
  border-bottom: none;
  height: 25px !important;
  line-height: 25px;
}
#common-file-uploader .el-upload-list[data-v-2f8b7038].uploader-file:hover {
  background-color: #f5f7fa;
}
#common-file-uploader[data-v-2f8b7038].uploader-file-icon:before {
  content: "" !important;
}
#common-file-uploader[data-v-2f8b7038].uploader-file-actions > span {
  margin-right: 6px;
}

/* 隐藏上传按钮 */
#file-uploader-btn[data-v-2f8b7038] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}