.specificMainBox[data-v-500bd310] {
  overflow: auto;
  overflow-x: hidden;
  height: calc(100% - 180px);
}
.specificMainBox .specificTItle[data-v-500bd310] {
  margin: 24px 0;
}
.specificMainBox .specificTItle .el-divider__text[data-v-500bd310] {
  font-size: 14px !important;
}
.specificMainBox .navigationBigBox[data-v-500bd310] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.specificMainBox .navigationBigBox .navigationBox[data-v-500bd310] {
  cursor: pointer;
  text-align: center;
  color: #606266;
  font-size: 12px;
  margin-bottom: 10px;
  line-height: initial;
}
.specificMainBox .navigationBigBox .navigationBox .navigationImgBox[data-v-500bd310] {
  width: 65px;
  height: 55px;
  display: block;
  border-radius: 10px;
  overflow: hidden;
  margin: 0 auto 10px;
  position: relative;
}
.specificMainBox .navigationBigBox .navigationBox .navigationImgBox img[data-v-500bd310] {
  width: 100%;
  height: 100%;
}
.specificMainBox .navigationBigBox .navigationBox .icon-checked[data-v-500bd310] {
  display: block;
  width: 0px;
  height: 0px;
  border: 10px solid #409eff;
  border-left: 10px solid transparent;
  border-top: 10px solid transparent;
  border-bottom-right-radius: 10px;
  position: absolute;
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
  right: -1px;
  bottom: -1px;
}
.specificMainBox .navigationBigBox .navigationBox .icon-checked i[data-v-500bd310] {
  position: absolute;
  top: -2px;
  left: -3px;
  font-size: 12px;
  color: #fff;
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
}
.specificMainBox .navigationBigBox .navigationBox span[data-v-500bd310] {
  height: 18px;
  line-height: 18px;
  width: 70px;
  display: block;
  overflow: hidden;
  margin: auto;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.specificMainBox .colorList[data-v-500bd310] {
  padding: 10px 0;
  line-height: initial;
}
.specificMainBox .colorList .el-tag[data-v-500bd310] {
  width: 24px;
  height: 24px;
  border: none !important;
  margin-right: 4px;
  border-radius: 2px;
  cursor: pointer;
  padding: 0;
  text-align: center;
  vertical-align: top;
}
.specificMainBox .colorList .el-icon-check[data-v-500bd310] {
  color: #fff;
  font-size: 16px;
  line-height: 24px;
}
.specificMainBox .drawer-item[data-v-500bd310] {
  color: #606266;
  font-size: 14px;
  padding: 12px 0;
  line-height: initial;
}
.specificMainBox .drawer-switch[data-v-500bd310] {
  float: right;
}