.lightWhite #app .blue .logo-content {
  background-color: #1B60A5;
}
.lightWhite #app .blue .avue-sidebar {
  background-color: #FFFFFF;
}
.lightWhite #app .blue .avue-sidebar .el-menu {
  background-color: #FFFFFF;
}
.lightWhite #app .blue .avue-sidebar .el-menu .nest-menu .menu-wrapper {
  background-color: #FFFFFF;
}
.lightWhite #app .blue .avue-sidebar .el-menu .el-submenu__title i {
  color: #333333;
}
.lightWhite #app .blue .avue-sidebar .el-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite #app .blue .avue-sidebar .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite #app .blue .avue-sidebar .el-menu .el-submenu__title:hover i {
  color: #1B60A5;
}
.lightWhite #app .blue .avue-sidebar .el-menu .el-submenu__title:hover span {
  color: #1B60A5;
}
.lightWhite #app .blue .avue-sidebar .el-menu .el-menu-item {
  background-color: #FFFFFF;
}
.lightWhite #app .blue .avue-sidebar .el-menu .el-menu-item i {
  color: #333333;
}
.lightWhite #app .blue .avue-sidebar .el-menu .el-menu-item span {
  color: #333333;
}
.lightWhite #app .blue .avue-sidebar .el-menu .el-menu-item.is-active {
  background-color: #1B60A5 !important;
  color: #FFFFFF;
}
.lightWhite #app .blue .avue-sidebar .el-menu .el-menu-item.is-active i {
  color: #FFFFFF;
}
.lightWhite #app .blue .avue-sidebar .el-menu .el-menu-item.is-active span {
  color: #FFFFFF;
}
.lightWhite #app .blue .avue-sidebar .el-menu .el-menu-item.is-active:hover span {
  color: #FFFFFF;
}
.lightWhite #app .blue .avue-sidebar .el-menu .el-menu-item.is-active:hover i {
  color: #FFFFFF;
}
.lightWhite #app .blue .avue-sidebar .el-menu .el-menu-item:hover i {
  color: #1B60A5;
}
.lightWhite #app .blue .avue-sidebar .el-menu .el-menu-item:hover span {
  color: #1B60A5;
}
.lightWhite #app .blue .el-menu--vertical {
  background-color: #FFFFFF;
}
.lightWhite #app .blue .el-menu--vertical .el-menu--popup {
  max-height: 600px !important;
  overflow: auto !important;
}
.lightWhite #app .blue .el-menu--vertical .el-menu {
  background-color: #FFFFFF;
}
.lightWhite #app .blue .el-menu--vertical .el-menu .el-menu-item {
  background-color: #FFFFFF;
}
.lightWhite #app .blue .el-menu--vertical .el-menu .el-menu-item i {
  color: #333333;
}
.lightWhite #app .blue .el-menu--vertical .el-menu .el-menu-item span {
  color: #333333;
}
.lightWhite #app .blue .el-menu--vertical .el-menu .el-submenu__title i {
  color: #333333;
}
.lightWhite #app .blue .el-menu--vertical .el-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite #app .blue .el-menu--vertical .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite #app .blue .el-menu--vertical .el-menu .el-submenu__title:hover span {
  color: #1B60A5;
}
.lightWhite #app .blue .el-menu--vertical .el-menu .el-submenu__title:hover i {
  color: #1B60A5;
}
.lightWhite #app .blue .el-menu--vertical .el-menu .el-menu-item:hover span {
  color: #1B60A5;
}
.lightWhite #app .blue .el-menu--vertical .el-menu .el-menu-item:hover i {
  color: #1B60A5;
}
.lightWhite #app .blue .el-menu--vertical .el-menu .el-menu-item.is-active {
  background-color: #1B60A5;
  color: #333333;
}
.lightWhite #app .blue .el-menu--vertical .el-menu .el-menu-item.is-active:hover span {
  color: #333333;
}
.lightWhite #app .blue .el-menu--vertical .el-menu .el-menu-item.is-active:hover i {
  color: #333333;
}
.lightWhite #app .blue .avue-top {
  color: #FFFFFF;
  background-color: #1B60A5 !important;
}
.lightWhite #app .blue .avue-top .avue-top-sidebar .el-menu {
  background-color: #1B60A5;
}
.lightWhite #app .blue .avue-top .avue-top-sidebar .el-menu .el-submenu__title {
  color: #FFFFFF;
}
.lightWhite #app .blue .avue-top .avue-top-sidebar .el-menu .el-submenu__title i {
  color: #FFFFFF;
  margin-right: 10px;
}
.lightWhite #app .blue .avue-top .avue-top-sidebar .el-menu .el-submenu__title i:last-child {
  display: none;
}
.lightWhite #app .blue .avue-top .avue-top-sidebar .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite #app .blue .avue-top .avue-top-sidebar .el-menu .el-submenu__title:hover span {
  color: #FFFFFF;
}
.lightWhite #app .blue .avue-top .avue-top-sidebar .el-menu .el-submenu__title:hover i {
  color: #FFFFFF;
}
.lightWhite #app .blue .avue-top .avue-top-sidebar .el-menu .headMenuItem {
  max-width: 160px;
}
.lightWhite #app .blue .top-menu .el-menu-item i {
  color: #FFFFFF !important;
}
.lightWhite #app .blue .top-menu .el-menu-item span {
  color: #FFFFFF;
}
.lightWhite #app .blue .blend .el-menu .menu-wrapper .elMenuItem {
  background-color: #FFFFFF;
}
.lightWhite #app .blue .plain .avue-layout {
  padding-left: 80px;
}
.lightWhite #app .blue .plain .avue-left {
  width: 80px;
}
.lightWhite #app .blue .plain .el-menu {
  background-color: #FFFFFF !important;
}
.lightWhite #app .blue .plain .el-menu .nest-menu .el-submenu__title {
  height: 70px;
}
.lightWhite #app .blue .plain .el-menu .nest-menu .el-submenu__title i {
  font-size: 28px;
}

.lightWhite > .blue .headMenuItem {
  max-width: 150px;
}
.lightWhite > .blue .el-menu--popup {
  max-height: 600px;
  overflow: auto;
}
.lightWhite > .blue .el-menu {
  background-color: transparent;
}
.lightWhite > .blue .el-menu .menu-wrapper {
  background-color: #FFFFFF;
}
.lightWhite > .blue .el-menu .menu-wrapper .nest-menu {
  width: 100%;
}
.lightWhite > .blue .el-menu .menu-wrapper .nest-menu .el-submenu__title {
  background-color: #FFFFFF;
  height: 46px;
  line-height: 46px;
}
.lightWhite > .blue .el-menu .menu-wrapper .nest-menu .el-submenu__title i {
  color: #333333;
  margin-left: 15px;
  margin-right: 6px;
  font-size: 20px;
}
.lightWhite > .blue .el-menu .menu-wrapper .nest-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite > .blue .el-menu .el-menu-item {
  background-color: #FFFFFF;
  height: 46px;
  line-height: 46px;
}
.lightWhite > .blue .el-menu .el-menu-item i {
  color: #333333;
  margin-left: 15px;
  margin-right: 6px;
  font-size: 20px;
}
.lightWhite > .blue .el-menu .el-menu-item span {
  color: #333333;
}
.lightWhite > .blue .el-menu .el-submenu__title i {
  color: #333333;
}
.lightWhite > .blue .el-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite > .blue .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite > .blue .el-menu .el-submenu__title:hover span {
  color: #1B60A5;
}
.lightWhite > .blue .el-menu .el-submenu__title:hover i {
  color: #1B60A5;
}
.lightWhite > .blue .el-menu .el-menu-item:hover span {
  color: #1B60A5;
}
.lightWhite > .blue .el-menu .el-menu-item:hover i {
  color: #1B60A5;
}
.lightWhite > .blue .el-menu .el-menu-item.is-active {
  background-color: #1B60A5;
  color: #FFFFFF;
}
.lightWhite > .blue .el-menu .el-menu-item.is-active i {
  color: #FFFFFF;
}
.lightWhite > .blue .el-menu .el-menu-item.is-active span {
  color: #FFFFFF;
}
.lightWhite > .blue .el-menu .el-menu-item.is-active:hover span {
  color: #FFFFFF;
}
.lightWhite > .blue .el-menu .el-menu-item.is-active:hover i {
  color: #FFFFFF;
}
.lightWhite > .blue .el-menu .el-menu-item:hover i {
  color: #1B60A5;
}
.lightWhite > .blue .el-menu .el-menu-item:hover span {
  color: #1B60A5;
}

.lightWhite #app .purple .logo-content {
  background-color: #722ED1;
}
.lightWhite #app .purple .avue-sidebar {
  background-color: #FFFFFF;
}
.lightWhite #app .purple .avue-sidebar .el-menu {
  background-color: #FFFFFF;
}
.lightWhite #app .purple .avue-sidebar .el-menu .nest-menu .menu-wrapper {
  background-color: #FFFFFF;
}
.lightWhite #app .purple .avue-sidebar .el-menu .el-submenu__title i {
  color: #333333;
}
.lightWhite #app .purple .avue-sidebar .el-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite #app .purple .avue-sidebar .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite #app .purple .avue-sidebar .el-menu .el-submenu__title:hover i {
  color: #722ED1;
}
.lightWhite #app .purple .avue-sidebar .el-menu .el-submenu__title:hover span {
  color: #722ED1;
}
.lightWhite #app .purple .avue-sidebar .el-menu .el-menu-item {
  background-color: #FFFFFF;
}
.lightWhite #app .purple .avue-sidebar .el-menu .el-menu-item i {
  color: #333333;
}
.lightWhite #app .purple .avue-sidebar .el-menu .el-menu-item span {
  color: #333333;
}
.lightWhite #app .purple .avue-sidebar .el-menu .el-menu-item.is-active {
  background-color: #722ED1 !important;
  color: #FFFFFF;
}
.lightWhite #app .purple .avue-sidebar .el-menu .el-menu-item.is-active i {
  color: #FFFFFF;
}
.lightWhite #app .purple .avue-sidebar .el-menu .el-menu-item.is-active span {
  color: #FFFFFF;
}
.lightWhite #app .purple .avue-sidebar .el-menu .el-menu-item.is-active:hover span {
  color: #FFFFFF;
}
.lightWhite #app .purple .avue-sidebar .el-menu .el-menu-item.is-active:hover i {
  color: #FFFFFF;
}
.lightWhite #app .purple .avue-sidebar .el-menu .el-menu-item:hover i {
  color: #722ED1;
}
.lightWhite #app .purple .avue-sidebar .el-menu .el-menu-item:hover span {
  color: #722ED1;
}
.lightWhite #app .purple .el-menu--vertical {
  background-color: #FFFFFF;
}
.lightWhite #app .purple .el-menu--vertical .el-menu--popup {
  max-height: 600px !important;
  overflow: auto !important;
}
.lightWhite #app .purple .el-menu--vertical .el-menu {
  background-color: #FFFFFF;
}
.lightWhite #app .purple .el-menu--vertical .el-menu .el-menu-item {
  background-color: #FFFFFF;
}
.lightWhite #app .purple .el-menu--vertical .el-menu .el-menu-item i {
  color: #333333;
}
.lightWhite #app .purple .el-menu--vertical .el-menu .el-menu-item span {
  color: #333333;
}
.lightWhite #app .purple .el-menu--vertical .el-menu .el-submenu__title i {
  color: #333333;
}
.lightWhite #app .purple .el-menu--vertical .el-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite #app .purple .el-menu--vertical .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite #app .purple .el-menu--vertical .el-menu .el-submenu__title:hover span {
  color: #722ED1;
}
.lightWhite #app .purple .el-menu--vertical .el-menu .el-submenu__title:hover i {
  color: #722ED1;
}
.lightWhite #app .purple .el-menu--vertical .el-menu .el-menu-item:hover span {
  color: #722ED1;
}
.lightWhite #app .purple .el-menu--vertical .el-menu .el-menu-item:hover i {
  color: #722ED1;
}
.lightWhite #app .purple .el-menu--vertical .el-menu .el-menu-item.is-active {
  background-color: #722ED1;
  color: #333333;
}
.lightWhite #app .purple .el-menu--vertical .el-menu .el-menu-item.is-active:hover span {
  color: #333333;
}
.lightWhite #app .purple .el-menu--vertical .el-menu .el-menu-item.is-active:hover i {
  color: #333333;
}
.lightWhite #app .purple .avue-top {
  color: #FFFFFF;
  background-color: #722ED1 !important;
}
.lightWhite #app .purple .avue-top .avue-top-sidebar .el-menu {
  background-color: #722ED1;
}
.lightWhite #app .purple .avue-top .avue-top-sidebar .el-menu .el-submenu__title {
  color: #FFFFFF;
}
.lightWhite #app .purple .avue-top .avue-top-sidebar .el-menu .el-submenu__title i {
  color: #FFFFFF;
  margin-right: 10px;
}
.lightWhite #app .purple .avue-top .avue-top-sidebar .el-menu .el-submenu__title i:last-child {
  display: none;
}
.lightWhite #app .purple .avue-top .avue-top-sidebar .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite #app .purple .avue-top .avue-top-sidebar .el-menu .el-submenu__title:hover span {
  color: #FFFFFF;
}
.lightWhite #app .purple .avue-top .avue-top-sidebar .el-menu .el-submenu__title:hover i {
  color: #FFFFFF;
}
.lightWhite #app .purple .avue-top .avue-top-sidebar .el-menu .headMenuItem {
  max-width: 160px;
}
.lightWhite #app .purple .top-menu .el-menu-item i {
  color: #FFFFFF !important;
}
.lightWhite #app .purple .top-menu .el-menu-item span {
  color: #FFFFFF;
}
.lightWhite #app .purple .blend .el-menu .menu-wrapper .elMenuItem {
  background-color: #FFFFFF;
}
.lightWhite #app .purple .plain .avue-layout {
  padding-left: 80px;
}
.lightWhite #app .purple .plain .avue-left {
  width: 80px;
}
.lightWhite #app .purple .plain .el-menu {
  background-color: #FFFFFF !important;
}
.lightWhite #app .purple .plain .el-menu .nest-menu .el-submenu__title {
  height: 70px;
}
.lightWhite #app .purple .plain .el-menu .nest-menu .el-submenu__title i {
  font-size: 28px;
}

.lightWhite > .purple .headMenuItem {
  max-width: 150px;
}
.lightWhite > .purple .el-menu--popup {
  max-height: 600px;
  overflow: auto;
}
.lightWhite > .purple .el-menu {
  background-color: transparent;
}
.lightWhite > .purple .el-menu .menu-wrapper {
  background-color: #FFFFFF;
}
.lightWhite > .purple .el-menu .menu-wrapper .nest-menu {
  width: 100%;
}
.lightWhite > .purple .el-menu .menu-wrapper .nest-menu .el-submenu__title {
  background-color: #FFFFFF;
  height: 46px;
  line-height: 46px;
}
.lightWhite > .purple .el-menu .menu-wrapper .nest-menu .el-submenu__title i {
  color: #333333;
  margin-left: 15px;
  margin-right: 6px;
  font-size: 20px;
}
.lightWhite > .purple .el-menu .menu-wrapper .nest-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite > .purple .el-menu .el-menu-item {
  background-color: #FFFFFF;
  height: 46px;
  line-height: 46px;
}
.lightWhite > .purple .el-menu .el-menu-item i {
  color: #333333;
  margin-left: 15px;
  margin-right: 6px;
  font-size: 20px;
}
.lightWhite > .purple .el-menu .el-menu-item span {
  color: #333333;
}
.lightWhite > .purple .el-menu .el-submenu__title i {
  color: #333333;
}
.lightWhite > .purple .el-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite > .purple .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite > .purple .el-menu .el-submenu__title:hover span {
  color: #722ED1;
}
.lightWhite > .purple .el-menu .el-submenu__title:hover i {
  color: #722ED1;
}
.lightWhite > .purple .el-menu .el-menu-item:hover span {
  color: #722ED1;
}
.lightWhite > .purple .el-menu .el-menu-item:hover i {
  color: #722ED1;
}
.lightWhite > .purple .el-menu .el-menu-item.is-active {
  background-color: #722ED1;
  color: #FFFFFF;
}
.lightWhite > .purple .el-menu .el-menu-item.is-active i {
  color: #FFFFFF;
}
.lightWhite > .purple .el-menu .el-menu-item.is-active span {
  color: #FFFFFF;
}
.lightWhite > .purple .el-menu .el-menu-item.is-active:hover span {
  color: #FFFFFF;
}
.lightWhite > .purple .el-menu .el-menu-item.is-active:hover i {
  color: #FFFFFF;
}
.lightWhite > .purple .el-menu .el-menu-item:hover i {
  color: #722ED1;
}
.lightWhite > .purple .el-menu .el-menu-item:hover span {
  color: #722ED1;
}

.lightWhite #app .azure .logo-content {
  background-color: #211BCE;
}
.lightWhite #app .azure .avue-sidebar {
  background-color: #FFFFFF;
}
.lightWhite #app .azure .avue-sidebar .el-menu {
  background-color: #FFFFFF;
}
.lightWhite #app .azure .avue-sidebar .el-menu .nest-menu .menu-wrapper {
  background-color: #FFFFFF;
}
.lightWhite #app .azure .avue-sidebar .el-menu .el-submenu__title i {
  color: #333333;
}
.lightWhite #app .azure .avue-sidebar .el-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite #app .azure .avue-sidebar .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite #app .azure .avue-sidebar .el-menu .el-submenu__title:hover i {
  color: #211BCE;
}
.lightWhite #app .azure .avue-sidebar .el-menu .el-submenu__title:hover span {
  color: #211BCE;
}
.lightWhite #app .azure .avue-sidebar .el-menu .el-menu-item {
  background-color: #FFFFFF;
}
.lightWhite #app .azure .avue-sidebar .el-menu .el-menu-item i {
  color: #333333;
}
.lightWhite #app .azure .avue-sidebar .el-menu .el-menu-item span {
  color: #333333;
}
.lightWhite #app .azure .avue-sidebar .el-menu .el-menu-item.is-active {
  background-color: #211BCE !important;
  color: #FFFFFF;
}
.lightWhite #app .azure .avue-sidebar .el-menu .el-menu-item.is-active i {
  color: #FFFFFF;
}
.lightWhite #app .azure .avue-sidebar .el-menu .el-menu-item.is-active span {
  color: #FFFFFF;
}
.lightWhite #app .azure .avue-sidebar .el-menu .el-menu-item.is-active:hover span {
  color: #FFFFFF;
}
.lightWhite #app .azure .avue-sidebar .el-menu .el-menu-item.is-active:hover i {
  color: #FFFFFF;
}
.lightWhite #app .azure .avue-sidebar .el-menu .el-menu-item:hover i {
  color: #211BCE;
}
.lightWhite #app .azure .avue-sidebar .el-menu .el-menu-item:hover span {
  color: #211BCE;
}
.lightWhite #app .azure .el-menu--vertical {
  background-color: #FFFFFF;
}
.lightWhite #app .azure .el-menu--vertical .el-menu--popup {
  max-height: 600px !important;
  overflow: auto !important;
}
.lightWhite #app .azure .el-menu--vertical .el-menu {
  background-color: #FFFFFF;
}
.lightWhite #app .azure .el-menu--vertical .el-menu .el-menu-item {
  background-color: #FFFFFF;
}
.lightWhite #app .azure .el-menu--vertical .el-menu .el-menu-item i {
  color: #333333;
}
.lightWhite #app .azure .el-menu--vertical .el-menu .el-menu-item span {
  color: #333333;
}
.lightWhite #app .azure .el-menu--vertical .el-menu .el-submenu__title i {
  color: #333333;
}
.lightWhite #app .azure .el-menu--vertical .el-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite #app .azure .el-menu--vertical .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite #app .azure .el-menu--vertical .el-menu .el-submenu__title:hover span {
  color: #211BCE;
}
.lightWhite #app .azure .el-menu--vertical .el-menu .el-submenu__title:hover i {
  color: #211BCE;
}
.lightWhite #app .azure .el-menu--vertical .el-menu .el-menu-item:hover span {
  color: #211BCE;
}
.lightWhite #app .azure .el-menu--vertical .el-menu .el-menu-item:hover i {
  color: #211BCE;
}
.lightWhite #app .azure .el-menu--vertical .el-menu .el-menu-item.is-active {
  background-color: #211BCE;
  color: #333333;
}
.lightWhite #app .azure .el-menu--vertical .el-menu .el-menu-item.is-active:hover span {
  color: #333333;
}
.lightWhite #app .azure .el-menu--vertical .el-menu .el-menu-item.is-active:hover i {
  color: #333333;
}
.lightWhite #app .azure .avue-top {
  color: #FFFFFF;
  background-color: #211BCE !important;
}
.lightWhite #app .azure .avue-top .avue-top-sidebar .el-menu {
  background-color: #211BCE;
}
.lightWhite #app .azure .avue-top .avue-top-sidebar .el-menu .el-submenu__title {
  color: #FFFFFF;
}
.lightWhite #app .azure .avue-top .avue-top-sidebar .el-menu .el-submenu__title i {
  color: #FFFFFF;
  margin-right: 10px;
}
.lightWhite #app .azure .avue-top .avue-top-sidebar .el-menu .el-submenu__title i:last-child {
  display: none;
}
.lightWhite #app .azure .avue-top .avue-top-sidebar .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite #app .azure .avue-top .avue-top-sidebar .el-menu .el-submenu__title:hover span {
  color: #FFFFFF;
}
.lightWhite #app .azure .avue-top .avue-top-sidebar .el-menu .el-submenu__title:hover i {
  color: #FFFFFF;
}
.lightWhite #app .azure .avue-top .avue-top-sidebar .el-menu .headMenuItem {
  max-width: 160px;
}
.lightWhite #app .azure .top-menu .el-menu-item i {
  color: #FFFFFF !important;
}
.lightWhite #app .azure .top-menu .el-menu-item span {
  color: #FFFFFF;
}
.lightWhite #app .azure .blend .el-menu .menu-wrapper .elMenuItem {
  background-color: #FFFFFF;
}
.lightWhite #app .azure .plain .avue-layout {
  padding-left: 80px;
}
.lightWhite #app .azure .plain .avue-left {
  width: 80px;
}
.lightWhite #app .azure .plain .el-menu {
  background-color: #FFFFFF !important;
}
.lightWhite #app .azure .plain .el-menu .nest-menu .el-submenu__title {
  height: 70px;
}
.lightWhite #app .azure .plain .el-menu .nest-menu .el-submenu__title i {
  font-size: 28px;
}

.lightWhite > .azure .headMenuItem {
  max-width: 150px;
}
.lightWhite > .azure .el-menu--popup {
  max-height: 600px;
  overflow: auto;
}
.lightWhite > .azure .el-menu {
  background-color: transparent;
}
.lightWhite > .azure .el-menu .menu-wrapper {
  background-color: #FFFFFF;
}
.lightWhite > .azure .el-menu .menu-wrapper .nest-menu {
  width: 100%;
}
.lightWhite > .azure .el-menu .menu-wrapper .nest-menu .el-submenu__title {
  background-color: #FFFFFF;
  height: 46px;
  line-height: 46px;
}
.lightWhite > .azure .el-menu .menu-wrapper .nest-menu .el-submenu__title i {
  color: #333333;
  margin-left: 15px;
  margin-right: 6px;
  font-size: 20px;
}
.lightWhite > .azure .el-menu .menu-wrapper .nest-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite > .azure .el-menu .el-menu-item {
  background-color: #FFFFFF;
  height: 46px;
  line-height: 46px;
}
.lightWhite > .azure .el-menu .el-menu-item i {
  color: #333333;
  margin-left: 15px;
  margin-right: 6px;
  font-size: 20px;
}
.lightWhite > .azure .el-menu .el-menu-item span {
  color: #333333;
}
.lightWhite > .azure .el-menu .el-submenu__title i {
  color: #333333;
}
.lightWhite > .azure .el-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite > .azure .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite > .azure .el-menu .el-submenu__title:hover span {
  color: #211BCE;
}
.lightWhite > .azure .el-menu .el-submenu__title:hover i {
  color: #211BCE;
}
.lightWhite > .azure .el-menu .el-menu-item:hover span {
  color: #211BCE;
}
.lightWhite > .azure .el-menu .el-menu-item:hover i {
  color: #211BCE;
}
.lightWhite > .azure .el-menu .el-menu-item.is-active {
  background-color: #211BCE;
  color: #FFFFFF;
}
.lightWhite > .azure .el-menu .el-menu-item.is-active i {
  color: #FFFFFF;
}
.lightWhite > .azure .el-menu .el-menu-item.is-active span {
  color: #FFFFFF;
}
.lightWhite > .azure .el-menu .el-menu-item.is-active:hover span {
  color: #FFFFFF;
}
.lightWhite > .azure .el-menu .el-menu-item.is-active:hover i {
  color: #FFFFFF;
}
.lightWhite > .azure .el-menu .el-menu-item:hover i {
  color: #211BCE;
}
.lightWhite > .azure .el-menu .el-menu-item:hover span {
  color: #211BCE;
}

.lightWhite #app .blackblue .logo-content {
  background-color: #211BCE;
}
.lightWhite #app .blackblue .avue-sidebar {
  background-color: #FFFFFF;
}
.lightWhite #app .blackblue .avue-sidebar .el-menu {
  background-color: #FFFFFF;
}
.lightWhite #app .blackblue .avue-sidebar .el-menu .nest-menu .menu-wrapper {
  background-color: #FFFFFF;
}
.lightWhite #app .blackblue .avue-sidebar .el-menu .el-submenu__title i {
  color: #333333;
}
.lightWhite #app .blackblue .avue-sidebar .el-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite #app .blackblue .avue-sidebar .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite #app .blackblue .avue-sidebar .el-menu .el-submenu__title:hover i {
  color: #211BCE;
}
.lightWhite #app .blackblue .avue-sidebar .el-menu .el-submenu__title:hover span {
  color: #211BCE;
}
.lightWhite #app .blackblue .avue-sidebar .el-menu .el-menu-item {
  background-color: #FFFFFF;
}
.lightWhite #app .blackblue .avue-sidebar .el-menu .el-menu-item i {
  color: #333333;
}
.lightWhite #app .blackblue .avue-sidebar .el-menu .el-menu-item span {
  color: #333333;
}
.lightWhite #app .blackblue .avue-sidebar .el-menu .el-menu-item.is-active {
  background-color: #211BCE !important;
  color: #FFFFFF;
}
.lightWhite #app .blackblue .avue-sidebar .el-menu .el-menu-item.is-active i {
  color: #FFFFFF;
}
.lightWhite #app .blackblue .avue-sidebar .el-menu .el-menu-item.is-active span {
  color: #FFFFFF;
}
.lightWhite #app .blackblue .avue-sidebar .el-menu .el-menu-item.is-active:hover span {
  color: #FFFFFF;
}
.lightWhite #app .blackblue .avue-sidebar .el-menu .el-menu-item.is-active:hover i {
  color: #FFFFFF;
}
.lightWhite #app .blackblue .avue-sidebar .el-menu .el-menu-item:hover i {
  color: #211BCE;
}
.lightWhite #app .blackblue .avue-sidebar .el-menu .el-menu-item:hover span {
  color: #211BCE;
}
.lightWhite #app .blackblue .el-menu--vertical {
  background-color: #FFFFFF;
}
.lightWhite #app .blackblue .el-menu--vertical .el-menu--popup {
  max-height: 600px !important;
  overflow: auto !important;
}
.lightWhite #app .blackblue .el-menu--vertical .el-menu {
  background-color: #FFFFFF;
}
.lightWhite #app .blackblue .el-menu--vertical .el-menu .el-menu-item {
  background-color: #FFFFFF;
}
.lightWhite #app .blackblue .el-menu--vertical .el-menu .el-menu-item i {
  color: #333333;
}
.lightWhite #app .blackblue .el-menu--vertical .el-menu .el-menu-item span {
  color: #333333;
}
.lightWhite #app .blackblue .el-menu--vertical .el-menu .el-submenu__title i {
  color: #333333;
}
.lightWhite #app .blackblue .el-menu--vertical .el-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite #app .blackblue .el-menu--vertical .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite #app .blackblue .el-menu--vertical .el-menu .el-submenu__title:hover span {
  color: #211BCE;
}
.lightWhite #app .blackblue .el-menu--vertical .el-menu .el-submenu__title:hover i {
  color: #211BCE;
}
.lightWhite #app .blackblue .el-menu--vertical .el-menu .el-menu-item:hover span {
  color: #211BCE;
}
.lightWhite #app .blackblue .el-menu--vertical .el-menu .el-menu-item:hover i {
  color: #211BCE;
}
.lightWhite #app .blackblue .el-menu--vertical .el-menu .el-menu-item.is-active {
  background-color: #211BCE;
  color: #333333;
}
.lightWhite #app .blackblue .el-menu--vertical .el-menu .el-menu-item.is-active:hover span {
  color: #333333;
}
.lightWhite #app .blackblue .el-menu--vertical .el-menu .el-menu-item.is-active:hover i {
  color: #333333;
}
.lightWhite #app .blackblue .avue-top {
  color: #FFFFFF;
  background-color: #211BCE !important;
}
.lightWhite #app .blackblue .avue-top .avue-top-sidebar .el-menu {
  background-color: #211BCE;
}
.lightWhite #app .blackblue .avue-top .avue-top-sidebar .el-menu .el-submenu__title {
  color: #FFFFFF;
}
.lightWhite #app .blackblue .avue-top .avue-top-sidebar .el-menu .el-submenu__title i {
  color: #FFFFFF;
  margin-right: 10px;
}
.lightWhite #app .blackblue .avue-top .avue-top-sidebar .el-menu .el-submenu__title i:last-child {
  display: none;
}
.lightWhite #app .blackblue .avue-top .avue-top-sidebar .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite #app .blackblue .avue-top .avue-top-sidebar .el-menu .el-submenu__title:hover span {
  color: #FFFFFF;
}
.lightWhite #app .blackblue .avue-top .avue-top-sidebar .el-menu .el-submenu__title:hover i {
  color: #FFFFFF;
}
.lightWhite #app .blackblue .avue-top .avue-top-sidebar .el-menu .headMenuItem {
  max-width: 160px;
}
.lightWhite #app .blackblue .top-menu .el-menu-item i {
  color: #FFFFFF !important;
}
.lightWhite #app .blackblue .top-menu .el-menu-item span {
  color: #FFFFFF;
}
.lightWhite #app .blackblue .blend .el-menu .menu-wrapper .elMenuItem {
  background-color: #FFFFFF;
}
.lightWhite #app .blackblue .plain .avue-layout {
  padding-left: 80px;
}
.lightWhite #app .blackblue .plain .avue-left {
  width: 80px;
}
.lightWhite #app .blackblue .plain .el-menu {
  background-color: #FFFFFF !important;
}
.lightWhite #app .blackblue .plain .el-menu .nest-menu .el-submenu__title {
  height: 70px;
}
.lightWhite #app .blackblue .plain .el-menu .nest-menu .el-submenu__title i {
  font-size: 28px;
}

.lightWhite > .blackblue .headMenuItem {
  max-width: 150px;
}
.lightWhite > .blackblue .el-menu--popup {
  max-height: 600px;
  overflow: auto;
}
.lightWhite > .blackblue .el-menu {
  background-color: transparent;
}
.lightWhite > .blackblue .el-menu .menu-wrapper {
  background-color: #FFFFFF;
}
.lightWhite > .blackblue .el-menu .menu-wrapper .nest-menu {
  width: 100%;
}
.lightWhite > .blackblue .el-menu .menu-wrapper .nest-menu .el-submenu__title {
  background-color: #FFFFFF;
  height: 46px;
  line-height: 46px;
}
.lightWhite > .blackblue .el-menu .menu-wrapper .nest-menu .el-submenu__title i {
  color: #333333;
  margin-left: 15px;
  margin-right: 6px;
  font-size: 20px;
}
.lightWhite > .blackblue .el-menu .menu-wrapper .nest-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite > .blackblue .el-menu .el-menu-item {
  background-color: #FFFFFF;
  height: 46px;
  line-height: 46px;
}
.lightWhite > .blackblue .el-menu .el-menu-item i {
  color: #333333;
  margin-left: 15px;
  margin-right: 6px;
  font-size: 20px;
}
.lightWhite > .blackblue .el-menu .el-menu-item span {
  color: #333333;
}
.lightWhite > .blackblue .el-menu .el-submenu__title i {
  color: #333333;
}
.lightWhite > .blackblue .el-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite > .blackblue .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite > .blackblue .el-menu .el-submenu__title:hover span {
  color: #211BCE;
}
.lightWhite > .blackblue .el-menu .el-submenu__title:hover i {
  color: #211BCE;
}
.lightWhite > .blackblue .el-menu .el-menu-item:hover span {
  color: #211BCE;
}
.lightWhite > .blackblue .el-menu .el-menu-item:hover i {
  color: #211BCE;
}
.lightWhite > .blackblue .el-menu .el-menu-item.is-active {
  background-color: #211BCE;
  color: #FFFFFF;
}
.lightWhite > .blackblue .el-menu .el-menu-item.is-active i {
  color: #FFFFFF;
}
.lightWhite > .blackblue .el-menu .el-menu-item.is-active span {
  color: #FFFFFF;
}
.lightWhite > .blackblue .el-menu .el-menu-item.is-active:hover span {
  color: #FFFFFF;
}
.lightWhite > .blackblue .el-menu .el-menu-item.is-active:hover i {
  color: #FFFFFF;
}
.lightWhite > .blackblue .el-menu .el-menu-item:hover i {
  color: #211BCE;
}
.lightWhite > .blackblue .el-menu .el-menu-item:hover span {
  color: #211BCE;
}

.lightWhite #app .ocean .logo-content {
  background-color: #13C2C2;
}
.lightWhite #app .ocean .avue-sidebar {
  background-color: #FFFFFF;
}
.lightWhite #app .ocean .avue-sidebar .el-menu {
  background-color: #FFFFFF;
}
.lightWhite #app .ocean .avue-sidebar .el-menu .nest-menu .menu-wrapper {
  background-color: #FFFFFF;
}
.lightWhite #app .ocean .avue-sidebar .el-menu .el-submenu__title i {
  color: #333333;
}
.lightWhite #app .ocean .avue-sidebar .el-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite #app .ocean .avue-sidebar .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite #app .ocean .avue-sidebar .el-menu .el-submenu__title:hover i {
  color: #13C2C2;
}
.lightWhite #app .ocean .avue-sidebar .el-menu .el-submenu__title:hover span {
  color: #13C2C2;
}
.lightWhite #app .ocean .avue-sidebar .el-menu .el-menu-item {
  background-color: #FFFFFF;
}
.lightWhite #app .ocean .avue-sidebar .el-menu .el-menu-item i {
  color: #333333;
}
.lightWhite #app .ocean .avue-sidebar .el-menu .el-menu-item span {
  color: #333333;
}
.lightWhite #app .ocean .avue-sidebar .el-menu .el-menu-item.is-active {
  background-color: #13C2C2 !important;
  color: #FFFFFF;
}
.lightWhite #app .ocean .avue-sidebar .el-menu .el-menu-item.is-active i {
  color: #FFFFFF;
}
.lightWhite #app .ocean .avue-sidebar .el-menu .el-menu-item.is-active span {
  color: #FFFFFF;
}
.lightWhite #app .ocean .avue-sidebar .el-menu .el-menu-item.is-active:hover span {
  color: #FFFFFF;
}
.lightWhite #app .ocean .avue-sidebar .el-menu .el-menu-item.is-active:hover i {
  color: #FFFFFF;
}
.lightWhite #app .ocean .avue-sidebar .el-menu .el-menu-item:hover i {
  color: #13C2C2;
}
.lightWhite #app .ocean .avue-sidebar .el-menu .el-menu-item:hover span {
  color: #13C2C2;
}
.lightWhite #app .ocean .el-menu--vertical {
  background-color: #FFFFFF;
}
.lightWhite #app .ocean .el-menu--vertical .el-menu--popup {
  max-height: 600px !important;
  overflow: auto !important;
}
.lightWhite #app .ocean .el-menu--vertical .el-menu {
  background-color: #FFFFFF;
}
.lightWhite #app .ocean .el-menu--vertical .el-menu .el-menu-item {
  background-color: #FFFFFF;
}
.lightWhite #app .ocean .el-menu--vertical .el-menu .el-menu-item i {
  color: #333333;
}
.lightWhite #app .ocean .el-menu--vertical .el-menu .el-menu-item span {
  color: #333333;
}
.lightWhite #app .ocean .el-menu--vertical .el-menu .el-submenu__title i {
  color: #333333;
}
.lightWhite #app .ocean .el-menu--vertical .el-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite #app .ocean .el-menu--vertical .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite #app .ocean .el-menu--vertical .el-menu .el-submenu__title:hover span {
  color: #13C2C2;
}
.lightWhite #app .ocean .el-menu--vertical .el-menu .el-submenu__title:hover i {
  color: #13C2C2;
}
.lightWhite #app .ocean .el-menu--vertical .el-menu .el-menu-item:hover span {
  color: #13C2C2;
}
.lightWhite #app .ocean .el-menu--vertical .el-menu .el-menu-item:hover i {
  color: #13C2C2;
}
.lightWhite #app .ocean .el-menu--vertical .el-menu .el-menu-item.is-active {
  background-color: #13C2C2;
  color: #333333;
}
.lightWhite #app .ocean .el-menu--vertical .el-menu .el-menu-item.is-active:hover span {
  color: #333333;
}
.lightWhite #app .ocean .el-menu--vertical .el-menu .el-menu-item.is-active:hover i {
  color: #333333;
}
.lightWhite #app .ocean .avue-top {
  color: #FFFFFF;
  background-color: #13C2C2 !important;
}
.lightWhite #app .ocean .avue-top .avue-top-sidebar .el-menu {
  background-color: #13C2C2;
}
.lightWhite #app .ocean .avue-top .avue-top-sidebar .el-menu .el-submenu__title {
  color: #FFFFFF;
}
.lightWhite #app .ocean .avue-top .avue-top-sidebar .el-menu .el-submenu__title i {
  color: #FFFFFF;
  margin-right: 10px;
}
.lightWhite #app .ocean .avue-top .avue-top-sidebar .el-menu .el-submenu__title i:last-child {
  display: none;
}
.lightWhite #app .ocean .avue-top .avue-top-sidebar .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite #app .ocean .avue-top .avue-top-sidebar .el-menu .el-submenu__title:hover span {
  color: #FFFFFF;
}
.lightWhite #app .ocean .avue-top .avue-top-sidebar .el-menu .el-submenu__title:hover i {
  color: #FFFFFF;
}
.lightWhite #app .ocean .avue-top .avue-top-sidebar .el-menu .headMenuItem {
  max-width: 160px;
}
.lightWhite #app .ocean .top-menu .el-menu-item i {
  color: #FFFFFF !important;
}
.lightWhite #app .ocean .top-menu .el-menu-item span {
  color: #FFFFFF;
}
.lightWhite #app .ocean .blend .el-menu .menu-wrapper .elMenuItem {
  background-color: #FFFFFF;
}
.lightWhite #app .ocean .plain .avue-layout {
  padding-left: 80px;
}
.lightWhite #app .ocean .plain .avue-left {
  width: 80px;
}
.lightWhite #app .ocean .plain .el-menu {
  background-color: #FFFFFF !important;
}
.lightWhite #app .ocean .plain .el-menu .nest-menu .el-submenu__title {
  height: 70px;
}
.lightWhite #app .ocean .plain .el-menu .nest-menu .el-submenu__title i {
  font-size: 28px;
}

.lightWhite > .ocean .headMenuItem {
  max-width: 150px;
}
.lightWhite > .ocean .el-menu--popup {
  max-height: 600px;
  overflow: auto;
}
.lightWhite > .ocean .el-menu {
  background-color: transparent;
}
.lightWhite > .ocean .el-menu .menu-wrapper {
  background-color: #FFFFFF;
}
.lightWhite > .ocean .el-menu .menu-wrapper .nest-menu {
  width: 100%;
}
.lightWhite > .ocean .el-menu .menu-wrapper .nest-menu .el-submenu__title {
  background-color: #FFFFFF;
  height: 46px;
  line-height: 46px;
}
.lightWhite > .ocean .el-menu .menu-wrapper .nest-menu .el-submenu__title i {
  color: #333333;
  margin-left: 15px;
  margin-right: 6px;
  font-size: 20px;
}
.lightWhite > .ocean .el-menu .menu-wrapper .nest-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite > .ocean .el-menu .el-menu-item {
  background-color: #FFFFFF;
  height: 46px;
  line-height: 46px;
}
.lightWhite > .ocean .el-menu .el-menu-item i {
  color: #333333;
  margin-left: 15px;
  margin-right: 6px;
  font-size: 20px;
}
.lightWhite > .ocean .el-menu .el-menu-item span {
  color: #333333;
}
.lightWhite > .ocean .el-menu .el-submenu__title i {
  color: #333333;
}
.lightWhite > .ocean .el-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite > .ocean .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite > .ocean .el-menu .el-submenu__title:hover span {
  color: #13C2C2;
}
.lightWhite > .ocean .el-menu .el-submenu__title:hover i {
  color: #13C2C2;
}
.lightWhite > .ocean .el-menu .el-menu-item:hover span {
  color: #13C2C2;
}
.lightWhite > .ocean .el-menu .el-menu-item:hover i {
  color: #13C2C2;
}
.lightWhite > .ocean .el-menu .el-menu-item.is-active {
  background-color: #13C2C2;
  color: #FFFFFF;
}
.lightWhite > .ocean .el-menu .el-menu-item.is-active i {
  color: #FFFFFF;
}
.lightWhite > .ocean .el-menu .el-menu-item.is-active span {
  color: #FFFFFF;
}
.lightWhite > .ocean .el-menu .el-menu-item.is-active:hover span {
  color: #FFFFFF;
}
.lightWhite > .ocean .el-menu .el-menu-item.is-active:hover i {
  color: #FFFFFF;
}
.lightWhite > .ocean .el-menu .el-menu-item:hover i {
  color: #13C2C2;
}
.lightWhite > .ocean .el-menu .el-menu-item:hover span {
  color: #13C2C2;
}

.lightWhite #app .green .logo-content {
  background-color: #2f6741;
}
.lightWhite #app .green .avue-sidebar {
  background-color: #FFFFFF;
}
.lightWhite #app .green .avue-sidebar .el-menu {
  background-color: #FFFFFF;
}
.lightWhite #app .green .avue-sidebar .el-menu .nest-menu .menu-wrapper {
  background-color: #FFFFFF;
}
.lightWhite #app .green .avue-sidebar .el-menu .el-submenu__title i {
  color: #333333;
}
.lightWhite #app .green .avue-sidebar .el-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite #app .green .avue-sidebar .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite #app .green .avue-sidebar .el-menu .el-submenu__title:hover i {
  color: #2f6741;
}
.lightWhite #app .green .avue-sidebar .el-menu .el-submenu__title:hover span {
  color: #2f6741;
}
.lightWhite #app .green .avue-sidebar .el-menu .el-menu-item {
  background-color: #FFFFFF;
}
.lightWhite #app .green .avue-sidebar .el-menu .el-menu-item i {
  color: #333333;
}
.lightWhite #app .green .avue-sidebar .el-menu .el-menu-item span {
  color: #333333;
}
.lightWhite #app .green .avue-sidebar .el-menu .el-menu-item.is-active {
  background-color: #2f6741 !important;
  color: #FFFFFF;
}
.lightWhite #app .green .avue-sidebar .el-menu .el-menu-item.is-active i {
  color: #FFFFFF;
}
.lightWhite #app .green .avue-sidebar .el-menu .el-menu-item.is-active span {
  color: #FFFFFF;
}
.lightWhite #app .green .avue-sidebar .el-menu .el-menu-item.is-active:hover span {
  color: #FFFFFF;
}
.lightWhite #app .green .avue-sidebar .el-menu .el-menu-item.is-active:hover i {
  color: #FFFFFF;
}
.lightWhite #app .green .avue-sidebar .el-menu .el-menu-item:hover i {
  color: #2f6741;
}
.lightWhite #app .green .avue-sidebar .el-menu .el-menu-item:hover span {
  color: #2f6741;
}
.lightWhite #app .green .el-menu--vertical {
  background-color: #FFFFFF;
}
.lightWhite #app .green .el-menu--vertical .el-menu--popup {
  max-height: 600px !important;
  overflow: auto !important;
}
.lightWhite #app .green .el-menu--vertical .el-menu {
  background-color: #FFFFFF;
}
.lightWhite #app .green .el-menu--vertical .el-menu .el-menu-item {
  background-color: #FFFFFF;
}
.lightWhite #app .green .el-menu--vertical .el-menu .el-menu-item i {
  color: #333333;
}
.lightWhite #app .green .el-menu--vertical .el-menu .el-menu-item span {
  color: #333333;
}
.lightWhite #app .green .el-menu--vertical .el-menu .el-submenu__title i {
  color: #333333;
}
.lightWhite #app .green .el-menu--vertical .el-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite #app .green .el-menu--vertical .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite #app .green .el-menu--vertical .el-menu .el-submenu__title:hover span {
  color: #2f6741;
}
.lightWhite #app .green .el-menu--vertical .el-menu .el-submenu__title:hover i {
  color: #2f6741;
}
.lightWhite #app .green .el-menu--vertical .el-menu .el-menu-item:hover span {
  color: #2f6741;
}
.lightWhite #app .green .el-menu--vertical .el-menu .el-menu-item:hover i {
  color: #2f6741;
}
.lightWhite #app .green .el-menu--vertical .el-menu .el-menu-item.is-active {
  background-color: #2f6741;
  color: #333333;
}
.lightWhite #app .green .el-menu--vertical .el-menu .el-menu-item.is-active:hover span {
  color: #333333;
}
.lightWhite #app .green .el-menu--vertical .el-menu .el-menu-item.is-active:hover i {
  color: #333333;
}
.lightWhite #app .green .avue-top {
  color: #FFFFFF;
  background-color: #2f6741 !important;
}
.lightWhite #app .green .avue-top .avue-top-sidebar .el-menu {
  background-color: #2f6741;
}
.lightWhite #app .green .avue-top .avue-top-sidebar .el-menu .el-submenu__title {
  color: #FFFFFF;
}
.lightWhite #app .green .avue-top .avue-top-sidebar .el-menu .el-submenu__title i {
  color: #FFFFFF;
  margin-right: 10px;
}
.lightWhite #app .green .avue-top .avue-top-sidebar .el-menu .el-submenu__title i:last-child {
  display: none;
}
.lightWhite #app .green .avue-top .avue-top-sidebar .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite #app .green .avue-top .avue-top-sidebar .el-menu .el-submenu__title:hover span {
  color: #FFFFFF;
}
.lightWhite #app .green .avue-top .avue-top-sidebar .el-menu .el-submenu__title:hover i {
  color: #FFFFFF;
}
.lightWhite #app .green .avue-top .avue-top-sidebar .el-menu .headMenuItem {
  max-width: 160px;
}
.lightWhite #app .green .top-menu .el-menu-item i {
  color: #FFFFFF !important;
}
.lightWhite #app .green .top-menu .el-menu-item span {
  color: #FFFFFF;
}
.lightWhite #app .green .blend .el-menu .menu-wrapper .elMenuItem {
  background-color: #FFFFFF;
}
.lightWhite #app .green .plain .avue-layout {
  padding-left: 80px;
}
.lightWhite #app .green .plain .avue-left {
  width: 80px;
}
.lightWhite #app .green .plain .el-menu {
  background-color: #FFFFFF !important;
}
.lightWhite #app .green .plain .el-menu .nest-menu .el-submenu__title {
  height: 70px;
}
.lightWhite #app .green .plain .el-menu .nest-menu .el-submenu__title i {
  font-size: 28px;
}

.lightWhite > .green .headMenuItem {
  max-width: 150px;
}
.lightWhite > .green .el-menu--popup {
  max-height: 600px;
  overflow: auto;
}
.lightWhite > .green .el-menu {
  background-color: transparent;
}
.lightWhite > .green .el-menu .menu-wrapper {
  background-color: #FFFFFF;
}
.lightWhite > .green .el-menu .menu-wrapper .nest-menu {
  width: 100%;
}
.lightWhite > .green .el-menu .menu-wrapper .nest-menu .el-submenu__title {
  background-color: #FFFFFF;
  height: 46px;
  line-height: 46px;
}
.lightWhite > .green .el-menu .menu-wrapper .nest-menu .el-submenu__title i {
  color: #333333;
  margin-left: 15px;
  margin-right: 6px;
  font-size: 20px;
}
.lightWhite > .green .el-menu .menu-wrapper .nest-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite > .green .el-menu .el-menu-item {
  background-color: #FFFFFF;
  height: 46px;
  line-height: 46px;
}
.lightWhite > .green .el-menu .el-menu-item i {
  color: #333333;
  margin-left: 15px;
  margin-right: 6px;
  font-size: 20px;
}
.lightWhite > .green .el-menu .el-menu-item span {
  color: #333333;
}
.lightWhite > .green .el-menu .el-submenu__title i {
  color: #333333;
}
.lightWhite > .green .el-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite > .green .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite > .green .el-menu .el-submenu__title:hover span {
  color: #2f6741;
}
.lightWhite > .green .el-menu .el-submenu__title:hover i {
  color: #2f6741;
}
.lightWhite > .green .el-menu .el-menu-item:hover span {
  color: #2f6741;
}
.lightWhite > .green .el-menu .el-menu-item:hover i {
  color: #2f6741;
}
.lightWhite > .green .el-menu .el-menu-item.is-active {
  background-color: #2f6741;
  color: #FFFFFF;
}
.lightWhite > .green .el-menu .el-menu-item.is-active i {
  color: #FFFFFF;
}
.lightWhite > .green .el-menu .el-menu-item.is-active span {
  color: #FFFFFF;
}
.lightWhite > .green .el-menu .el-menu-item.is-active:hover span {
  color: #FFFFFF;
}
.lightWhite > .green .el-menu .el-menu-item.is-active:hover i {
  color: #FFFFFF;
}
.lightWhite > .green .el-menu .el-menu-item:hover i {
  color: #2f6741;
}
.lightWhite > .green .el-menu .el-menu-item:hover span {
  color: #2f6741;
}

.lightWhite #app .yellow .logo-content {
  background-color: #F8BC18;
}
.lightWhite #app .yellow .avue-sidebar {
  background-color: #FFFFFF;
}
.lightWhite #app .yellow .avue-sidebar .el-menu {
  background-color: #FFFFFF;
}
.lightWhite #app .yellow .avue-sidebar .el-menu .nest-menu .menu-wrapper {
  background-color: #FFFFFF;
}
.lightWhite #app .yellow .avue-sidebar .el-menu .el-submenu__title i {
  color: #333333;
}
.lightWhite #app .yellow .avue-sidebar .el-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite #app .yellow .avue-sidebar .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite #app .yellow .avue-sidebar .el-menu .el-submenu__title:hover i {
  color: #F8BC18;
}
.lightWhite #app .yellow .avue-sidebar .el-menu .el-submenu__title:hover span {
  color: #F8BC18;
}
.lightWhite #app .yellow .avue-sidebar .el-menu .el-menu-item {
  background-color: #FFFFFF;
}
.lightWhite #app .yellow .avue-sidebar .el-menu .el-menu-item i {
  color: #333333;
}
.lightWhite #app .yellow .avue-sidebar .el-menu .el-menu-item span {
  color: #333333;
}
.lightWhite #app .yellow .avue-sidebar .el-menu .el-menu-item.is-active {
  background-color: #F8BC18 !important;
  color: #FFFFFF;
}
.lightWhite #app .yellow .avue-sidebar .el-menu .el-menu-item.is-active i {
  color: #FFFFFF;
}
.lightWhite #app .yellow .avue-sidebar .el-menu .el-menu-item.is-active span {
  color: #FFFFFF;
}
.lightWhite #app .yellow .avue-sidebar .el-menu .el-menu-item.is-active:hover span {
  color: #FFFFFF;
}
.lightWhite #app .yellow .avue-sidebar .el-menu .el-menu-item.is-active:hover i {
  color: #FFFFFF;
}
.lightWhite #app .yellow .avue-sidebar .el-menu .el-menu-item:hover i {
  color: #F8BC18;
}
.lightWhite #app .yellow .avue-sidebar .el-menu .el-menu-item:hover span {
  color: #F8BC18;
}
.lightWhite #app .yellow .el-menu--vertical {
  background-color: #FFFFFF;
}
.lightWhite #app .yellow .el-menu--vertical .el-menu--popup {
  max-height: 600px !important;
  overflow: auto !important;
}
.lightWhite #app .yellow .el-menu--vertical .el-menu {
  background-color: #FFFFFF;
}
.lightWhite #app .yellow .el-menu--vertical .el-menu .el-menu-item {
  background-color: #FFFFFF;
}
.lightWhite #app .yellow .el-menu--vertical .el-menu .el-menu-item i {
  color: #333333;
}
.lightWhite #app .yellow .el-menu--vertical .el-menu .el-menu-item span {
  color: #333333;
}
.lightWhite #app .yellow .el-menu--vertical .el-menu .el-submenu__title i {
  color: #333333;
}
.lightWhite #app .yellow .el-menu--vertical .el-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite #app .yellow .el-menu--vertical .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite #app .yellow .el-menu--vertical .el-menu .el-submenu__title:hover span {
  color: #F8BC18;
}
.lightWhite #app .yellow .el-menu--vertical .el-menu .el-submenu__title:hover i {
  color: #F8BC18;
}
.lightWhite #app .yellow .el-menu--vertical .el-menu .el-menu-item:hover span {
  color: #F8BC18;
}
.lightWhite #app .yellow .el-menu--vertical .el-menu .el-menu-item:hover i {
  color: #F8BC18;
}
.lightWhite #app .yellow .el-menu--vertical .el-menu .el-menu-item.is-active {
  background-color: #F8BC18;
  color: #333333;
}
.lightWhite #app .yellow .el-menu--vertical .el-menu .el-menu-item.is-active:hover span {
  color: #333333;
}
.lightWhite #app .yellow .el-menu--vertical .el-menu .el-menu-item.is-active:hover i {
  color: #333333;
}
.lightWhite #app .yellow .avue-top {
  color: #FFFFFF;
  background-color: #F8BC18 !important;
}
.lightWhite #app .yellow .avue-top .avue-top-sidebar .el-menu {
  background-color: #F8BC18;
}
.lightWhite #app .yellow .avue-top .avue-top-sidebar .el-menu .el-submenu__title {
  color: #FFFFFF;
}
.lightWhite #app .yellow .avue-top .avue-top-sidebar .el-menu .el-submenu__title i {
  color: #FFFFFF;
  margin-right: 10px;
}
.lightWhite #app .yellow .avue-top .avue-top-sidebar .el-menu .el-submenu__title i:last-child {
  display: none;
}
.lightWhite #app .yellow .avue-top .avue-top-sidebar .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite #app .yellow .avue-top .avue-top-sidebar .el-menu .el-submenu__title:hover span {
  color: #FFFFFF;
}
.lightWhite #app .yellow .avue-top .avue-top-sidebar .el-menu .el-submenu__title:hover i {
  color: #FFFFFF;
}
.lightWhite #app .yellow .avue-top .avue-top-sidebar .el-menu .headMenuItem {
  max-width: 160px;
}
.lightWhite #app .yellow .top-menu .el-menu-item i {
  color: #FFFFFF !important;
}
.lightWhite #app .yellow .top-menu .el-menu-item span {
  color: #FFFFFF;
}
.lightWhite #app .yellow .blend .el-menu .menu-wrapper .elMenuItem {
  background-color: #FFFFFF;
}
.lightWhite #app .yellow .plain .avue-layout {
  padding-left: 80px;
}
.lightWhite #app .yellow .plain .avue-left {
  width: 80px;
}
.lightWhite #app .yellow .plain .el-menu {
  background-color: #FFFFFF !important;
}
.lightWhite #app .yellow .plain .el-menu .nest-menu .el-submenu__title {
  height: 70px;
}
.lightWhite #app .yellow .plain .el-menu .nest-menu .el-submenu__title i {
  font-size: 28px;
}

.lightWhite > .yellow .headMenuItem {
  max-width: 150px;
}
.lightWhite > .yellow .el-menu--popup {
  max-height: 600px;
  overflow: auto;
}
.lightWhite > .yellow .el-menu {
  background-color: transparent;
}
.lightWhite > .yellow .el-menu .menu-wrapper {
  background-color: #FFFFFF;
}
.lightWhite > .yellow .el-menu .menu-wrapper .nest-menu {
  width: 100%;
}
.lightWhite > .yellow .el-menu .menu-wrapper .nest-menu .el-submenu__title {
  background-color: #FFFFFF;
  height: 46px;
  line-height: 46px;
}
.lightWhite > .yellow .el-menu .menu-wrapper .nest-menu .el-submenu__title i {
  color: #333333;
  margin-left: 15px;
  margin-right: 6px;
  font-size: 20px;
}
.lightWhite > .yellow .el-menu .menu-wrapper .nest-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite > .yellow .el-menu .el-menu-item {
  background-color: #FFFFFF;
  height: 46px;
  line-height: 46px;
}
.lightWhite > .yellow .el-menu .el-menu-item i {
  color: #333333;
  margin-left: 15px;
  margin-right: 6px;
  font-size: 20px;
}
.lightWhite > .yellow .el-menu .el-menu-item span {
  color: #333333;
}
.lightWhite > .yellow .el-menu .el-submenu__title i {
  color: #333333;
}
.lightWhite > .yellow .el-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite > .yellow .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite > .yellow .el-menu .el-submenu__title:hover span {
  color: #F8BC18;
}
.lightWhite > .yellow .el-menu .el-submenu__title:hover i {
  color: #F8BC18;
}
.lightWhite > .yellow .el-menu .el-menu-item:hover span {
  color: #F8BC18;
}
.lightWhite > .yellow .el-menu .el-menu-item:hover i {
  color: #F8BC18;
}
.lightWhite > .yellow .el-menu .el-menu-item.is-active {
  background-color: #F8BC18;
  color: #FFFFFF;
}
.lightWhite > .yellow .el-menu .el-menu-item.is-active i {
  color: #FFFFFF;
}
.lightWhite > .yellow .el-menu .el-menu-item.is-active span {
  color: #FFFFFF;
}
.lightWhite > .yellow .el-menu .el-menu-item.is-active:hover span {
  color: #FFFFFF;
}
.lightWhite > .yellow .el-menu .el-menu-item.is-active:hover i {
  color: #FFFFFF;
}
.lightWhite > .yellow .el-menu .el-menu-item:hover i {
  color: #F8BC18;
}
.lightWhite > .yellow .el-menu .el-menu-item:hover span {
  color: #F8BC18;
}

.lightWhite #app .orange .logo-content {
  background-color: #F5811C;
}
.lightWhite #app .orange .avue-sidebar {
  background-color: #FFFFFF;
}
.lightWhite #app .orange .avue-sidebar .el-menu {
  background-color: #FFFFFF;
}
.lightWhite #app .orange .avue-sidebar .el-menu .nest-menu .menu-wrapper {
  background-color: #FFFFFF;
}
.lightWhite #app .orange .avue-sidebar .el-menu .el-submenu__title i {
  color: #333333;
}
.lightWhite #app .orange .avue-sidebar .el-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite #app .orange .avue-sidebar .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite #app .orange .avue-sidebar .el-menu .el-submenu__title:hover i {
  color: #F5811C;
}
.lightWhite #app .orange .avue-sidebar .el-menu .el-submenu__title:hover span {
  color: #F5811C;
}
.lightWhite #app .orange .avue-sidebar .el-menu .el-menu-item {
  background-color: #FFFFFF;
}
.lightWhite #app .orange .avue-sidebar .el-menu .el-menu-item i {
  color: #333333;
}
.lightWhite #app .orange .avue-sidebar .el-menu .el-menu-item span {
  color: #333333;
}
.lightWhite #app .orange .avue-sidebar .el-menu .el-menu-item.is-active {
  background-color: #F5811C !important;
  color: #FFFFFF;
}
.lightWhite #app .orange .avue-sidebar .el-menu .el-menu-item.is-active i {
  color: #FFFFFF;
}
.lightWhite #app .orange .avue-sidebar .el-menu .el-menu-item.is-active span {
  color: #FFFFFF;
}
.lightWhite #app .orange .avue-sidebar .el-menu .el-menu-item.is-active:hover span {
  color: #FFFFFF;
}
.lightWhite #app .orange .avue-sidebar .el-menu .el-menu-item.is-active:hover i {
  color: #FFFFFF;
}
.lightWhite #app .orange .avue-sidebar .el-menu .el-menu-item:hover i {
  color: #F5811C;
}
.lightWhite #app .orange .avue-sidebar .el-menu .el-menu-item:hover span {
  color: #F5811C;
}
.lightWhite #app .orange .el-menu--vertical {
  background-color: #FFFFFF;
}
.lightWhite #app .orange .el-menu--vertical .el-menu--popup {
  max-height: 600px !important;
  overflow: auto !important;
}
.lightWhite #app .orange .el-menu--vertical .el-menu {
  background-color: #FFFFFF;
}
.lightWhite #app .orange .el-menu--vertical .el-menu .el-menu-item {
  background-color: #FFFFFF;
}
.lightWhite #app .orange .el-menu--vertical .el-menu .el-menu-item i {
  color: #333333;
}
.lightWhite #app .orange .el-menu--vertical .el-menu .el-menu-item span {
  color: #333333;
}
.lightWhite #app .orange .el-menu--vertical .el-menu .el-submenu__title i {
  color: #333333;
}
.lightWhite #app .orange .el-menu--vertical .el-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite #app .orange .el-menu--vertical .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite #app .orange .el-menu--vertical .el-menu .el-submenu__title:hover span {
  color: #F5811C;
}
.lightWhite #app .orange .el-menu--vertical .el-menu .el-submenu__title:hover i {
  color: #F5811C;
}
.lightWhite #app .orange .el-menu--vertical .el-menu .el-menu-item:hover span {
  color: #F5811C;
}
.lightWhite #app .orange .el-menu--vertical .el-menu .el-menu-item:hover i {
  color: #F5811C;
}
.lightWhite #app .orange .el-menu--vertical .el-menu .el-menu-item.is-active {
  background-color: #F5811C;
  color: #333333;
}
.lightWhite #app .orange .el-menu--vertical .el-menu .el-menu-item.is-active:hover span {
  color: #333333;
}
.lightWhite #app .orange .el-menu--vertical .el-menu .el-menu-item.is-active:hover i {
  color: #333333;
}
.lightWhite #app .orange .avue-top {
  color: #FFFFFF;
  background-color: #F5811C !important;
}
.lightWhite #app .orange .avue-top .avue-top-sidebar .el-menu {
  background-color: #F5811C;
}
.lightWhite #app .orange .avue-top .avue-top-sidebar .el-menu .el-submenu__title {
  color: #FFFFFF;
}
.lightWhite #app .orange .avue-top .avue-top-sidebar .el-menu .el-submenu__title i {
  color: #FFFFFF;
  margin-right: 10px;
}
.lightWhite #app .orange .avue-top .avue-top-sidebar .el-menu .el-submenu__title i:last-child {
  display: none;
}
.lightWhite #app .orange .avue-top .avue-top-sidebar .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite #app .orange .avue-top .avue-top-sidebar .el-menu .el-submenu__title:hover span {
  color: #FFFFFF;
}
.lightWhite #app .orange .avue-top .avue-top-sidebar .el-menu .el-submenu__title:hover i {
  color: #FFFFFF;
}
.lightWhite #app .orange .avue-top .avue-top-sidebar .el-menu .headMenuItem {
  max-width: 160px;
}
.lightWhite #app .orange .top-menu .el-menu-item i {
  color: #FFFFFF !important;
}
.lightWhite #app .orange .top-menu .el-menu-item span {
  color: #FFFFFF;
}
.lightWhite #app .orange .blend .el-menu .menu-wrapper .elMenuItem {
  background-color: #FFFFFF;
}
.lightWhite #app .orange .plain .avue-layout {
  padding-left: 80px;
}
.lightWhite #app .orange .plain .avue-left {
  width: 80px;
}
.lightWhite #app .orange .plain .el-menu {
  background-color: #FFFFFF !important;
}
.lightWhite #app .orange .plain .el-menu .nest-menu .el-submenu__title {
  height: 70px;
}
.lightWhite #app .orange .plain .el-menu .nest-menu .el-submenu__title i {
  font-size: 28px;
}

.lightWhite > .orange .headMenuItem {
  max-width: 150px;
}
.lightWhite > .orange .el-menu--popup {
  max-height: 600px;
  overflow: auto;
}
.lightWhite > .orange .el-menu {
  background-color: transparent;
}
.lightWhite > .orange .el-menu .menu-wrapper {
  background-color: #FFFFFF;
}
.lightWhite > .orange .el-menu .menu-wrapper .nest-menu {
  width: 100%;
}
.lightWhite > .orange .el-menu .menu-wrapper .nest-menu .el-submenu__title {
  background-color: #FFFFFF;
  height: 46px;
  line-height: 46px;
}
.lightWhite > .orange .el-menu .menu-wrapper .nest-menu .el-submenu__title i {
  color: #333333;
  margin-left: 15px;
  margin-right: 6px;
  font-size: 20px;
}
.lightWhite > .orange .el-menu .menu-wrapper .nest-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite > .orange .el-menu .el-menu-item {
  background-color: #FFFFFF;
  height: 46px;
  line-height: 46px;
}
.lightWhite > .orange .el-menu .el-menu-item i {
  color: #333333;
  margin-left: 15px;
  margin-right: 6px;
  font-size: 20px;
}
.lightWhite > .orange .el-menu .el-menu-item span {
  color: #333333;
}
.lightWhite > .orange .el-menu .el-submenu__title i {
  color: #333333;
}
.lightWhite > .orange .el-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite > .orange .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite > .orange .el-menu .el-submenu__title:hover span {
  color: #F5811C;
}
.lightWhite > .orange .el-menu .el-submenu__title:hover i {
  color: #F5811C;
}
.lightWhite > .orange .el-menu .el-menu-item:hover span {
  color: #F5811C;
}
.lightWhite > .orange .el-menu .el-menu-item:hover i {
  color: #F5811C;
}
.lightWhite > .orange .el-menu .el-menu-item.is-active {
  background-color: #F5811C;
  color: #FFFFFF;
}
.lightWhite > .orange .el-menu .el-menu-item.is-active i {
  color: #FFFFFF;
}
.lightWhite > .orange .el-menu .el-menu-item.is-active span {
  color: #FFFFFF;
}
.lightWhite > .orange .el-menu .el-menu-item.is-active:hover span {
  color: #FFFFFF;
}
.lightWhite > .orange .el-menu .el-menu-item.is-active:hover i {
  color: #FFFFFF;
}
.lightWhite > .orange .el-menu .el-menu-item:hover i {
  color: #F5811C;
}
.lightWhite > .orange .el-menu .el-menu-item:hover span {
  color: #F5811C;
}

.lightWhite #app .red .logo-content {
  background-color: #C5211B;
}
.lightWhite #app .red .avue-sidebar {
  background-color: #FFFFFF;
}
.lightWhite #app .red .avue-sidebar .el-menu {
  background-color: #FFFFFF;
}
.lightWhite #app .red .avue-sidebar .el-menu .nest-menu .menu-wrapper {
  background-color: #FFFFFF;
}
.lightWhite #app .red .avue-sidebar .el-menu .el-submenu__title i {
  color: #333333;
}
.lightWhite #app .red .avue-sidebar .el-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite #app .red .avue-sidebar .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite #app .red .avue-sidebar .el-menu .el-submenu__title:hover i {
  color: #C5211B;
}
.lightWhite #app .red .avue-sidebar .el-menu .el-submenu__title:hover span {
  color: #C5211B;
}
.lightWhite #app .red .avue-sidebar .el-menu .el-menu-item {
  background-color: #FFFFFF;
}
.lightWhite #app .red .avue-sidebar .el-menu .el-menu-item i {
  color: #333333;
}
.lightWhite #app .red .avue-sidebar .el-menu .el-menu-item span {
  color: #333333;
}
.lightWhite #app .red .avue-sidebar .el-menu .el-menu-item.is-active {
  background-color: #C5211B !important;
  color: #FFFFFF;
}
.lightWhite #app .red .avue-sidebar .el-menu .el-menu-item.is-active i {
  color: #FFFFFF;
}
.lightWhite #app .red .avue-sidebar .el-menu .el-menu-item.is-active span {
  color: #FFFFFF;
}
.lightWhite #app .red .avue-sidebar .el-menu .el-menu-item.is-active:hover span {
  color: #FFFFFF;
}
.lightWhite #app .red .avue-sidebar .el-menu .el-menu-item.is-active:hover i {
  color: #FFFFFF;
}
.lightWhite #app .red .avue-sidebar .el-menu .el-menu-item:hover i {
  color: #C5211B;
}
.lightWhite #app .red .avue-sidebar .el-menu .el-menu-item:hover span {
  color: #C5211B;
}
.lightWhite #app .red .el-menu--vertical {
  background-color: #FFFFFF;
}
.lightWhite #app .red .el-menu--vertical .el-menu--popup {
  max-height: 600px !important;
  overflow: auto !important;
}
.lightWhite #app .red .el-menu--vertical .el-menu {
  background-color: #FFFFFF;
}
.lightWhite #app .red .el-menu--vertical .el-menu .el-menu-item {
  background-color: #FFFFFF;
}
.lightWhite #app .red .el-menu--vertical .el-menu .el-menu-item i {
  color: #333333;
}
.lightWhite #app .red .el-menu--vertical .el-menu .el-menu-item span {
  color: #333333;
}
.lightWhite #app .red .el-menu--vertical .el-menu .el-submenu__title i {
  color: #333333;
}
.lightWhite #app .red .el-menu--vertical .el-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite #app .red .el-menu--vertical .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite #app .red .el-menu--vertical .el-menu .el-submenu__title:hover span {
  color: #C5211B;
}
.lightWhite #app .red .el-menu--vertical .el-menu .el-submenu__title:hover i {
  color: #C5211B;
}
.lightWhite #app .red .el-menu--vertical .el-menu .el-menu-item:hover span {
  color: #C5211B;
}
.lightWhite #app .red .el-menu--vertical .el-menu .el-menu-item:hover i {
  color: #C5211B;
}
.lightWhite #app .red .el-menu--vertical .el-menu .el-menu-item.is-active {
  background-color: #C5211B;
  color: #333333;
}
.lightWhite #app .red .el-menu--vertical .el-menu .el-menu-item.is-active:hover span {
  color: #333333;
}
.lightWhite #app .red .el-menu--vertical .el-menu .el-menu-item.is-active:hover i {
  color: #333333;
}
.lightWhite #app .red .avue-top {
  color: #FFFFFF;
  background-color: #C5211B !important;
}
.lightWhite #app .red .avue-top .avue-top-sidebar .el-menu {
  background-color: #C5211B;
}
.lightWhite #app .red .avue-top .avue-top-sidebar .el-menu .el-submenu__title {
  color: #FFFFFF;
}
.lightWhite #app .red .avue-top .avue-top-sidebar .el-menu .el-submenu__title i {
  color: #FFFFFF;
  margin-right: 10px;
}
.lightWhite #app .red .avue-top .avue-top-sidebar .el-menu .el-submenu__title i:last-child {
  display: none;
}
.lightWhite #app .red .avue-top .avue-top-sidebar .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite #app .red .avue-top .avue-top-sidebar .el-menu .el-submenu__title:hover span {
  color: #FFFFFF;
}
.lightWhite #app .red .avue-top .avue-top-sidebar .el-menu .el-submenu__title:hover i {
  color: #FFFFFF;
}
.lightWhite #app .red .avue-top .avue-top-sidebar .el-menu .headMenuItem {
  max-width: 160px;
}
.lightWhite #app .red .top-menu .el-menu-item i {
  color: #FFFFFF !important;
}
.lightWhite #app .red .top-menu .el-menu-item span {
  color: #FFFFFF;
}
.lightWhite #app .red .blend .el-menu .menu-wrapper .elMenuItem {
  background-color: #FFFFFF;
}
.lightWhite #app .red .plain .avue-layout {
  padding-left: 80px;
}
.lightWhite #app .red .plain .avue-left {
  width: 80px;
}
.lightWhite #app .red .plain .el-menu {
  background-color: #FFFFFF !important;
}
.lightWhite #app .red .plain .el-menu .nest-menu .el-submenu__title {
  height: 70px;
}
.lightWhite #app .red .plain .el-menu .nest-menu .el-submenu__title i {
  font-size: 28px;
}

.lightWhite > .red .headMenuItem {
  max-width: 150px;
}
.lightWhite > .red .el-menu--popup {
  max-height: 600px;
  overflow: auto;
}
.lightWhite > .red .el-menu {
  background-color: transparent;
}
.lightWhite > .red .el-menu .menu-wrapper {
  background-color: #FFFFFF;
}
.lightWhite > .red .el-menu .menu-wrapper .nest-menu {
  width: 100%;
}
.lightWhite > .red .el-menu .menu-wrapper .nest-menu .el-submenu__title {
  background-color: #FFFFFF;
  height: 46px;
  line-height: 46px;
}
.lightWhite > .red .el-menu .menu-wrapper .nest-menu .el-submenu__title i {
  color: #333333;
  margin-left: 15px;
  margin-right: 6px;
  font-size: 20px;
}
.lightWhite > .red .el-menu .menu-wrapper .nest-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite > .red .el-menu .el-menu-item {
  background-color: #FFFFFF;
  height: 46px;
  line-height: 46px;
}
.lightWhite > .red .el-menu .el-menu-item i {
  color: #333333;
  margin-left: 15px;
  margin-right: 6px;
  font-size: 20px;
}
.lightWhite > .red .el-menu .el-menu-item span {
  color: #333333;
}
.lightWhite > .red .el-menu .el-submenu__title i {
  color: #333333;
}
.lightWhite > .red .el-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite > .red .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite > .red .el-menu .el-submenu__title:hover span {
  color: #C5211B;
}
.lightWhite > .red .el-menu .el-submenu__title:hover i {
  color: #C5211B;
}
.lightWhite > .red .el-menu .el-menu-item:hover span {
  color: #C5211B;
}
.lightWhite > .red .el-menu .el-menu-item:hover i {
  color: #C5211B;
}
.lightWhite > .red .el-menu .el-menu-item.is-active {
  background-color: #C5211B;
  color: #FFFFFF;
}
.lightWhite > .red .el-menu .el-menu-item.is-active i {
  color: #FFFFFF;
}
.lightWhite > .red .el-menu .el-menu-item.is-active span {
  color: #FFFFFF;
}
.lightWhite > .red .el-menu .el-menu-item.is-active:hover span {
  color: #FFFFFF;
}
.lightWhite > .red .el-menu .el-menu-item.is-active:hover i {
  color: #FFFFFF;
}
.lightWhite > .red .el-menu .el-menu-item:hover i {
  color: #C5211B;
}
.lightWhite > .red .el-menu .el-menu-item:hover span {
  color: #C5211B;
}

.lightWhite #app .grey .logo-content {
  background-color: #435967;
}
.lightWhite #app .grey .avue-sidebar {
  background-color: #FFFFFF;
}
.lightWhite #app .grey .avue-sidebar .el-menu {
  background-color: #FFFFFF;
}
.lightWhite #app .grey .avue-sidebar .el-menu .nest-menu .menu-wrapper {
  background-color: #FFFFFF;
}
.lightWhite #app .grey .avue-sidebar .el-menu .el-submenu__title i {
  color: #333333;
}
.lightWhite #app .grey .avue-sidebar .el-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite #app .grey .avue-sidebar .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite #app .grey .avue-sidebar .el-menu .el-submenu__title:hover i {
  color: #435967;
}
.lightWhite #app .grey .avue-sidebar .el-menu .el-submenu__title:hover span {
  color: #435967;
}
.lightWhite #app .grey .avue-sidebar .el-menu .el-menu-item {
  background-color: #FFFFFF;
}
.lightWhite #app .grey .avue-sidebar .el-menu .el-menu-item i {
  color: #333333;
}
.lightWhite #app .grey .avue-sidebar .el-menu .el-menu-item span {
  color: #333333;
}
.lightWhite #app .grey .avue-sidebar .el-menu .el-menu-item.is-active {
  background-color: #435967 !important;
  color: #FFFFFF;
}
.lightWhite #app .grey .avue-sidebar .el-menu .el-menu-item.is-active i {
  color: #FFFFFF;
}
.lightWhite #app .grey .avue-sidebar .el-menu .el-menu-item.is-active span {
  color: #FFFFFF;
}
.lightWhite #app .grey .avue-sidebar .el-menu .el-menu-item.is-active:hover span {
  color: #FFFFFF;
}
.lightWhite #app .grey .avue-sidebar .el-menu .el-menu-item.is-active:hover i {
  color: #FFFFFF;
}
.lightWhite #app .grey .avue-sidebar .el-menu .el-menu-item:hover i {
  color: #435967;
}
.lightWhite #app .grey .avue-sidebar .el-menu .el-menu-item:hover span {
  color: #435967;
}
.lightWhite #app .grey .el-menu--vertical {
  background-color: #FFFFFF;
}
.lightWhite #app .grey .el-menu--vertical .el-menu--popup {
  max-height: 600px !important;
  overflow: auto !important;
}
.lightWhite #app .grey .el-menu--vertical .el-menu {
  background-color: #FFFFFF;
}
.lightWhite #app .grey .el-menu--vertical .el-menu .el-menu-item {
  background-color: #FFFFFF;
}
.lightWhite #app .grey .el-menu--vertical .el-menu .el-menu-item i {
  color: #333333;
}
.lightWhite #app .grey .el-menu--vertical .el-menu .el-menu-item span {
  color: #333333;
}
.lightWhite #app .grey .el-menu--vertical .el-menu .el-submenu__title i {
  color: #333333;
}
.lightWhite #app .grey .el-menu--vertical .el-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite #app .grey .el-menu--vertical .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite #app .grey .el-menu--vertical .el-menu .el-submenu__title:hover span {
  color: #435967;
}
.lightWhite #app .grey .el-menu--vertical .el-menu .el-submenu__title:hover i {
  color: #435967;
}
.lightWhite #app .grey .el-menu--vertical .el-menu .el-menu-item:hover span {
  color: #435967;
}
.lightWhite #app .grey .el-menu--vertical .el-menu .el-menu-item:hover i {
  color: #435967;
}
.lightWhite #app .grey .el-menu--vertical .el-menu .el-menu-item.is-active {
  background-color: #435967;
  color: #333333;
}
.lightWhite #app .grey .el-menu--vertical .el-menu .el-menu-item.is-active:hover span {
  color: #333333;
}
.lightWhite #app .grey .el-menu--vertical .el-menu .el-menu-item.is-active:hover i {
  color: #333333;
}
.lightWhite #app .grey .avue-top {
  color: #FFFFFF;
  background-color: #435967 !important;
}
.lightWhite #app .grey .avue-top .avue-top-sidebar .el-menu {
  background-color: #435967;
}
.lightWhite #app .grey .avue-top .avue-top-sidebar .el-menu .el-submenu__title {
  color: #FFFFFF;
}
.lightWhite #app .grey .avue-top .avue-top-sidebar .el-menu .el-submenu__title i {
  color: #FFFFFF;
  margin-right: 10px;
}
.lightWhite #app .grey .avue-top .avue-top-sidebar .el-menu .el-submenu__title i:last-child {
  display: none;
}
.lightWhite #app .grey .avue-top .avue-top-sidebar .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite #app .grey .avue-top .avue-top-sidebar .el-menu .el-submenu__title:hover span {
  color: #FFFFFF;
}
.lightWhite #app .grey .avue-top .avue-top-sidebar .el-menu .el-submenu__title:hover i {
  color: #FFFFFF;
}
.lightWhite #app .grey .avue-top .avue-top-sidebar .el-menu .headMenuItem {
  max-width: 160px;
}
.lightWhite #app .grey .top-menu .el-menu-item i {
  color: #FFFFFF !important;
}
.lightWhite #app .grey .top-menu .el-menu-item span {
  color: #FFFFFF;
}
.lightWhite #app .grey .blend .el-menu .menu-wrapper .elMenuItem {
  background-color: #FFFFFF;
}
.lightWhite #app .grey .plain .avue-layout {
  padding-left: 80px;
}
.lightWhite #app .grey .plain .avue-left {
  width: 80px;
}
.lightWhite #app .grey .plain .el-menu {
  background-color: #FFFFFF !important;
}
.lightWhite #app .grey .plain .el-menu .nest-menu .el-submenu__title {
  height: 70px;
}
.lightWhite #app .grey .plain .el-menu .nest-menu .el-submenu__title i {
  font-size: 28px;
}

.lightWhite > .grey .headMenuItem {
  max-width: 150px;
}
.lightWhite > .grey .el-menu--popup {
  max-height: 600px;
  overflow: auto;
}
.lightWhite > .grey .el-menu {
  background-color: transparent;
}
.lightWhite > .grey .el-menu .menu-wrapper {
  background-color: #FFFFFF;
}
.lightWhite > .grey .el-menu .menu-wrapper .nest-menu {
  width: 100%;
}
.lightWhite > .grey .el-menu .menu-wrapper .nest-menu .el-submenu__title {
  background-color: #FFFFFF;
  height: 46px;
  line-height: 46px;
}
.lightWhite > .grey .el-menu .menu-wrapper .nest-menu .el-submenu__title i {
  color: #333333;
  margin-left: 15px;
  margin-right: 6px;
  font-size: 20px;
}
.lightWhite > .grey .el-menu .menu-wrapper .nest-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite > .grey .el-menu .el-menu-item {
  background-color: #FFFFFF;
  height: 46px;
  line-height: 46px;
}
.lightWhite > .grey .el-menu .el-menu-item i {
  color: #333333;
  margin-left: 15px;
  margin-right: 6px;
  font-size: 20px;
}
.lightWhite > .grey .el-menu .el-menu-item span {
  color: #333333;
}
.lightWhite > .grey .el-menu .el-submenu__title i {
  color: #333333;
}
.lightWhite > .grey .el-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite > .grey .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite > .grey .el-menu .el-submenu__title:hover span {
  color: #435967;
}
.lightWhite > .grey .el-menu .el-submenu__title:hover i {
  color: #435967;
}
.lightWhite > .grey .el-menu .el-menu-item:hover span {
  color: #435967;
}
.lightWhite > .grey .el-menu .el-menu-item:hover i {
  color: #435967;
}
.lightWhite > .grey .el-menu .el-menu-item.is-active {
  background-color: #435967;
  color: #FFFFFF;
}
.lightWhite > .grey .el-menu .el-menu-item.is-active i {
  color: #FFFFFF;
}
.lightWhite > .grey .el-menu .el-menu-item.is-active span {
  color: #FFFFFF;
}
.lightWhite > .grey .el-menu .el-menu-item.is-active:hover span {
  color: #FFFFFF;
}
.lightWhite > .grey .el-menu .el-menu-item.is-active:hover i {
  color: #FFFFFF;
}
.lightWhite > .grey .el-menu .el-menu-item:hover i {
  color: #435967;
}
.lightWhite > .grey .el-menu .el-menu-item:hover span {
  color: #435967;
}

.lightWhite #app .black .logo-content {
  background-color: #3C3E4D;
}
.lightWhite #app .black .avue-sidebar {
  background-color: #FFFFFF;
}
.lightWhite #app .black .avue-sidebar .el-menu {
  background-color: #FFFFFF;
}
.lightWhite #app .black .avue-sidebar .el-menu .nest-menu .menu-wrapper {
  background-color: #FFFFFF;
}
.lightWhite #app .black .avue-sidebar .el-menu .el-submenu__title i {
  color: #333333;
}
.lightWhite #app .black .avue-sidebar .el-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite #app .black .avue-sidebar .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite #app .black .avue-sidebar .el-menu .el-submenu__title:hover i {
  color: #3C3E4D;
}
.lightWhite #app .black .avue-sidebar .el-menu .el-submenu__title:hover span {
  color: #3C3E4D;
}
.lightWhite #app .black .avue-sidebar .el-menu .el-menu-item {
  background-color: #FFFFFF;
}
.lightWhite #app .black .avue-sidebar .el-menu .el-menu-item i {
  color: #333333;
}
.lightWhite #app .black .avue-sidebar .el-menu .el-menu-item span {
  color: #333333;
}
.lightWhite #app .black .avue-sidebar .el-menu .el-menu-item.is-active {
  background-color: #3C3E4D !important;
  color: #FFFFFF;
}
.lightWhite #app .black .avue-sidebar .el-menu .el-menu-item.is-active i {
  color: #FFFFFF;
}
.lightWhite #app .black .avue-sidebar .el-menu .el-menu-item.is-active span {
  color: #FFFFFF;
}
.lightWhite #app .black .avue-sidebar .el-menu .el-menu-item.is-active:hover span {
  color: #FFFFFF;
}
.lightWhite #app .black .avue-sidebar .el-menu .el-menu-item.is-active:hover i {
  color: #FFFFFF;
}
.lightWhite #app .black .avue-sidebar .el-menu .el-menu-item:hover i {
  color: #3C3E4D;
}
.lightWhite #app .black .avue-sidebar .el-menu .el-menu-item:hover span {
  color: #3C3E4D;
}
.lightWhite #app .black .el-menu--vertical {
  background-color: #FFFFFF;
}
.lightWhite #app .black .el-menu--vertical .el-menu--popup {
  max-height: 600px !important;
  overflow: auto !important;
}
.lightWhite #app .black .el-menu--vertical .el-menu {
  background-color: #FFFFFF;
}
.lightWhite #app .black .el-menu--vertical .el-menu .el-menu-item {
  background-color: #FFFFFF;
}
.lightWhite #app .black .el-menu--vertical .el-menu .el-menu-item i {
  color: #333333;
}
.lightWhite #app .black .el-menu--vertical .el-menu .el-menu-item span {
  color: #333333;
}
.lightWhite #app .black .el-menu--vertical .el-menu .el-submenu__title i {
  color: #333333;
}
.lightWhite #app .black .el-menu--vertical .el-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite #app .black .el-menu--vertical .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite #app .black .el-menu--vertical .el-menu .el-submenu__title:hover span {
  color: #3C3E4D;
}
.lightWhite #app .black .el-menu--vertical .el-menu .el-submenu__title:hover i {
  color: #3C3E4D;
}
.lightWhite #app .black .el-menu--vertical .el-menu .el-menu-item:hover span {
  color: #3C3E4D;
}
.lightWhite #app .black .el-menu--vertical .el-menu .el-menu-item:hover i {
  color: #3C3E4D;
}
.lightWhite #app .black .el-menu--vertical .el-menu .el-menu-item.is-active {
  background-color: #3C3E4D;
  color: #333333;
}
.lightWhite #app .black .el-menu--vertical .el-menu .el-menu-item.is-active:hover span {
  color: #333333;
}
.lightWhite #app .black .el-menu--vertical .el-menu .el-menu-item.is-active:hover i {
  color: #333333;
}
.lightWhite #app .black .avue-top {
  color: #FFFFFF;
  background-color: #3C3E4D !important;
}
.lightWhite #app .black .avue-top .avue-top-sidebar .el-menu {
  background-color: #3C3E4D;
}
.lightWhite #app .black .avue-top .avue-top-sidebar .el-menu .el-submenu__title {
  color: #FFFFFF;
}
.lightWhite #app .black .avue-top .avue-top-sidebar .el-menu .el-submenu__title i {
  color: #FFFFFF;
  margin-right: 10px;
}
.lightWhite #app .black .avue-top .avue-top-sidebar .el-menu .el-submenu__title i:last-child {
  display: none;
}
.lightWhite #app .black .avue-top .avue-top-sidebar .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite #app .black .avue-top .avue-top-sidebar .el-menu .el-submenu__title:hover span {
  color: #FFFFFF;
}
.lightWhite #app .black .avue-top .avue-top-sidebar .el-menu .el-submenu__title:hover i {
  color: #FFFFFF;
}
.lightWhite #app .black .avue-top .avue-top-sidebar .el-menu .headMenuItem {
  max-width: 160px;
}
.lightWhite #app .black .top-menu .el-menu-item i {
  color: #FFFFFF !important;
}
.lightWhite #app .black .top-menu .el-menu-item span {
  color: #FFFFFF;
}
.lightWhite #app .black .blend .el-menu .menu-wrapper .elMenuItem {
  background-color: #FFFFFF;
}
.lightWhite #app .black .plain .avue-layout {
  padding-left: 80px;
}
.lightWhite #app .black .plain .avue-left {
  width: 80px;
}
.lightWhite #app .black .plain .el-menu {
  background-color: #FFFFFF !important;
}
.lightWhite #app .black .plain .el-menu .nest-menu .el-submenu__title {
  height: 70px;
}
.lightWhite #app .black .plain .el-menu .nest-menu .el-submenu__title i {
  font-size: 28px;
}

.lightWhite > .black .headMenuItem {
  max-width: 150px;
}
.lightWhite > .black .el-menu--popup {
  max-height: 600px;
  overflow: auto;
}
.lightWhite > .black .el-menu {
  background-color: transparent;
}
.lightWhite > .black .el-menu .menu-wrapper {
  background-color: #FFFFFF;
}
.lightWhite > .black .el-menu .menu-wrapper .nest-menu {
  width: 100%;
}
.lightWhite > .black .el-menu .menu-wrapper .nest-menu .el-submenu__title {
  background-color: #FFFFFF;
  height: 46px;
  line-height: 46px;
}
.lightWhite > .black .el-menu .menu-wrapper .nest-menu .el-submenu__title i {
  color: #333333;
  margin-left: 15px;
  margin-right: 6px;
  font-size: 20px;
}
.lightWhite > .black .el-menu .menu-wrapper .nest-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite > .black .el-menu .el-menu-item {
  background-color: #FFFFFF;
  height: 46px;
  line-height: 46px;
}
.lightWhite > .black .el-menu .el-menu-item i {
  color: #333333;
  margin-left: 15px;
  margin-right: 6px;
  font-size: 20px;
}
.lightWhite > .black .el-menu .el-menu-item span {
  color: #333333;
}
.lightWhite > .black .el-menu .el-submenu__title i {
  color: #333333;
}
.lightWhite > .black .el-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite > .black .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite > .black .el-menu .el-submenu__title:hover span {
  color: #3C3E4D;
}
.lightWhite > .black .el-menu .el-submenu__title:hover i {
  color: #3C3E4D;
}
.lightWhite > .black .el-menu .el-menu-item:hover span {
  color: #3C3E4D;
}
.lightWhite > .black .el-menu .el-menu-item:hover i {
  color: #3C3E4D;
}
.lightWhite > .black .el-menu .el-menu-item.is-active {
  background-color: #3C3E4D;
  color: #FFFFFF;
}
.lightWhite > .black .el-menu .el-menu-item.is-active i {
  color: #FFFFFF;
}
.lightWhite > .black .el-menu .el-menu-item.is-active span {
  color: #FFFFFF;
}
.lightWhite > .black .el-menu .el-menu-item.is-active:hover span {
  color: #FFFFFF;
}
.lightWhite > .black .el-menu .el-menu-item.is-active:hover i {
  color: #FFFFFF;
}
.lightWhite > .black .el-menu .el-menu-item:hover i {
  color: #3C3E4D;
}
.lightWhite > .black .el-menu .el-menu-item:hover span {
  color: #3C3E4D;
}

.lightWhite #app .skyBlue .logo-content {
  background-color: #0090A8;
}
.lightWhite #app .skyBlue .avue-sidebar {
  background-color: #FFFFFF;
}
.lightWhite #app .skyBlue .avue-sidebar .el-menu {
  background-color: #FFFFFF;
}
.lightWhite #app .skyBlue .avue-sidebar .el-menu .nest-menu .menu-wrapper {
  background-color: #FFFFFF;
}
.lightWhite #app .skyBlue .avue-sidebar .el-menu .el-submenu__title i {
  color: #333333;
}
.lightWhite #app .skyBlue .avue-sidebar .el-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite #app .skyBlue .avue-sidebar .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite #app .skyBlue .avue-sidebar .el-menu .el-submenu__title:hover i {
  color: #0090A8;
}
.lightWhite #app .skyBlue .avue-sidebar .el-menu .el-submenu__title:hover span {
  color: #0090A8;
}
.lightWhite #app .skyBlue .avue-sidebar .el-menu .el-menu-item {
  background-color: #FFFFFF;
}
.lightWhite #app .skyBlue .avue-sidebar .el-menu .el-menu-item i {
  color: #333333;
}
.lightWhite #app .skyBlue .avue-sidebar .el-menu .el-menu-item span {
  color: #333333;
}
.lightWhite #app .skyBlue .avue-sidebar .el-menu .el-menu-item.is-active {
  background-color: #0090A8 !important;
  color: #FFFFFF;
}
.lightWhite #app .skyBlue .avue-sidebar .el-menu .el-menu-item.is-active i {
  color: #FFFFFF;
}
.lightWhite #app .skyBlue .avue-sidebar .el-menu .el-menu-item.is-active span {
  color: #FFFFFF;
}
.lightWhite #app .skyBlue .avue-sidebar .el-menu .el-menu-item.is-active:hover span {
  color: #FFFFFF;
}
.lightWhite #app .skyBlue .avue-sidebar .el-menu .el-menu-item.is-active:hover i {
  color: #FFFFFF;
}
.lightWhite #app .skyBlue .avue-sidebar .el-menu .el-menu-item:hover i {
  color: #0090A8;
}
.lightWhite #app .skyBlue .avue-sidebar .el-menu .el-menu-item:hover span {
  color: #0090A8;
}
.lightWhite #app .skyBlue .el-menu--vertical {
  background-color: #FFFFFF;
}
.lightWhite #app .skyBlue .el-menu--vertical .el-menu--popup {
  max-height: 600px !important;
  overflow: auto !important;
}
.lightWhite #app .skyBlue .el-menu--vertical .el-menu {
  background-color: #FFFFFF;
}
.lightWhite #app .skyBlue .el-menu--vertical .el-menu .el-menu-item {
  background-color: #FFFFFF;
}
.lightWhite #app .skyBlue .el-menu--vertical .el-menu .el-menu-item i {
  color: #333333;
}
.lightWhite #app .skyBlue .el-menu--vertical .el-menu .el-menu-item span {
  color: #333333;
}
.lightWhite #app .skyBlue .el-menu--vertical .el-menu .el-submenu__title i {
  color: #333333;
}
.lightWhite #app .skyBlue .el-menu--vertical .el-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite #app .skyBlue .el-menu--vertical .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite #app .skyBlue .el-menu--vertical .el-menu .el-submenu__title:hover span {
  color: #0090A8;
}
.lightWhite #app .skyBlue .el-menu--vertical .el-menu .el-submenu__title:hover i {
  color: #0090A8;
}
.lightWhite #app .skyBlue .el-menu--vertical .el-menu .el-menu-item:hover span {
  color: #0090A8;
}
.lightWhite #app .skyBlue .el-menu--vertical .el-menu .el-menu-item:hover i {
  color: #0090A8;
}
.lightWhite #app .skyBlue .el-menu--vertical .el-menu .el-menu-item.is-active {
  background-color: #0090A8;
  color: #333333;
}
.lightWhite #app .skyBlue .el-menu--vertical .el-menu .el-menu-item.is-active:hover span {
  color: #333333;
}
.lightWhite #app .skyBlue .el-menu--vertical .el-menu .el-menu-item.is-active:hover i {
  color: #333333;
}
.lightWhite #app .skyBlue .avue-top {
  color: #FFFFFF;
  background-color: #0090A8 !important;
}
.lightWhite #app .skyBlue .avue-top .avue-top-sidebar .el-menu {
  background-color: #0090A8;
}
.lightWhite #app .skyBlue .avue-top .avue-top-sidebar .el-menu .el-submenu__title {
  color: #FFFFFF;
}
.lightWhite #app .skyBlue .avue-top .avue-top-sidebar .el-menu .el-submenu__title i {
  color: #FFFFFF;
  margin-right: 10px;
}
.lightWhite #app .skyBlue .avue-top .avue-top-sidebar .el-menu .el-submenu__title i:last-child {
  display: none;
}
.lightWhite #app .skyBlue .avue-top .avue-top-sidebar .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite #app .skyBlue .avue-top .avue-top-sidebar .el-menu .el-submenu__title:hover span {
  color: #FFFFFF;
}
.lightWhite #app .skyBlue .avue-top .avue-top-sidebar .el-menu .el-submenu__title:hover i {
  color: #FFFFFF;
}
.lightWhite #app .skyBlue .avue-top .avue-top-sidebar .el-menu .headMenuItem {
  max-width: 160px;
}
.lightWhite #app .skyBlue .top-menu .el-menu-item i {
  color: #FFFFFF !important;
}
.lightWhite #app .skyBlue .top-menu .el-menu-item span {
  color: #FFFFFF;
}
.lightWhite #app .skyBlue .blend .el-menu .menu-wrapper .elMenuItem {
  background-color: #FFFFFF;
}
.lightWhite #app .skyBlue .plain .avue-layout {
  padding-left: 80px;
}
.lightWhite #app .skyBlue .plain .avue-left {
  width: 80px;
}
.lightWhite #app .skyBlue .plain .el-menu {
  background-color: #FFFFFF !important;
}
.lightWhite #app .skyBlue .plain .el-menu .nest-menu .el-submenu__title {
  height: 70px;
}
.lightWhite #app .skyBlue .plain .el-menu .nest-menu .el-submenu__title i {
  font-size: 28px;
}

.lightWhite > .skyBlue .headMenuItem {
  max-width: 150px;
}
.lightWhite > .skyBlue .el-menu--popup {
  max-height: 600px;
  overflow: auto;
}
.lightWhite > .skyBlue .el-menu {
  background-color: transparent;
}
.lightWhite > .skyBlue .el-menu .menu-wrapper {
  background-color: #FFFFFF;
}
.lightWhite > .skyBlue .el-menu .menu-wrapper .nest-menu {
  width: 100%;
}
.lightWhite > .skyBlue .el-menu .menu-wrapper .nest-menu .el-submenu__title {
  background-color: #FFFFFF;
  height: 46px;
  line-height: 46px;
}
.lightWhite > .skyBlue .el-menu .menu-wrapper .nest-menu .el-submenu__title i {
  color: #333333;
  margin-left: 15px;
  margin-right: 6px;
  font-size: 20px;
}
.lightWhite > .skyBlue .el-menu .menu-wrapper .nest-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite > .skyBlue .el-menu .el-menu-item {
  background-color: #FFFFFF;
  height: 46px;
  line-height: 46px;
}
.lightWhite > .skyBlue .el-menu .el-menu-item i {
  color: #333333;
  margin-left: 15px;
  margin-right: 6px;
  font-size: 20px;
}
.lightWhite > .skyBlue .el-menu .el-menu-item span {
  color: #333333;
}
.lightWhite > .skyBlue .el-menu .el-submenu__title i {
  color: #333333;
}
.lightWhite > .skyBlue .el-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite > .skyBlue .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite > .skyBlue .el-menu .el-submenu__title:hover span {
  color: #0090A8;
}
.lightWhite > .skyBlue .el-menu .el-submenu__title:hover i {
  color: #0090A8;
}
.lightWhite > .skyBlue .el-menu .el-menu-item:hover span {
  color: #0090A8;
}
.lightWhite > .skyBlue .el-menu .el-menu-item:hover i {
  color: #0090A8;
}
.lightWhite > .skyBlue .el-menu .el-menu-item.is-active {
  background-color: #0090A8;
  color: #FFFFFF;
}
.lightWhite > .skyBlue .el-menu .el-menu-item.is-active i {
  color: #FFFFFF;
}
.lightWhite > .skyBlue .el-menu .el-menu-item.is-active span {
  color: #FFFFFF;
}
.lightWhite > .skyBlue .el-menu .el-menu-item.is-active:hover span {
  color: #FFFFFF;
}
.lightWhite > .skyBlue .el-menu .el-menu-item.is-active:hover i {
  color: #FFFFFF;
}
.lightWhite > .skyBlue .el-menu .el-menu-item:hover i {
  color: #0090A8;
}
.lightWhite > .skyBlue .el-menu .el-menu-item:hover span {
  color: #0090A8;
}

.lightWhite #app .tangerine .logo-content {
  background-color: #D34827;
}
.lightWhite #app .tangerine .avue-sidebar {
  background-color: #FFFFFF;
}
.lightWhite #app .tangerine .avue-sidebar .el-menu {
  background-color: #FFFFFF;
}
.lightWhite #app .tangerine .avue-sidebar .el-menu .nest-menu .menu-wrapper {
  background-color: #FFFFFF;
}
.lightWhite #app .tangerine .avue-sidebar .el-menu .el-submenu__title i {
  color: #333333;
}
.lightWhite #app .tangerine .avue-sidebar .el-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite #app .tangerine .avue-sidebar .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite #app .tangerine .avue-sidebar .el-menu .el-submenu__title:hover i {
  color: #D34827;
}
.lightWhite #app .tangerine .avue-sidebar .el-menu .el-submenu__title:hover span {
  color: #D34827;
}
.lightWhite #app .tangerine .avue-sidebar .el-menu .el-menu-item {
  background-color: #FFFFFF;
}
.lightWhite #app .tangerine .avue-sidebar .el-menu .el-menu-item i {
  color: #333333;
}
.lightWhite #app .tangerine .avue-sidebar .el-menu .el-menu-item span {
  color: #333333;
}
.lightWhite #app .tangerine .avue-sidebar .el-menu .el-menu-item.is-active {
  background-color: #D34827 !important;
  color: #FFFFFF;
}
.lightWhite #app .tangerine .avue-sidebar .el-menu .el-menu-item.is-active i {
  color: #FFFFFF;
}
.lightWhite #app .tangerine .avue-sidebar .el-menu .el-menu-item.is-active span {
  color: #FFFFFF;
}
.lightWhite #app .tangerine .avue-sidebar .el-menu .el-menu-item.is-active:hover span {
  color: #FFFFFF;
}
.lightWhite #app .tangerine .avue-sidebar .el-menu .el-menu-item.is-active:hover i {
  color: #FFFFFF;
}
.lightWhite #app .tangerine .avue-sidebar .el-menu .el-menu-item:hover i {
  color: #D34827;
}
.lightWhite #app .tangerine .avue-sidebar .el-menu .el-menu-item:hover span {
  color: #D34827;
}
.lightWhite #app .tangerine .el-menu--vertical {
  background-color: #FFFFFF;
}
.lightWhite #app .tangerine .el-menu--vertical .el-menu--popup {
  max-height: 600px !important;
  overflow: auto !important;
}
.lightWhite #app .tangerine .el-menu--vertical .el-menu {
  background-color: #FFFFFF;
}
.lightWhite #app .tangerine .el-menu--vertical .el-menu .el-menu-item {
  background-color: #FFFFFF;
}
.lightWhite #app .tangerine .el-menu--vertical .el-menu .el-menu-item i {
  color: #333333;
}
.lightWhite #app .tangerine .el-menu--vertical .el-menu .el-menu-item span {
  color: #333333;
}
.lightWhite #app .tangerine .el-menu--vertical .el-menu .el-submenu__title i {
  color: #333333;
}
.lightWhite #app .tangerine .el-menu--vertical .el-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite #app .tangerine .el-menu--vertical .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite #app .tangerine .el-menu--vertical .el-menu .el-submenu__title:hover span {
  color: #D34827;
}
.lightWhite #app .tangerine .el-menu--vertical .el-menu .el-submenu__title:hover i {
  color: #D34827;
}
.lightWhite #app .tangerine .el-menu--vertical .el-menu .el-menu-item:hover span {
  color: #D34827;
}
.lightWhite #app .tangerine .el-menu--vertical .el-menu .el-menu-item:hover i {
  color: #D34827;
}
.lightWhite #app .tangerine .el-menu--vertical .el-menu .el-menu-item.is-active {
  background-color: #D34827;
  color: #333333;
}
.lightWhite #app .tangerine .el-menu--vertical .el-menu .el-menu-item.is-active:hover span {
  color: #333333;
}
.lightWhite #app .tangerine .el-menu--vertical .el-menu .el-menu-item.is-active:hover i {
  color: #333333;
}
.lightWhite #app .tangerine .avue-top {
  color: #FFFFFF;
  background-color: #D34827 !important;
}
.lightWhite #app .tangerine .avue-top .avue-top-sidebar .el-menu {
  background-color: #D34827;
}
.lightWhite #app .tangerine .avue-top .avue-top-sidebar .el-menu .el-submenu__title {
  color: #FFFFFF;
}
.lightWhite #app .tangerine .avue-top .avue-top-sidebar .el-menu .el-submenu__title i {
  color: #FFFFFF;
  margin-right: 10px;
}
.lightWhite #app .tangerine .avue-top .avue-top-sidebar .el-menu .el-submenu__title i:last-child {
  display: none;
}
.lightWhite #app .tangerine .avue-top .avue-top-sidebar .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite #app .tangerine .avue-top .avue-top-sidebar .el-menu .el-submenu__title:hover span {
  color: #FFFFFF;
}
.lightWhite #app .tangerine .avue-top .avue-top-sidebar .el-menu .el-submenu__title:hover i {
  color: #FFFFFF;
}
.lightWhite #app .tangerine .avue-top .avue-top-sidebar .el-menu .headMenuItem {
  max-width: 160px;
}
.lightWhite #app .tangerine .top-menu .el-menu-item i {
  color: #FFFFFF !important;
}
.lightWhite #app .tangerine .top-menu .el-menu-item span {
  color: #FFFFFF;
}
.lightWhite #app .tangerine .blend .el-menu .menu-wrapper .elMenuItem {
  background-color: #FFFFFF;
}
.lightWhite #app .tangerine .plain .avue-layout {
  padding-left: 80px;
}
.lightWhite #app .tangerine .plain .avue-left {
  width: 80px;
}
.lightWhite #app .tangerine .plain .el-menu {
  background-color: #FFFFFF !important;
}
.lightWhite #app .tangerine .plain .el-menu .nest-menu .el-submenu__title {
  height: 70px;
}
.lightWhite #app .tangerine .plain .el-menu .nest-menu .el-submenu__title i {
  font-size: 28px;
}

.lightWhite > .tangerine .headMenuItem {
  max-width: 150px;
}
.lightWhite > .tangerine .el-menu--popup {
  max-height: 600px;
  overflow: auto;
}
.lightWhite > .tangerine .el-menu {
  background-color: transparent;
}
.lightWhite > .tangerine .el-menu .menu-wrapper {
  background-color: #FFFFFF;
}
.lightWhite > .tangerine .el-menu .menu-wrapper .nest-menu {
  width: 100%;
}
.lightWhite > .tangerine .el-menu .menu-wrapper .nest-menu .el-submenu__title {
  background-color: #FFFFFF;
  height: 46px;
  line-height: 46px;
}
.lightWhite > .tangerine .el-menu .menu-wrapper .nest-menu .el-submenu__title i {
  color: #333333;
  margin-left: 15px;
  margin-right: 6px;
  font-size: 20px;
}
.lightWhite > .tangerine .el-menu .menu-wrapper .nest-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite > .tangerine .el-menu .el-menu-item {
  background-color: #FFFFFF;
  height: 46px;
  line-height: 46px;
}
.lightWhite > .tangerine .el-menu .el-menu-item i {
  color: #333333;
  margin-left: 15px;
  margin-right: 6px;
  font-size: 20px;
}
.lightWhite > .tangerine .el-menu .el-menu-item span {
  color: #333333;
}
.lightWhite > .tangerine .el-menu .el-submenu__title i {
  color: #333333;
}
.lightWhite > .tangerine .el-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite > .tangerine .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite > .tangerine .el-menu .el-submenu__title:hover span {
  color: #D34827;
}
.lightWhite > .tangerine .el-menu .el-submenu__title:hover i {
  color: #D34827;
}
.lightWhite > .tangerine .el-menu .el-menu-item:hover span {
  color: #D34827;
}
.lightWhite > .tangerine .el-menu .el-menu-item:hover i {
  color: #D34827;
}
.lightWhite > .tangerine .el-menu .el-menu-item.is-active {
  background-color: #D34827;
  color: #FFFFFF;
}
.lightWhite > .tangerine .el-menu .el-menu-item.is-active i {
  color: #FFFFFF;
}
.lightWhite > .tangerine .el-menu .el-menu-item.is-active span {
  color: #FFFFFF;
}
.lightWhite > .tangerine .el-menu .el-menu-item.is-active:hover span {
  color: #FFFFFF;
}
.lightWhite > .tangerine .el-menu .el-menu-item.is-active:hover i {
  color: #FFFFFF;
}
.lightWhite > .tangerine .el-menu .el-menu-item:hover i {
  color: #D34827;
}
.lightWhite > .tangerine .el-menu .el-menu-item:hover span {
  color: #D34827;
}

.lightWhite #app .darkBlue .logo-content {
  background-color: #305198;
}
.lightWhite #app .darkBlue .avue-sidebar {
  background-color: #FFFFFF;
}
.lightWhite #app .darkBlue .avue-sidebar .el-menu {
  background-color: #FFFFFF;
}
.lightWhite #app .darkBlue .avue-sidebar .el-menu .nest-menu .menu-wrapper {
  background-color: #FFFFFF;
}
.lightWhite #app .darkBlue .avue-sidebar .el-menu .el-submenu__title i {
  color: #333333;
}
.lightWhite #app .darkBlue .avue-sidebar .el-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite #app .darkBlue .avue-sidebar .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite #app .darkBlue .avue-sidebar .el-menu .el-submenu__title:hover i {
  color: #305198;
}
.lightWhite #app .darkBlue .avue-sidebar .el-menu .el-submenu__title:hover span {
  color: #305198;
}
.lightWhite #app .darkBlue .avue-sidebar .el-menu .el-menu-item {
  background-color: #FFFFFF;
}
.lightWhite #app .darkBlue .avue-sidebar .el-menu .el-menu-item i {
  color: #333333;
}
.lightWhite #app .darkBlue .avue-sidebar .el-menu .el-menu-item span {
  color: #333333;
}
.lightWhite #app .darkBlue .avue-sidebar .el-menu .el-menu-item.is-active {
  background-color: #305198 !important;
  color: #FFFFFF;
}
.lightWhite #app .darkBlue .avue-sidebar .el-menu .el-menu-item.is-active i {
  color: #FFFFFF;
}
.lightWhite #app .darkBlue .avue-sidebar .el-menu .el-menu-item.is-active span {
  color: #FFFFFF;
}
.lightWhite #app .darkBlue .avue-sidebar .el-menu .el-menu-item.is-active:hover span {
  color: #FFFFFF;
}
.lightWhite #app .darkBlue .avue-sidebar .el-menu .el-menu-item.is-active:hover i {
  color: #FFFFFF;
}
.lightWhite #app .darkBlue .avue-sidebar .el-menu .el-menu-item:hover i {
  color: #305198;
}
.lightWhite #app .darkBlue .avue-sidebar .el-menu .el-menu-item:hover span {
  color: #305198;
}
.lightWhite #app .darkBlue .el-menu--vertical {
  background-color: #FFFFFF;
}
.lightWhite #app .darkBlue .el-menu--vertical .el-menu--popup {
  max-height: 600px !important;
  overflow: auto !important;
}
.lightWhite #app .darkBlue .el-menu--vertical .el-menu {
  background-color: #FFFFFF;
}
.lightWhite #app .darkBlue .el-menu--vertical .el-menu .el-menu-item {
  background-color: #FFFFFF;
}
.lightWhite #app .darkBlue .el-menu--vertical .el-menu .el-menu-item i {
  color: #333333;
}
.lightWhite #app .darkBlue .el-menu--vertical .el-menu .el-menu-item span {
  color: #333333;
}
.lightWhite #app .darkBlue .el-menu--vertical .el-menu .el-submenu__title i {
  color: #333333;
}
.lightWhite #app .darkBlue .el-menu--vertical .el-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite #app .darkBlue .el-menu--vertical .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite #app .darkBlue .el-menu--vertical .el-menu .el-submenu__title:hover span {
  color: #305198;
}
.lightWhite #app .darkBlue .el-menu--vertical .el-menu .el-submenu__title:hover i {
  color: #305198;
}
.lightWhite #app .darkBlue .el-menu--vertical .el-menu .el-menu-item:hover span {
  color: #305198;
}
.lightWhite #app .darkBlue .el-menu--vertical .el-menu .el-menu-item:hover i {
  color: #305198;
}
.lightWhite #app .darkBlue .el-menu--vertical .el-menu .el-menu-item.is-active {
  background-color: #305198;
  color: #333333;
}
.lightWhite #app .darkBlue .el-menu--vertical .el-menu .el-menu-item.is-active:hover span {
  color: #333333;
}
.lightWhite #app .darkBlue .el-menu--vertical .el-menu .el-menu-item.is-active:hover i {
  color: #333333;
}
.lightWhite #app .darkBlue .avue-top {
  color: #FFFFFF;
  background-color: #305198 !important;
}
.lightWhite #app .darkBlue .avue-top .avue-top-sidebar .el-menu {
  background-color: #305198;
}
.lightWhite #app .darkBlue .avue-top .avue-top-sidebar .el-menu .el-submenu__title {
  color: #FFFFFF;
}
.lightWhite #app .darkBlue .avue-top .avue-top-sidebar .el-menu .el-submenu__title i {
  color: #FFFFFF;
  margin-right: 10px;
}
.lightWhite #app .darkBlue .avue-top .avue-top-sidebar .el-menu .el-submenu__title i:last-child {
  display: none;
}
.lightWhite #app .darkBlue .avue-top .avue-top-sidebar .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite #app .darkBlue .avue-top .avue-top-sidebar .el-menu .el-submenu__title:hover span {
  color: #FFFFFF;
}
.lightWhite #app .darkBlue .avue-top .avue-top-sidebar .el-menu .el-submenu__title:hover i {
  color: #FFFFFF;
}
.lightWhite #app .darkBlue .avue-top .avue-top-sidebar .el-menu .headMenuItem {
  max-width: 160px;
}
.lightWhite #app .darkBlue .top-menu .el-menu-item i {
  color: #FFFFFF !important;
}
.lightWhite #app .darkBlue .top-menu .el-menu-item span {
  color: #FFFFFF;
}
.lightWhite #app .darkBlue .blend .el-menu .menu-wrapper .elMenuItem {
  background-color: #FFFFFF;
}
.lightWhite #app .darkBlue .plain .avue-layout {
  padding-left: 80px;
}
.lightWhite #app .darkBlue .plain .avue-left {
  width: 80px;
}
.lightWhite #app .darkBlue .plain .el-menu {
  background-color: #FFFFFF !important;
}
.lightWhite #app .darkBlue .plain .el-menu .nest-menu .el-submenu__title {
  height: 70px;
}
.lightWhite #app .darkBlue .plain .el-menu .nest-menu .el-submenu__title i {
  font-size: 28px;
}

.lightWhite > .darkBlue .headMenuItem {
  max-width: 150px;
}
.lightWhite > .darkBlue .el-menu--popup {
  max-height: 600px;
  overflow: auto;
}
.lightWhite > .darkBlue .el-menu {
  background-color: transparent;
}
.lightWhite > .darkBlue .el-menu .menu-wrapper {
  background-color: #FFFFFF;
}
.lightWhite > .darkBlue .el-menu .menu-wrapper .nest-menu {
  width: 100%;
}
.lightWhite > .darkBlue .el-menu .menu-wrapper .nest-menu .el-submenu__title {
  background-color: #FFFFFF;
  height: 46px;
  line-height: 46px;
}
.lightWhite > .darkBlue .el-menu .menu-wrapper .nest-menu .el-submenu__title i {
  color: #333333;
  margin-left: 15px;
  margin-right: 6px;
  font-size: 20px;
}
.lightWhite > .darkBlue .el-menu .menu-wrapper .nest-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite > .darkBlue .el-menu .el-menu-item {
  background-color: #FFFFFF;
  height: 46px;
  line-height: 46px;
}
.lightWhite > .darkBlue .el-menu .el-menu-item i {
  color: #333333;
  margin-left: 15px;
  margin-right: 6px;
  font-size: 20px;
}
.lightWhite > .darkBlue .el-menu .el-menu-item span {
  color: #333333;
}
.lightWhite > .darkBlue .el-menu .el-submenu__title i {
  color: #333333;
}
.lightWhite > .darkBlue .el-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite > .darkBlue .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite > .darkBlue .el-menu .el-submenu__title:hover span {
  color: #305198;
}
.lightWhite > .darkBlue .el-menu .el-submenu__title:hover i {
  color: #305198;
}
.lightWhite > .darkBlue .el-menu .el-menu-item:hover span {
  color: #305198;
}
.lightWhite > .darkBlue .el-menu .el-menu-item:hover i {
  color: #305198;
}
.lightWhite > .darkBlue .el-menu .el-menu-item.is-active {
  background-color: #305198;
  color: #FFFFFF;
}
.lightWhite > .darkBlue .el-menu .el-menu-item.is-active i {
  color: #FFFFFF;
}
.lightWhite > .darkBlue .el-menu .el-menu-item.is-active span {
  color: #FFFFFF;
}
.lightWhite > .darkBlue .el-menu .el-menu-item.is-active:hover span {
  color: #FFFFFF;
}
.lightWhite > .darkBlue .el-menu .el-menu-item.is-active:hover i {
  color: #FFFFFF;
}
.lightWhite > .darkBlue .el-menu .el-menu-item:hover i {
  color: #305198;
}
.lightWhite > .darkBlue .el-menu .el-menu-item:hover span {
  color: #305198;
}

.lightWhite #app .flamingRed .logo-content {
  background-color: #b3150c;
}
.lightWhite #app .flamingRed .avue-sidebar {
  background-color: #FFFFFF;
}
.lightWhite #app .flamingRed .avue-sidebar .el-menu {
  background-color: #FFFFFF;
}
.lightWhite #app .flamingRed .avue-sidebar .el-menu .nest-menu .menu-wrapper {
  background-color: #FFFFFF;
}
.lightWhite #app .flamingRed .avue-sidebar .el-menu .el-submenu__title i {
  color: #333333;
}
.lightWhite #app .flamingRed .avue-sidebar .el-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite #app .flamingRed .avue-sidebar .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite #app .flamingRed .avue-sidebar .el-menu .el-submenu__title:hover i {
  color: #b3150c;
}
.lightWhite #app .flamingRed .avue-sidebar .el-menu .el-submenu__title:hover span {
  color: #b3150c;
}
.lightWhite #app .flamingRed .avue-sidebar .el-menu .el-menu-item {
  background-color: #FFFFFF;
}
.lightWhite #app .flamingRed .avue-sidebar .el-menu .el-menu-item i {
  color: #333333;
}
.lightWhite #app .flamingRed .avue-sidebar .el-menu .el-menu-item span {
  color: #333333;
}
.lightWhite #app .flamingRed .avue-sidebar .el-menu .el-menu-item.is-active {
  background-color: #b3150c !important;
  color: #FFFFFF;
}
.lightWhite #app .flamingRed .avue-sidebar .el-menu .el-menu-item.is-active i {
  color: #FFFFFF;
}
.lightWhite #app .flamingRed .avue-sidebar .el-menu .el-menu-item.is-active span {
  color: #FFFFFF;
}
.lightWhite #app .flamingRed .avue-sidebar .el-menu .el-menu-item.is-active:hover span {
  color: #FFFFFF;
}
.lightWhite #app .flamingRed .avue-sidebar .el-menu .el-menu-item.is-active:hover i {
  color: #FFFFFF;
}
.lightWhite #app .flamingRed .avue-sidebar .el-menu .el-menu-item:hover i {
  color: #b3150c;
}
.lightWhite #app .flamingRed .avue-sidebar .el-menu .el-menu-item:hover span {
  color: #b3150c;
}
.lightWhite #app .flamingRed .el-menu--vertical {
  background-color: #FFFFFF;
}
.lightWhite #app .flamingRed .el-menu--vertical .el-menu--popup {
  max-height: 600px !important;
  overflow: auto !important;
}
.lightWhite #app .flamingRed .el-menu--vertical .el-menu {
  background-color: #FFFFFF;
}
.lightWhite #app .flamingRed .el-menu--vertical .el-menu .el-menu-item {
  background-color: #FFFFFF;
}
.lightWhite #app .flamingRed .el-menu--vertical .el-menu .el-menu-item i {
  color: #333333;
}
.lightWhite #app .flamingRed .el-menu--vertical .el-menu .el-menu-item span {
  color: #333333;
}
.lightWhite #app .flamingRed .el-menu--vertical .el-menu .el-submenu__title i {
  color: #333333;
}
.lightWhite #app .flamingRed .el-menu--vertical .el-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite #app .flamingRed .el-menu--vertical .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite #app .flamingRed .el-menu--vertical .el-menu .el-submenu__title:hover span {
  color: #b3150c;
}
.lightWhite #app .flamingRed .el-menu--vertical .el-menu .el-submenu__title:hover i {
  color: #b3150c;
}
.lightWhite #app .flamingRed .el-menu--vertical .el-menu .el-menu-item:hover span {
  color: #b3150c;
}
.lightWhite #app .flamingRed .el-menu--vertical .el-menu .el-menu-item:hover i {
  color: #b3150c;
}
.lightWhite #app .flamingRed .el-menu--vertical .el-menu .el-menu-item.is-active {
  background-color: #b3150c;
  color: #333333;
}
.lightWhite #app .flamingRed .el-menu--vertical .el-menu .el-menu-item.is-active:hover span {
  color: #333333;
}
.lightWhite #app .flamingRed .el-menu--vertical .el-menu .el-menu-item.is-active:hover i {
  color: #333333;
}
.lightWhite #app .flamingRed .avue-top {
  color: #FFFFFF;
  background-color: #b3150c !important;
}
.lightWhite #app .flamingRed .avue-top .avue-top-sidebar .el-menu {
  background-color: #b3150c;
}
.lightWhite #app .flamingRed .avue-top .avue-top-sidebar .el-menu .el-submenu__title {
  color: #FFFFFF;
}
.lightWhite #app .flamingRed .avue-top .avue-top-sidebar .el-menu .el-submenu__title i {
  color: #FFFFFF;
  margin-right: 10px;
}
.lightWhite #app .flamingRed .avue-top .avue-top-sidebar .el-menu .el-submenu__title i:last-child {
  display: none;
}
.lightWhite #app .flamingRed .avue-top .avue-top-sidebar .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite #app .flamingRed .avue-top .avue-top-sidebar .el-menu .el-submenu__title:hover span {
  color: #FFFFFF;
}
.lightWhite #app .flamingRed .avue-top .avue-top-sidebar .el-menu .el-submenu__title:hover i {
  color: #FFFFFF;
}
.lightWhite #app .flamingRed .avue-top .avue-top-sidebar .el-menu .headMenuItem {
  max-width: 160px;
}
.lightWhite #app .flamingRed .top-menu .el-menu-item i {
  color: #FFFFFF !important;
}
.lightWhite #app .flamingRed .top-menu .el-menu-item span {
  color: #FFFFFF;
}
.lightWhite #app .flamingRed .blend .el-menu .menu-wrapper .elMenuItem {
  background-color: #FFFFFF;
}
.lightWhite #app .flamingRed .plain .avue-layout {
  padding-left: 80px;
}
.lightWhite #app .flamingRed .plain .avue-left {
  width: 80px;
}
.lightWhite #app .flamingRed .plain .el-menu {
  background-color: #FFFFFF !important;
}
.lightWhite #app .flamingRed .plain .el-menu .nest-menu .el-submenu__title {
  height: 70px;
}
.lightWhite #app .flamingRed .plain .el-menu .nest-menu .el-submenu__title i {
  font-size: 28px;
}

.lightWhite > .flamingRed .headMenuItem {
  max-width: 150px;
}
.lightWhite > .flamingRed .el-menu--popup {
  max-height: 600px;
  overflow: auto;
}
.lightWhite > .flamingRed .el-menu {
  background-color: transparent;
}
.lightWhite > .flamingRed .el-menu .menu-wrapper {
  background-color: #FFFFFF;
}
.lightWhite > .flamingRed .el-menu .menu-wrapper .nest-menu {
  width: 100%;
}
.lightWhite > .flamingRed .el-menu .menu-wrapper .nest-menu .el-submenu__title {
  background-color: #FFFFFF;
  height: 46px;
  line-height: 46px;
}
.lightWhite > .flamingRed .el-menu .menu-wrapper .nest-menu .el-submenu__title i {
  color: #333333;
  margin-left: 15px;
  margin-right: 6px;
  font-size: 20px;
}
.lightWhite > .flamingRed .el-menu .menu-wrapper .nest-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite > .flamingRed .el-menu .el-menu-item {
  background-color: #FFFFFF;
  height: 46px;
  line-height: 46px;
}
.lightWhite > .flamingRed .el-menu .el-menu-item i {
  color: #333333;
  margin-left: 15px;
  margin-right: 6px;
  font-size: 20px;
}
.lightWhite > .flamingRed .el-menu .el-menu-item span {
  color: #333333;
}
.lightWhite > .flamingRed .el-menu .el-submenu__title i {
  color: #333333;
}
.lightWhite > .flamingRed .el-menu .el-submenu__title span {
  color: #333333;
}
.lightWhite > .flamingRed .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.lightWhite > .flamingRed .el-menu .el-submenu__title:hover span {
  color: #b3150c;
}
.lightWhite > .flamingRed .el-menu .el-submenu__title:hover i {
  color: #b3150c;
}
.lightWhite > .flamingRed .el-menu .el-menu-item:hover span {
  color: #b3150c;
}
.lightWhite > .flamingRed .el-menu .el-menu-item:hover i {
  color: #b3150c;
}
.lightWhite > .flamingRed .el-menu .el-menu-item.is-active {
  background-color: #b3150c;
  color: #FFFFFF;
}
.lightWhite > .flamingRed .el-menu .el-menu-item.is-active i {
  color: #FFFFFF;
}
.lightWhite > .flamingRed .el-menu .el-menu-item.is-active span {
  color: #FFFFFF;
}
.lightWhite > .flamingRed .el-menu .el-menu-item.is-active:hover span {
  color: #FFFFFF;
}
.lightWhite > .flamingRed .el-menu .el-menu-item.is-active:hover i {
  color: #FFFFFF;
}
.lightWhite > .flamingRed .el-menu .el-menu-item:hover i {
  color: #b3150c;
}
.lightWhite > .flamingRed .el-menu .el-menu-item:hover span {
  color: #b3150c;
}