.my-autocomplete li {
  line-height: normal;
  padding: 7px;
}
.my-autocomplete li .icon {
  margin-right: 5px;
  display: inline-block;
  vertical-align: middle;
}
.my-autocomplete li .name {
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: middle;
}
.my-autocomplete li .addr {
  padding-top: 5px;
  width: 100%;
  font-size: 12px;
  color: #b4b4b4;
}
.my-autocomplete li .highlighted .addr {
  color: #ddd;
}