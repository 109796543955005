.projectSelectUser .el-scrollbar[data-v-38f7ed78] {
  height: 100%;
}
.projectSelectUser .box .el-scrollbar__wrap[data-v-38f7ed78] {
  overflow: scroll;
}
.projectSelectUser .footerBtn[data-v-38f7ed78] {
  border-top: 1px solid #cccccc;
  padding-top: 10px;
}
.projectSelectUser[data-v-38f7ed78] .el-tree {
  height: 490px !important;
  max-height: 490px !important;
}