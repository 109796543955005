.main[data-v-1aa7f404] {
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 0 0 10px;
}
.main[data-v-1aa7f404] .el-table {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  border-top: none;
}