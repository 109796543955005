[data-v-1263059b] .cell {
  font-size: 14px;
}
.avue-form[data-v-1263059b] {
  height: 44px;
}
.avue-crud[data-v-1263059b] .avue-crud__menu {
  min-height: 0 !important;
  margin: 0 !important;
}
[data-v-1263059b] .avueCrudNoTag .el-table {
  height: calc(100vh - 234px) !important;
  max-height: calc(100vh - 234px) !important;
}
.avue-crud__pagination[data-v-1263059b] {
  background-color: #ffffff !important;
}
.el-link[data-v-1263059b] {
  text-decoration: underline;
  -webkit-text-decoration-color: #1b1b1b;
          text-decoration-color: #1b1b1b;
}
.el-link[data-v-1263059b]:hover {
  color: #6c757d;
  text-decoration: none;
}
[data-v-1263059b] .avue-crud .el-table th {
  background: #fafafa;
}
[data-v-1263059b] .el-table--border, .el-table--group[data-v-1263059b] {
  border-color: #cccccc !important;
}
[data-v-1263059b] .borderColor {
  border-color: #cccccc !important;
  border-right: 1px solid #cccccc !important;
}
[data-v-1263059b] .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf[data-v-1263059b] {
  border-color: #cccccc !important;
}
[data-v-1263059b] .el-table__cell {
  border-left: 1px solid #cccccc !important;
}
[data-v-1263059b] .el-table__fixed-right::before {
  background-color: #ffffff !important;
}
[data-v-1263059b] .el-table__fixed::before {
  background-color: #ffffff !important;
}
[data-v-1263059b] .el-table--border::after, .el-table--group[data-v-1263059b]::after, .el-table[data-v-1263059b]::before {
  background-color: #cccccc !important;
}
[data-v-1263059b] .el-table--border {
  border-bottom: 1px solid #cccccc !important;
}
[data-v-1263059b] .el-icon-circle-close {
  display: none;
}
[data-v-1263059b] .el-icon-circle-plus-outline {
  display: none;
}
[data-v-1263059b] .el-button + .el-button {
  margin-left: 10px;
}