@charset "UTF-8";
/* 清除浮动 */
.clearfix::after {
  display: block;
  content: "";
  clear: both;
}
.my-card {
  width: 100%;
  background: #ffffff;
}
.my-card .card-header {
  padding: 12px 20px;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-bottom: 1px solid #E5E5E5;
}
.my-card .card-header .title {
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  position: relative;
  padding-left: 10px;
}
.my-card .card-header .title::before {
  content: "";
  width: 3px;
  height: 14px;
  background: #0B82FF;
  margin-right: 10px;
  display: inline-block;
  position: absolute;
  top: 50%;
  margin-top: -7px;
  left: 0;
}
.my-card .card-header .more-btn, .my-card .card-header .set-btn {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  font-weight: normal;
  float: right;
  cursor: pointer;
}