.uploader-file-info[data-v-469a5b5d] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.uploader-file-info .uploader-file-actions[data-v-469a5b5d] {
  width: 50px;
  padding-right: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.uploader-file-info .uploader-file-actions .actions-icon[data-v-469a5b5d] {
  color: #606266;
  opacity: 0.8;
  cursor: pointer;
  margin-left: 6px;
  font-size: 14px;
  display: block;
}
.uploader-file-info .uploader-file-actions .el-icon-close[data-v-469a5b5d] {
  display: block;
  position: static;
}
.uploader-file-info .uploader-file-status[data-v-469a5b5d] {
  width: 150px !important;
  text-indent: 10px !important;
}
.uploader-file-info .uploader-file-status em[data-v-469a5b5d] {
  margin: 0 5px;
}
.uploader-file-name[data-v-469a5b5d] {
  width: calc(100% - 200px);
  text-indent: 0px !important;
  padding-left: 4px;
  padding-right: 10px;
}
.uploader-file-name .el-icon-paperclip[data-v-469a5b5d] {
  margin-right: 7px;
  line-height: 25px;
}
.uploader-file-size[data-v-469a5b5d] {
  width: 13%;
  text-indent: 10px;
}
.uploader-file-meta[data-v-469a5b5d] {
  width: 8%;
}