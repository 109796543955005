@font-face {
  font-family: "icon-ym";
  /* Project id 3271933 */
  src: url(../../static/fonts/iconfont.4c981ce8.woff2) format("woff2"), url(../../static/fonts/iconfont.25a83f92.woff) format("woff"), url(../../static/fonts/iconfont.af9f6432.ttf) format("truetype");
}
.icon-ym {
  font-family: "icon-ym" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ym-flowEntrust-app:before {
  content: "\E8DA";
}

.icon-ym-btn-download:before {
  content: "\E8D9";
}

.icon-ym-btn-upload:before {
  content: "\E8D7";
}

.icon-ym-right:before {
  content: "\E8D4";
}

.icon-ym-left:before {
  content: "\E8D5";
}

.icon-ym-arrow-down:before {
  content: "\E8D6";
}

.icon-ym-add-flow:before {
  content: "\E8D8";
}

.icon-ym-launchFlow:before {
  content: "\E8D2";
}

.icon-ym-funcFlow:before {
  content: "\E8D3";
}

.icon-ym-accountConfig:before {
  content: "\E8CB";
}

.icon-ym-sendConfig:before {
  content: "\E8CC";
}

.icon-ym-smsConfig:before {
  content: "\E8CD";
}

.icon-ym-emailConfig:before {
  content: "\E8CE";
}

.icon-ym-msgCenter:before {
  content: "\E8CF";
}

.icon-ym-msgTemplate:before {
  content: "\E8D0";
}

.icon-ym-msgMonitor:before {
  content: "\E8D1";
}

.icon-ym-logo-webhook:before {
  content: "\E8CA";
}

.icon-ym-logo-github:before {
  content: "\E8B0";
}

.icon-ym-logo-feishu:before {
  content: "\E8C5";
}

.icon-ym-logo-dingding:before {
  content: "\E8C6";
}

.icon-ym-logo-wechat:before {
  content: "\E8C7";
}

.icon-ym-logo-qq:before {
  content: "\E8C8";
}

.icon-ym-logo-wxWork:before {
  content: "\E8C9";
}

.icon-ym-position1:before {
  content: "\E8C3";
}

.icon-ym-generator-popupTableSelect:before {
  content: "\E8C4";
}

.icon-ym-gradeManage:before {
  content: "\E8C2";
}

.icon-ym-fold:before {
  content: "\E829";
}

.icon-ym-customForm:before {
  content: "\E857";
}

.icon-ym-branch:before {
  content: "\E8B1";
}

.icon-ym-codeGeneration1:before {
  content: "\E8B2";
}

.icon-ym-filter:before {
  content: "\E8B3";
}

.icon-ym-download:before {
  content: "\E8B4";
}

.icon-ym-functionForm:before {
  content: "\E8B5";
}

.icon-ym-unfold:before {
  content: "\E8B6";
}

.icon-ym-node:before {
  content: "\E8B7";
}

.icon-ym-nav-home:before {
  content: "\E8B8";
}

.icon-ym-systemForm:before {
  content: "\E8B9";
}

.icon-ym-generator-link:before {
  content: "\E8BA";
}

.icon-ym-timer:before {
  content: "\E8BB";
}

.icon-ym-generator-alert:before {
  content: "\E8BC";
}

.icon-ym-generator-group1:before {
  content: "\E8BD";
}

.icon-ym-generator-role:before {
  content: "\E8BE";
}

.icon-ym-systemToggle:before {
  content: "\E8BF";
}

.icon-ym-upload:before {
  content: "\E8C0";
}

.icon-ym-generator-department1:before {
  content: "\E8C1";
}

.icon-ym-signature:before {
  content: "\E8AF";
}

.icon-ym-yingyong:before {
  content: "\E81E";
}

.icon-ym-generator-section:before {
  content: "\E853";
}

.icon-ym-lianxi:before {
  content: "\E873";
}

.icon-ym-bigScreenExampl:before {
  content: "\E755";
}

.icon-ym-appForm:before {
  content: "\E756";
}

.icon-ym-authorize:before {
  content: "\E75C";
}

.icon-ym-dataApplication:before {
  content: "\E75D";
}

.icon-ym-dataDictionary:before {
  content: "\E76C";
}

.icon-ym-codeGeneration:before {
  content: "\E76F";
}

.icon-ym-documentPreviewExample:before {
  content: "\E77B";
}

.icon-ym-dataInterface:before {
  content: "\E78F";
}

.icon-ym-bigScreenDesign:before {
  content: "\E7A7";
}

.icon-ym-dataSync:before {
  content: "\E7AB";
}

.icon-ym-extend:before {
  content: "\E7B1";
}

.icon-ym-flowForm:before {
  content: "\E7B2";
}

.icon-ym-dataModel:before {
  content: "\E7BB";
}

.icon-ym-formExample:before {
  content: "\E7C8";
}

.icon-ym-department:before {
  content: "\E812";
}

.icon-ym-dataSource:before {
  content: "\E816";
}

.icon-ym-flowLaunch:before {
  content: "\E81B";
}

.icon-ym-flowForm1:before {
  content: "\E81F";
}

.icon-ym-formDesign:before {
  content: "\E827";
}

.icon-ym-highchartsWordcloud:before {
  content: "\E82C";
}

.icon-ym-dataBackup:before {
  content: "\E830";
}

.icon-ym-flowEntrust:before {
  content: "\E831";
}

.icon-ym-role:before {
  content: "\E832";
}

.icon-ym-information:before {
  content: "\E834";
}

.icon-ym-portalDesign:before {
  content: "\E83B";
}

.icon-ym-highchartsColumn:before {
  content: "\E83C";
}

.icon-ym-onlineUser:before {
  content: "\E83F";
}

.icon-ym-sysMonitor:before {
  content: "\E842";
}

.icon-ym-sysCache:before {
  content: "\E843";
}

.icon-ym-organization:before {
  content: "\E845";
}

.icon-ym-flowTodo:before {
  content: "\E847";
}

.icon-ym-pause:before {
  content: "\E849";
}

.icon-ym-report-icon-preview-pageIndex:before {
  content: "\E84C";
}

.icon-ym-sysProvince:before {
  content: "\E850";
}

.icon-ym-sysNotice:before {
  content: "\E851";
}

.icon-ym-wf-finishedProduct:before {
  content: "\E852";
}

.icon-ym-reportExample:before {
  content: "\E85D";
}

.icon-ym-wf-crmOrder:before {
  content: "\E864";
}

.icon-ym-signetExample:before {
  content: "\E866";
}

.icon-ym-position:before {
  content: "\E867";
}

.icon-ym-permission:before {
  content: "\E868";
}

.icon-ym-user:before {
  content: "\E871";
}

.icon-ym-wf-expenseExpenditure:before {
  content: "\E877";
}

.icon-ym-onlineDevelopment:before {
  content: "\E87A";
}

.icon-ym-sysBillRule:before {
  content: "\E88B";
}

.icon-ym-system:before {
  content: "\E892";
}

.icon-ym-reportDesign:before {
  content: "\E896";
}

.icon-ym-sysQuartz:before {
  content: "\E897";
}

.icon-ym-workFlow:before {
  content: "\E89E";
}

.icon-ym-sysIcon:before {
  content: "\E8A4";
}

.icon-ym-huibao:before {
  content: "\E8A5";
}

.icon-ym-webForm:before {
  content: "\E8A6";
}

.icon-ym-wf-staffOvertime:before {
  content: "\E8A7";
}

.icon-ym-tree-manage1:before {
  content: "\E8A8";
}

.icon-ym-webDesign:before {
  content: "\E8A9";
}

.icon-ym-wf-receiptSign:before {
  content: "\E8AA";
}

.icon-ym-windows:before {
  content: "\E8AB";
}

.icon-ym-systemTemplate:before {
  content: "\E8AC";
}

.icon-ym-sysLog:before {
  content: "\E8AD";
}

.icon-ym-sysConfig:before {
  content: "\E8AE";
}

.icon-ym-header-pannel:before {
  content: "\E7CF";
}

.icon-ym-search:before {
  content: "\E804";
}

.icon-ym-header-message:before {
  content: "\E810";
}

.icon-ym-generator-jobs:before {
  content: "\E819";
}

.icon-ym-clean:before {
  content: "\E83E";
}

.icon-ym-header-IM:before {
  content: "\E899";
}

.icon-ym-header-language:before {
  content: "\E8A3";
}

.icon-ym-flowDone-app:before {
  content: "\E7A6";
}

.icon-ym-flowLaunch-app:before {
  content: "\E7B3";
}

.icon-ym-addFlow:before {
  content: "\E7B8";
}

.icon-ym-flowCopy-app:before {
  content: "\E7BF";
}

.icon-ym-flowTodo-app:before {
  content: "\E8A2";
}

.icon-ym-mobile:before {
  content: "\E7DC";
}

.icon-ym-pc:before {
  content: "\E7EE";
}

.icon-ym-video-play:before {
  content: "\E8A1";
}

.icon-ym-header-collapse1:before {
  content: "\E809";
}

.icon-ym-xitong:before {
  content: "\E814";
}

.icon-ym-full-screen1:before {
  content: "\E7D0";
}

.icon-ym-file-html1:before {
  content: "\E7D1";
}

.icon-ym-generator-area:before {
  content: "\E7D2";
}

.icon-ym-generator-modifytime:before {
  content: "\E7D3";
}

.icon-ym-generator-fold:before {
  content: "\E7D4";
}

.icon-ym-generator-founder:before {
  content: "\E7D5";
}

.icon-ym-generator-line:before {
  content: "\E7D6";
}

.icon-ym-generator-department:before {
  content: "\E7D7";
}

.icon-ym-generator-function:before {
  content: "\E7D8";
}

.icon-ym-generator-notice:before {
  content: "\E7D9";
}

.icon-ym-generator-Provinces:before {
  content: "\E7DA";
}

.icon-ym-generator-popup-attr:before {
  content: "\E7DD";
}

.icon-ym-generator-divider:before {
  content: "\E7DE";
}

.icon-ym-generator-radio:before {
  content: "\E7DF";
}

.icon-ym-generator-menu:before {
  content: "\E7E0";
}

.icon-ym-generator-popup:before {
  content: "\E7E1";
}

.icon-ym-generator-input:before {
  content: "\E7E2";
}

.icon-ym-generator-rich-text:before {
  content: "\E7E3";
}

.icon-ym-generator-Panel:before {
  content: "\E7E4";
}

.icon-ym-generator-table:before {
  content: "\E7E5";
}

.icon-ym-generator-radar:before {
  content: "\E7E6";
}

.icon-ym-generator-password:before {
  content: "\E7E7";
}

.icon-ym-generator-component:before {
  content: "\E7E8";
}

.icon-ym-generator-station:before {
  content: "\E7E9";
}

.icon-ym-generator-rate:before {
  content: "\E7EA";
}

.icon-ym-generator-subFlow1:before {
  content: "\E7EB";
}

.icon-ym-generator-number:before {
  content: "\E7EC";
}

.icon-ym-generator-steps:before {
  content: "\E7ED";
}

.icon-ym-generator-flow:before {
  content: "\E7EF";
}

.icon-ym-generator-color:before {
  content: "\E7F0";
}

.icon-ym-generator-label:before {
  content: "\E7F1";
}

.icon-ym-generator-nature:before {
  content: "\E7F2";
}

.icon-ym-generator-select:before {
  content: "\E7F3";
}

.icon-ym-generator-slider:before {
  content: "\E7F4";
}

.icon-ym-generator-qrcode:before {
  content: "\E7F5";
}

.icon-ym-generator-group:before {
  content: "\E7F6";
}

.icon-ym-generator-time:before {
  content: "\E7F7";
}

.icon-ym-generator-user:before {
  content: "\E7F8";
}

.icon-ym-generator-modifier:before {
  content: "\E7F9";
}

.icon-ym-h5:before {
  content: "\E7FA";
}

.icon-ym-generator-tree:before {
  content: "\E7FB";
}

.icon-ym-gonggao:before {
  content: "\E7FC";
}

.icon-ym-generator-kanban:before {
  content: "\E7FD";
}

.icon-ym-generator-textarea:before {
  content: "\E7FE";
}

.icon-ym-generator-todo:before {
  content: "\E7FF";
}

.icon-ym-header-loginOut:before {
  content: "\E800";
}

.icon-ym-header-expand:before {
  content: "\E801";
}

.icon-ym-generator-time-range:before {
  content: "\E802";
}

.icon-ym-header-lockScreen:before {
  content: "\E803";
}

.icon-ym-generator-switch:before {
  content: "\E805";
}

.icon-ym-header-feedBack:before {
  content: "\E806";
}

.icon-ym-header-skins:before {
  content: "\E807";
}

.icon-ym-global-role:before {
  content: "\E808";
}

.icon-ym-highchartsBullet:before {
  content: "\E80B";
}

.icon-ym-header-userInfo:before {
  content: "\E80C";
}

.icon-ym-generator-upload:before {
  content: "\E80D";
}

.icon-ym-generator-todolist:before {
  content: "\E80E";
}

.icon-ym-highchartsArea:before {
  content: "\E80F";
}

.icon-ym-highchartsFunnel:before {
  content: "\E811";
}

.icon-ym-highcharts:before {
  content: "\E813";
}

.icon-ym-header-about:before {
  content: "\E815";
}

.icon-ym-importAndExportExample:before {
  content: "\E817";
}

.icon-ym-highchartsScatter:before {
  content: "\E818";
}

.icon-ym-header-language1:before {
  content: "\E81A";
}

.icon-ym-mpMenu:before {
  content: "\E81C";
}

.icon-ym-group:before {
  content: "\E81D";
}

.icon-ym-nav-close:before {
  content: "\E820";
}

.icon-ym-highchartsLine:before {
  content: "\E821";
}

.icon-ym-ios:before {
  content: "\E822";
}

.icon-ym-highchartsBellcurve:before {
  content: "\E823";
}

.icon-ym-mpMaterial:before {
  content: "\E824";
}

.icon-ym-mpMessage:before {
  content: "\E825";
}

.icon-ym-message:before {
  content: "\E826";
}

.icon-ym-java:before {
  content: "\E828";
}

.icon-ym-orderDemo:before {
  content: "\E82A";
}

.icon-ym-position-1:before {
  content: "\E82B";
}

.icon-ym-nav-next:before {
  content: "\E82D";
}

.icon-ym-projectExample:before {
  content: "\E82F";
}

.icon-ym-mapExample:before {
  content: "\E833";
}

.icon-ym-qyUser:before {
  content: "\E835";
}

.icon-ym-scanCode1:before {
  content: "\E836";
}

.icon-ym-report-icon-picture:before {
  content: "\E837";
}

.icon-ym-mpConfig:before {
  content: "\E838";
}

.icon-ym-options1:before {
  content: "\E839";
}

.icon-ym-nav-prev:before {
  content: "\E83A";
}

.icon-ym-scheduleExample:before {
  content: "\E83D";
}

.icon-ym-Refresh:before {
  content: "\E840";
}

.icon-ym-options:before {
  content: "\E841";
}

.icon-ym-saolian:before {
  content: "\E844";
}

.icon-ym-shezhi:before {
  content: "\E846";
}

.icon-ym-sysMenu:before {
  content: "\E848";
}

.icon-ym-barCodeExample:before {
  content: "\E73B";
}

.icon-ym-sysLanguage:before {
  content: "\E84A";
}

.icon-ym-report-icon-pie:before {
  content: "\E84B";
}

.icon-ym-bigScreenExample1:before {
  content: "\E73E";
}

.icon-ym-bigDataExample:before {
  content: "\E73F";
}

.icon-ym-orderExample:before {
  content: "\E84D";
}

.icon-ym-preview-excel-paging:before {
  content: "\E84E";
}

.icon-ym-btn-import:before {
  content: "\E741";
}

.icon-ym-tableExample:before {
  content: "\E84F";
}

.icon-ym-btn-collapse:before {
  content: "\E742";
}

.icon-ym-btn-edit:before {
  content: "\E743";
}

.icon-ym-chartExample:before {
  content: "\E744";
}

.icon-ym-btn-refresh:before {
  content: "\E746";
}

.icon-ym-tree-department:before {
  content: "\E855";
}

.icon-ym-checktriangle:before {
  content: "\E747";
}

.icon-ym-btn-fields:before {
  content: "\E748";
}

.icon-ym-tree-department2:before {
  content: "\E858";
}

.icon-ym-btn-export:before {
  content: "\E749";
}

.icon-ym-tree-normal1:before {
  content: "\E859";
}

.icon-ym-btn-preview:before {
  content: "\E74A";
}

.icon-ym-tree-normal:before {
  content: "\E85A";
}

.icon-ym-tree-organization3:before {
  content: "\E85B";
}

.icon-ym-btn-add:before {
  content: "\E74C";
}

.icon-ym-tree-position2:before {
  content: "\E85C";
}

.icon-ym-btn-expand:before {
  content: "\E74E";
}

.icon-ym-tree-user2:before {
  content: "\E85E";
}

.icon-ym-daiban1:before {
  content: "\E74F";
}

.icon-ym-tree-department1:before {
  content: "\E85F";
}

.icon-ym-daka:before {
  content: "\E750";
}

.icon-ym-tableExample1:before {
  content: "\E860";
}

.icon-ym-tree-manage:before {
  content: "\E861";
}

.icon-ym-android:before {
  content: "\E752";
}

.icon-ym-wf-applyDeliverGoods:before {
  content: "\E862";
}

.icon-ym-darg:before {
  content: "\E753";
}

.icon-ym-wechat1:before {
  content: "\E863";
}

.icon-ym-duties:before {
  content: "\E754";
}

.icon-ym-tree-position1:before {
  content: "\E865";
}

.icon-ym-echartsColumn:before {
  content: "\E757";
}

.icon-ym-delete:before {
  content: "\E758";
}

.icon-ym-echartsCandlestick:before {
  content: "\E759";
}

.icon-ym-visual-studio:before {
  content: "\E869";
}

.icon-ym-btn-remove:before {
  content: "\E75A";
}

.icon-ym-wf-conBilling:before {
  content: "\E86A";
}

.icon-ym-echartsFunnel:before {
  content: "\E75B";
}

.icon-ym-wf-batchPack:before {
  content: "\E86B";
}

.icon-ym-tree-department-1:before {
  content: "\E86C";
}

.icon-ym-wf-articlesWarehous:before {
  content: "\E86D";
}

.icon-ym-emailExample:before {
  content: "\E75E";
}

.icon-ym-wf-applyMeeting:before {
  content: "\E86E";
}

.icon-ym-dingdang:before {
  content: "\E75F";
}

.icon-ym-wf-batchTable:before {
  content: "\E86F";
}

.icon-ym-echartsLineArea:before {
  content: "\E760";
}

.icon-ym-wf-applyBanquet:before {
  content: "\E870";
}

.icon-ym-crm:before {
  content: "\E761";
}

.icon-ym-extend-ambulance:before {
  content: "\E762";
}

.icon-ym-wf-documentApproval:before {
  content: "\E872";
}

.icon-ym-echartsScatter:before {
  content: "\E763";
}

.icon-ym-wf-archivalBorrow:before {
  content: "\E874";
}

.icon-ym-dbLink:before {
  content: "\E764";
}

.icon-ym-wf-debitBill:before {
  content: "\E875";
}

.icon-ym-dot-net:before {
  content: "\E765";
}

.icon-ym-weChat:before {
  content: "\E876";
}

.icon-ym-extend-exclamation-triangle:before {
  content: "\E766";
}

.icon-ym-btn-copy:before {
  content: "\E767";
}

.icon-ym-wf-contractApproval:before {
  content: "\E879";
}

.icon-ym-documentExample:before {
  content: "\E768";
}

.icon-ym-echartsLineBar:before {
  content: "\E769";
}

.icon-ym-wf-documentSigning:before {
  content: "\E87B";
}

.icon-ym-extend-clock:before {
  content: "\E76A";
}

.icon-ym-wf-contractApprovalSheet:before {
  content: "\E87D";
}

.icon-ym-extend-check-circle-o:before {
  content: "\E76B";
}

.icon-ym-wf-leaveApply:before {
  content: "\E87F";
}

.icon-ym-wf-letterService:before {
  content: "\E880";
}

.icon-ym-erweima:before {
  content: "\E76D";
}

.icon-ym-wf-officeSupplies:before {
  content: "\E881";
}

.icon-ym-echartsBarAcross:before {
  content: "\E76E";
}

.icon-ym-wf-payDistribution:before {
  content: "\E882";
}

.icon-ym-wf-incomeRecognition:before {
  content: "\E883";
}

.icon-ym-extend-eye:before {
  content: "\E770";
}

.icon-ym-wf-postBatchTab:before {
  content: "\E884";
}

.icon-ym-extend-circle:before {
  content: "\E771";
}

.icon-ym-wf-quotationApproval:before {
  content: "\E885";
}

.icon-ym-extend-bar-chart:before {
  content: "\E772";
}

.icon-ym-wf-rewardPunishment:before {
  content: "\E886";
}

.icon-ym-extend-mail-forward:before {
  content: "\E773";
}

.icon-ym-wf-monthlyReport:before {
  content: "\E887";
}

.icon-ym-extend-car:before {
  content: "\E774";
}

.icon-ym-wf-materialRequisition:before {
  content: "\E888";
}

.icon-ym-extend-meh:before {
  content: "\E775";
}

.icon-ym-wf-procurementMaterial:before {
  content: "\E889";
}

.icon-ym-extend-cog:before {
  content: "\E776";
}

.icon-ym-wf-outboundOrder:before {
  content: "\E88A";
}

.icon-ym-extend-minus-circle:before {
  content: "\E777";
}

.icon-ym-echartsPie:before {
  content: "\E778";
}

.icon-ym-wf-receiptProcessing:before {
  content: "\E88C";
}

.icon-ym-extend-envelope:before {
  content: "\E779";
}

.icon-ym-wf-salesOrder:before {
  content: "\E88D";
}

.icon-ym-compress-screen:before {
  content: "\E77A";
}

.icon-ym-wf-salesSupport:before {
  content: "\E88E";
}

.icon-ym-wf-travelApply:before {
  content: "\E88F";
}

.icon-ym-echartsTree:before {
  content: "\E77C";
}

.icon-ym-wf-paymentApply:before {
  content: "\E890";
}

.icon-ym-extend-star-o:before {
  content: "\E77D";
}

.icon-ym-wf-violationHandling:before {
  content: "\E891";
}

.icon-ym-extend-paper-plane:before {
  content: "\E77E";
}

.icon-ym-extend-history:before {
  content: "\E77F";
}

.icon-ym-wf-purchaseList:before {
  content: "\E893";
}

.icon-ym-echartsGauge:before {
  content: "\E780";
}

.icon-ym-wf-warehouseReceipt:before {
  content: "\E894";
}

.icon-ym-extend-edit:before {
  content: "\E781";
}

.icon-ym-wf-workContactSheet:before {
  content: "\E895";
}

.icon-ym-extend-plane:before {
  content: "\E782";
}

.icon-ym-file-blank:before {
  content: "\E783";
}

.icon-ym-extend-folder-open:before {
  content: "\E784";
}

.icon-ym-wf-travelReimbursement:before {
  content: "\E898";
}

.icon-ym-extend-thumbs-up:before {
  content: "\E785";
}

.icon-ym-extend-truck:before {
  content: "\E786";
}

.icon-ym-wf-supplementCard:before {
  content: "\E89A";
}

.icon-ym-extend-folder:before {
  content: "\E787";
}

.icon-ym-zhanghao:before {
  content: "\E89B";
}

.icon-ym-extend-paperclip:before {
  content: "\E788";
}

.icon-ym-zuzhi:before {
  content: "\E89C";
}

.icon-ym-extend-star:before {
  content: "\E789";
}

.icon-ym-xingcheng:before {
  content: "\E89D";
}

.icon-ym-extend-train:before {
  content: "\E78A";
}

.icon-ym-extend-motorcycle:before {
  content: "\E78B";
}

.icon-ym-wf-vehicleApply:before {
  content: "\E89F";
}

.icon-ym-file-photo:before {
  content: "\E78C";
}

.icon-ym-zujian:before {
  content: "\E8A0";
}

.icon-ym-extend-subway:before {
  content: "\E78D";
}

.icon-ym-extend-envelope-open-o:before {
  content: "\E78E";
}

.icon-ym-file-csv1:before {
  content: "\E790";
}

.icon-ym-extend-pencil:before {
  content: "\E791";
}

.icon-ym-extend-bicycle:before {
  content: "\E792";
}

.icon-ym-extend-save:before {
  content: "\E793";
}

.icon-ym-extend-trash:before {
  content: "\E795";
}

.icon-ym-extend-bus:before {
  content: "\E796";
}

.icon-ym-file-eps:before {
  content: "\E797";
}

.icon-ym-file-ai:before {
  content: "\E798";
}

.icon-ym-extend-share:before {
  content: "\E799";
}

.icon-ym-file-audio:before {
  content: "\E79B";
}

.icon-ym-file-word:before {
  content: "\E79C";
}

.icon-ym-file-pdf:before {
  content: "\E79D";
}

.icon-ym-file-code:before {
  content: "\E79F";
}

.icon-ym-flowCirculate:before {
  content: "\E7A1";
}

.icon-ym-file-ppt:before {
  content: "\E7A4";
}

.icon-ym-file-video:before {
  content: "\E7A5";
}

.icon-ym-file-zip:before {
  content: "\E7A8";
}

.icon-ym-flowDone:before {
  content: "\E7A9";
}

.icon-ym-file-psd:before {
  content: "\E7AA";
}

.icon-ym-gangwei2:before {
  content: "\E7AC";
}

.icon-ym-full-screen:before {
  content: "\E7AD";
}

.icon-ym-file-rar:before {
  content: "\E7AE";
}

.icon-ym-file-visio-1:before {
  content: "\E7AF";
}

.icon-ym-file-movie:before {
  content: "\E7B0";
}

.icon-ym-generator-barcode:before {
  content: "\E7B4";
}

.icon-ym-flowDesign:before {
  content: "\E7B5";
}

.icon-ym-formExample1:before {
  content: "\E7B7";
}

.icon-ym-file-xml:before {
  content: "\E7B9";
}

.icon-ym-flowMonitor:before {
  content: "\E7BA";
}

.icon-ym-extend-rocket:before {
  content: "\E7BC";
}

.icon-ym-file-image:before {
  content: "\E7BD";
}

.icon-ym-generator-bar:before {
  content: "\E7BE";
}

.icon-ym-generator-date-range:before {
  content: "\E7C0";
}

.icon-ym-generator-date:before {
  content: "\E7C1";
}

.icon-ym-generator-card:before {
  content: "\E7C2";
}

.icon-ym-file-visio:before {
  content: "\E7C3";
}

.icon-ym-generator-dictionary:before {
  content: "\E7C4";
}

.icon-ym-generator-email:before {
  content: "\E7C5";
}

.icon-ym-generator-company:before {
  content: "\E7C6";
}

.icon-ym-generator-annular:before {
  content: "\E7C7";
}

.icon-ym-generator-button:before {
  content: "\E7C9";
}

.icon-ym-generator-count:before {
  content: "\E7CA";
}

.icon-ym-generator-layout:before {
  content: "\E7CB";
}

.icon-ym-file-excel:before {
  content: "\E7CC";
}

.icon-ym-generator-cascader:before {
  content: "\E7CD";
}

.icon-ym-generator-checkbox:before {
  content: "\E7CE";
}

.icon-ym-highchartsGauge:before {
  content: "\E856";
}

.icon-ym-wf-outgoingApply:before {
  content: "\E854";
}

.icon-ym-mac:before {
  content: "\E7DB";
}

.icon-ym-appDesign:before {
  content: "\E6F9";
}

.icon-ym-generator-pie:before {
  content: "\E60B";
}

.icon-ym-scanCode:before {
  content: "\E87E";
}

.icon-ym-mpUser:before {
  content: "\E794";
}

.icon-ym-printExample:before {
  content: "\E79A";
}

.icon-ym-qyOrg:before {
  content: "\E79E";
}

.icon-ym-qyMessage:before {
  content: "\E7A0";
}

.icon-ym-generator-subFlow:before {
  content: "\E61B";
}

.icon-ym-reportExample1:before {
  content: "\E7A2";
}

.icon-ym-qyConfig:before {
  content: "\E7A3";
}

.icon-ym-saoyisao:before {
  content: "\E623";
}

.icon-ym-highcharts-Pie:before {
  content: "\E7B6";
}

.icon-ym-report-icon-area:before {
  content: "\E63E";
}

.icon-ym-report-icon-arrow-down:before {
  content: "\E63F";
}

.icon-ym-report-icon-align-left:before {
  content: "\E640";
}

.icon-ym-report-icon-align-top:before {
  content: "\E641";
}

.icon-ym-report-icon-border-all:before {
  content: "\E642";
}

.icon-ym-report-icon-align-bottom:before {
  content: "\E643";
}

.icon-ym-report-icon-align-right:before {
  content: "\E644";
}

.icon-ym-report-icon-bold:before {
  content: "\E645";
}

.icon-ym-report-icon-border-bottom:before {
  content: "\E646";
}

.icon-ym-report-icon-align-center:before {
  content: "\E647";
}

.icon-ym-report-icon-border-right:before {
  content: "\E648";
}

.icon-ym-report-icon-bubble:before {
  content: "\E649";
}

.icon-ym-report-icon-diagram:before {
  content: "\E64A";
}

.icon-ym-report-icon-enlarge:before {
  content: "\E64B";
}

.icon-ym-report-icon-align-middle:before {
  content: "\E64C";
}

.icon-ym-report-icon-preview-pagePre:before {
  content: "\E64E";
}

.icon-ym-report-icon-border-left:before {
  content: "\E64F";
}

.icon-ym-report-icon-font:before {
  content: "\E652";
}

.icon-ym-report-icon-border-top:before {
  content: "\E653";
}

.icon-ym-report-icon-background-color:before {
  content: "\E656";
}

.icon-ym-report-icon-border-custom:before {
  content: "\E657";
}

.icon-ym-report-icon-horizontal-column:before {
  content: "\E65C";
}

.icon-ym-report-icon-column:before {
  content: "\E65D";
}

.icon-ym-report-icon-font-type:before {
  content: "\E65E";
}

.icon-ym-report-icon-import-excel:before {
  content: "\E65F";
}

.icon-ym-report-icon-restore:before {
  content: "\E662";
}

.icon-ym-report-icon-oblique-head:before {
  content: "\E663";
}

.icon-ym-report-icon-preview-pdf:before {
  content: "\E664";
}

.icon-ym-report-icon-preview-pageNext:before {
  content: "\E665";
}

.icon-ym-report-icon-preview-print:before {
  content: "\E666";
}

.icon-ym-report-icon-preview-pageLast:before {
  content: "\E667";
}

.icon-ym-report-icon-preview-excel-paging:before {
  content: "\E669";
}

.icon-ym-report-icon-radar:before {
  content: "\E66A";
}

.icon-ym-report-icon-border-none:before {
  content: "\E66B";
}

.icon-ym-report-icon-preview-word:before {
  content: "\E66C";
}

.icon-ym-report-icon-scatter:before {
  content: "\E66F";
}

.icon-ym-report-icon-preview-printPreview:before {
  content: "\E670";
}

.icon-ym-report-icon-preview-excel-with-paging-sheet:before {
  content: "\E671";
}

.icon-ym-report-icon-fontsize:before {
  content: "\E672";
}

.icon-ym-report-icon-search-setting:before {
  content: "\E673";
}

.icon-ym-report-icon-preview-excel:before {
  content: "\E674";
}

.icon-ym-report-icon-undo:before {
  content: "\E675";
}

.icon-ym-report-icon-shrink:before {
  content: "\E676";
}

.icon-ym-report-icon-configuration:before {
  content: "\E677";
}

.icon-ym-report-icon-preview-refresh:before {
  content: "\E678";
}

.icon-ym-report-icon-underline:before {
  content: "\E679";
}

.icon-ym-report-icon-italic:before {
  content: "\E67B";
}

.icon-ym-report-icon-preview-printPdf:before {
  content: "\E67C";
}

.icon-ym-report-icon-merge-cell:before {
  content: "\E67A";
}

.icon-ym-report-icon-polar-plot:before {
  content: "\E650";
}

.icon-ym-report-icon-page-preview:before {
  content: "\E668";
}

.icon-ym-report-icon-doughnut:before {
  content: "\E66E";
}

.icon-ym-report-icon-preview:before {
  content: "\E655";
}

.icon-ym-pageDesign:before {
  content: "\E684";
}

.icon-ym-customUrl:before {
  content: "\E685";
}

.icon-ym-btn-clearn:before {
  content: "\E80A";
}

.icon-ym-tree-organization1:before {
  content: "\E82E";
}

.icon-ym-generator-documents:before {
  content: "\E878";
}

.icon-ym-generator-createtime:before {
  content: "\E87C";
}