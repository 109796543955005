.SNOWS-button[data-v-d0e30f90] {
  width: 100%;
}
.SNOWS-button.SNOWS-button-left[data-v-d0e30f90] {
  text-align: left;
}
.SNOWS-button.jnpf-button-center[data-v-d0e30f90] {
  text-align: center;
}
.SNOWS-button.jnpf-button-right[data-v-d0e30f90] {
  text-align: right;
}