.dark .el-menu--horizontal .headMenuItem {
  max-width: 150px;
}
.dark .logo-content {
  background-color: #031e39;
}
.dark .avue-sidebar {
  background-color: #001529;
}
.dark .avue-sidebar .el-menu {
  background-color: #001529;
}
.dark .avue-sidebar .el-menu .nest-menu .menu-wrapper {
  background-color: #000c17;
}
.dark .avue-sidebar .el-menu .el-submenu__title i {
  color: #FFFFFF;
}
.dark .avue-sidebar .el-menu .el-submenu__title span {
  color: #FFFFFF;
}
.dark .avue-sidebar .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.dark .avue-sidebar .el-menu .el-submenu__title:hover i {
  color: #1890ff;
}
.dark .avue-sidebar .el-menu .el-submenu__title:hover span {
  color: #1890ff;
}
.dark .avue-sidebar .el-menu .el-menu-item {
  background-color: #000c17;
}
.dark .avue-sidebar .el-menu .el-menu-item i {
  color: #FFFFFF;
}
.dark .avue-sidebar .el-menu .el-menu-item span {
  color: #FFFFFF;
}
.dark .avue-sidebar .el-menu .el-menu-item.is-active {
  background-color: #1890ff !important;
  color: #FFFFFF;
}
.dark .avue-sidebar .el-menu .el-menu-item.is-active:hover span {
  color: #FFFFFF;
}
.dark .avue-sidebar .el-menu .el-menu-item.is-active:hover i {
  color: #FFFFFF;
}
.dark .avue-sidebar .el-menu .el-menu-item:hover i {
  color: #1890ff;
}
.dark .avue-sidebar .el-menu .el-menu-item:hover span {
  color: #1890ff;
}
.dark .el-menu--vertical {
  background-color: #001529;
}
.dark .el-menu--vertical .el-menu--popup {
  max-height: 600px !important;
  overflow: auto !important;
}
.dark .el-menu--vertical .el-menu {
  background-color: #001529;
}
.dark .el-menu--vertical .el-menu .el-menu-item {
  background-color: #001529;
}
.dark .el-menu--vertical .el-menu .el-menu-item i {
  color: #FFFFFF;
}
.dark .el-menu--vertical .el-menu .el-menu-item span {
  color: #FFFFFF;
}
.dark .el-menu--vertical .el-menu .el-submenu__title i {
  color: #FFFFFF;
}
.dark .el-menu--vertical .el-menu .el-submenu__title span {
  color: #FFFFFF;
}
.dark .el-menu--vertical .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.dark .el-menu--vertical .el-menu .el-submenu__title:hover span {
  color: #1890ff;
}
.dark .el-menu--vertical .el-menu .el-submenu__title:hover i {
  color: #1890ff;
}
.dark .el-menu--vertical .el-menu .el-menu-item:hover span {
  color: #1890ff;
}
.dark .el-menu--vertical .el-menu .el-menu-item:hover i {
  color: #1890ff;
}
.dark .el-menu--vertical .el-menu .el-menu-item.is-active {
  background-color: #1890ff;
  color: #FFFFFF;
}
.dark .el-menu--vertical .el-menu .el-menu-item.is-active:hover span {
  color: #FFFFFF;
}
.dark .el-menu--vertical .el-menu .el-menu-item.is-active:hover i {
  color: #FFFFFF;
}
.dark .avue-top {
  color: #FFFFFF;
  background-color: #031e39;
}
.dark .avue-top .avue-top-sidebar .el-menu {
  background-color: #031e39;
}
.dark .avue-top .avue-top-sidebar .el-menu .menu-wrapper .el-menu-item {
  color: #FFFFFF;
}
.dark .avue-top .avue-top-sidebar .el-menu .menu-wrapper .el-menu-item .menuItemBox i {
  color: #FFFFFF;
  margin-right: 10px;
}
.dark .avue-top .avue-top-sidebar .el-menu .menu-wrapper .el-menu-item .menuItemBox i:last-child {
  display: none;
}
.dark .avue-top .avue-top-sidebar .el-menu .menu-wrapper .nest-menu .el-submenu__title {
  color: #FFFFFF;
}
.dark .avue-top .avue-top-sidebar .el-menu .menu-wrapper .nest-menu .el-submenu__title i {
  color: #FFFFFF;
  margin-right: 10px;
}
.dark .avue-top .avue-top-sidebar .el-menu .menu-wrapper .nest-menu .el-submenu__title i:last-child {
  display: none;
}
.dark .avue-top .avue-top-sidebar .el-menu .menu-wrapper .nest-menu .el-submenu__title:hover {
  background-color: transparent;
}
.dark .avue-top .avue-top-sidebar .el-menu .menu-wrapper .nest-menu .el-submenu__title:hover span {
  color: #1890ff;
}
.dark .avue-top .avue-top-sidebar .el-menu .menu-wrapper .nest-menu .el-submenu__title:hover i {
  color: #1890ff;
}
.dark .avue-top .avue-top-sidebar .el-menu .menu-wrapper .nest-menu .headMenuItem {
  max-width: 160px;
}
.dark .el-menu--horizontal {
  background-color: #001529;
}
.dark .el-menu--horizontal .el-menu--popup {
  max-height: 600px;
  overflow: auto;
}
.dark .el-menu--horizontal .el-menu {
  background-color: #001529;
}
.dark .el-menu--horizontal .el-menu .menu-wrapper {
  background-color: #001529;
}
.dark .el-menu--horizontal .el-menu .menu-wrapper .nest-menu {
  width: 100%;
}
.dark .el-menu--horizontal .el-menu .menu-wrapper .nest-menu .el-submenu__title {
  background-color: #001529;
  height: 46px;
  line-height: 46px;
}
.dark .el-menu--horizontal .el-menu .menu-wrapper .nest-menu .el-submenu__title i {
  color: #FFFFFF;
  margin-left: 15px;
  margin-right: 6px;
  font-size: 20px;
}
.dark .el-menu--horizontal .el-menu .menu-wrapper .nest-menu .el-submenu__title span {
  color: #FFFFFF;
}
.dark .el-menu--horizontal .el-menu .el-menu-item {
  background-color: #001529;
  height: 46px;
  line-height: 46px;
  font-size: 16px;
}
.dark .el-menu--horizontal .el-menu .el-menu-item i {
  color: #FFFFFF;
  margin-left: 15px;
  margin-right: 6px;
  font-size: 20px;
}
.dark .el-menu--horizontal .el-menu .el-menu-item span {
  color: #FFFFFF;
}
.dark .el-menu--horizontal .el-menu .el-submenu__title {
  font-size: 16px;
}
.dark .el-menu--horizontal .el-menu .el-submenu__title i {
  color: #FFFFFF;
}
.dark .el-menu--horizontal .el-menu .el-submenu__title span {
  color: #FFFFFF;
}
.dark .el-menu--horizontal .el-menu .el-submenu__title:hover {
  background-color: transparent;
}
.dark .el-menu--horizontal .el-menu .el-submenu__title:hover span {
  color: #1890ff;
}
.dark .el-menu--horizontal .el-menu .el-submenu__title:hover i {
  color: #1890ff;
}
.dark .el-menu--horizontal .el-menu .el-menu-item:hover span {
  color: #1890ff;
}
.dark .el-menu--horizontal .el-menu .el-menu-item:hover i {
  color: #1890ff;
}
.dark .el-menu--horizontal .el-menu .el-menu-item.is-active {
  background-color: #1890ff;
  color: #FFFFFF;
}
.dark .el-menu--horizontal .el-menu .el-menu-item.is-active:hover span {
  color: #FFFFFF;
}
.dark .el-menu--horizontal .el-menu .el-menu-item.is-active:hover i {
  color: #FFFFFF;
}
.dark .top-menu .el-menu-item i {
  color: #FFFFFF !important;
}
.dark .top-menu .el-menu-item span {
  color: #FFFFFF;
}
.dark .blend .el-menu .menu-wrapper .elMenuItem {
  background-color: #001529;
}
.dark .plain .avue-layout {
  padding-left: 80px;
}
.dark .plain .avue-left {
  width: 80px;
}
.dark .plain .el-menu .nest-menu .el-submenu__title {
  height: 70px;
}
.dark .plain .el-menu .nest-menu .el-submenu__title i {
  font-size: 28px;
}