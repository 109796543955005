.columnSettings-drawer .SNOWS-flex-main[data-v-39a4f327] {
  overflow: hidden;
}
.columnSettings-drawer .columnSetting-head[data-v-39a4f327] {
  height: 46px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 24px;
  font-size: 14px;
  color: #303133;
}
.columnSettings-drawer .column-list[data-v-39a4f327] {
  height: 100%;
  padding: 8px 10px 0;
}
.columnSettings-drawer .column-list .column-item[data-v-39a4f327] {
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0;
  font-size: 12px;
  color: #303133;
  cursor: pointer;
  border-bottom: 1px solid #e3e6eb;
}
.columnSettings-drawer .column-list .column-item[data-v-39a4f327]:hover {
  background: rgba(25, 144, 250, 0.1);
}
.columnSettings-drawer .column-list .column-item .column-item-icon[data-v-39a4f327] {
  margin: auto 8px auto 10px;
  color: #909399;
  cursor: move;
  font-size: 14px;
}
.columnSettings-drawer .column-list .column-item .el-switch[data-v-39a4f327] {
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
}
.columnSettings-drawer .column-list .column-item .column-item-label[data-v-39a4f327] {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.columnSettings-drawer .column-list .column-item .check-box[data-v-39a4f327] {
  margin-right: 16px;
}
.columnSettings-drawer .footer[data-v-39a4f327] {
  margin: 4px 20px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}