[data-v-6dfc9e12] #pane-all {
  padding: 0 !important;
  background-color: #FFFFFF;
}
[data-v-6dfc9e12] #pane-department {
  padding: 0 !important;
  background-color: #FFFFFF;
}
[data-v-6dfc9e12] #pane-subordinates {
  padding: 0 !important;
  background-color: #FFFFFF;
}
[data-v-6dfc9e12] .selected-item-text p {
  margin: 0 !important;
}