.avue-searchs[data-v-1ffa9008] {
  padding-top: 50px;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 1024;
}
.avue-searchs__title[data-v-1ffa9008] {
  margin-bottom: 60px;
  text-align: center;
  font-size: 42px;
  font-weight: bold;
  letter-spacing: 2px;
  text-indent: 2px;
}
.avue-searchs__form[data-v-1ffa9008] {
  margin: 0 auto 50px auto;
  width: 50%;
  text-align: center;
}
.avue-searchs__form p[data-v-1ffa9008] {
  margin-top: 20px;
}
.avue-searchs__scrollbar[data-v-1ffa9008] {
  height: 400px;
}
.avue-searchs__list[data-v-1ffa9008] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 20px 30px;
  margin: 0 auto;
  width: 70%;
  border-radius: 4px;
  border: 1px solid #ebeef5;
  background-color: #fff;
  overflow: hidden;
  color: #303133;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.avue-searchs__item[data-v-1ffa9008] {
  padding: 5px 0;
  border-bottom: 1px dashed #eee;
}
.avue-searchs__item-icon[data-v-1ffa9008] {
  margin-right: 5px;
  font-size: 18px;
}
.avue-searchs__item-title[data-v-1ffa9008] {
  font-size: 20px;
  font-weight: 500;
  color: #333;
}
.avue-searchs__item-path[data-v-1ffa9008] {
  line-height: 30px;
  color: #666;
}