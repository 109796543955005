.syButton .el-button {
  padding: 6.5px 15px;
}
.syButton .el-button--text {
  padding: 0;
}
.syButton .CustomIcon {
  vertical-align: bottom;
}
.syButton .padd-l-10 {
  padding-left: 10px;
}
.syButton .padd-r-10 {
  padding-right: 10px;
}