.contextmenu[data-v-5e3841fd] {
  margin: 0;
  background: #fff;
  z-index: 3000;
  position: absolute;
  list-style-type: none;
  padding: 5px 0;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #333;
  -webkit-box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
  left: 105px;
  line-height: normal;
}
.contextmenu li[data-v-5e3841fd] {
  margin: 0;
  padding: 7px 16px;
  cursor: pointer;
}
.contextmenu li[data-v-5e3841fd]:hover {
  background: #eee;
}
[data-v-5e3841fd] #el-drawer__title {
  font-size: 20px;
  padding-left: 20px !important;
}
.contacts-drawer[data-v-5e3841fd] .el-tabs {
  height: 100%;
}
.contacts-drawer[data-v-5e3841fd] .el-tabs .el-tabs__header {
  margin-bottom: 0 !important;
}
.contacts-drawer[data-v-5e3841fd] .el-tabs .el-tabs__item {
  width: 50%;
  text-align: center;
  color: #303133 !important;
}
.contacts-drawer[data-v-5e3841fd] .el-tabs .el-tabs__nav {
  width: 100%;
}
.contacts-drawer[data-v-5e3841fd] .el-tabs .el-tabs__content {
  height: calc(100% - 40px);
}
.contacts-drawer[data-v-5e3841fd] .el-tabs .el-tab-pane {
  height: 100%;
  overflow: hidden;
}
.contacts-drawer .noData-txt[data-v-5e3841fd] {
  font-size: 14px;
  color: #909399;
  line-height: 20px;
  text-align: center;
  padding-top: 20px;
}
.contacts-drawer .search-input[data-v-5e3841fd] .el-input__inner {
  border-radius: 0;
  border-right: none;
  border-left: none;
  border-top: none;
}
.contacts-drawer .search-input .el-input__icon[data-v-5e3841fd] {
  cursor: pointer;
}
.contacts-drawer .search-input .el-input__icon[data-v-5e3841fd]:hover {
  color: #1890ff;
}
.contacts-drawer[data-v-5e3841fd] .el-drawer__header {
  border: none;
}
.contacts-drawer[data-v-5e3841fd] .el-drawer__body {
  overflow: hidden;
}
.contacts-drawer[data-v-5e3841fd] .el-drawer__body .userList {
  overflow: auto;
  overflow-x: hidden;
  position: relative;
  height: calc(100% - 32px);
}
.contacts-drawer[data-v-5e3841fd] .el-drawer__body .userList.replyList {
  height: 100%;
}
.contacts-drawer[data-v-5e3841fd] .el-drawer__body .userList.replyList .title {
  overflow: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 20px;
}
.contacts-drawer[data-v-5e3841fd] .el-drawer__body .userList .el-badge {
  margin-top: 10px;
}
.contacts-drawer[data-v-5e3841fd] .el-drawer__body .userList ::v-deep .el-loading-mask {
  top: 100px;
}
.contacts-drawer[data-v-5e3841fd] .el-drawer__body .userList-item {
  position: relative;
  display: block;
  padding: 0 20px;
  background-color: #fff;
  border-bottom: 1px solid #f5f7f9;
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
}
.contacts-drawer[data-v-5e3841fd] .el-drawer__body .userList-item .offLine {
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
}
.contacts-drawer[data-v-5e3841fd] .el-drawer__body .userList-item:hover {
  background-color: #f5f7f9;
}
.contacts-drawer[data-v-5e3841fd] .el-drawer__body .userList-item .userList-txt {
  margin-left: 14px;
  overflow: hidden;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding-top: 1px;
}
.contacts-drawer[data-v-5e3841fd] .el-drawer__body .userList-item .userList-txt .title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  color: #333;
  margin-bottom: 5px;
  line-height: 20px;
}
.contacts-drawer[data-v-5e3841fd] .el-drawer__body .userList-item .userList-txt .name {
  font-size: 12px;
  color: #999;
}
.contacts-drawer[data-v-5e3841fd] .el-drawer__body .userList-item .userList-txt .name .content {
  display: inline-block;
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.contacts-drawer[data-v-5e3841fd] .el-drawer__body .userList-item .userList-txt .name .time {
  float: right;
}