#avue-view[data-v-b145f1a4] {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px 16px;
  background: #f4f5f6;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.avue-left[data-v-b145f1a4] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.logo-content[data-v-b145f1a4] {
  width: 100%;
  height: 60px;
}
.logo-content img[data-v-b145f1a4] {
  width: 100%;
  height: 100%;
}
.hamburger-container[data-v-b145f1a4] {
  position: absolute;
  width: 100%;
  bottom: 5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  text-align: right;
}
.avue--collapse .hamburger-container[data-v-b145f1a4] {
  text-align: center;
}
.functional[data-v-b145f1a4] {
  padding-left: 0px !important;
}