#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.avue--detail .el-col {
  margin-bottom: 0;
}
.body {
  font-family: PingFangSt;
  font-weight: normal;
}
.el-drawer__header {
  padding: 1px 1px 1px 1px !important;
}
.el-drawer__body {
  padding: 0px 0px 0px 0px !important;
}
.el-card__body {
  padding: 0 12px 10px 12px !important;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.remove-avue-form-menu .avue-form__menu {
  display: none !important;
}