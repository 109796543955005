.SNOWS-selectTree[data-v-09d59a6c] {
  width: 100%;
}
.el-scrollbar .el-scrollbar__view .el-select-dropdown__item[data-v-09d59a6c] {
  height: auto;
  max-height: 274px;
  padding: 0;
  overflow: hidden;
  overflow-y: auto;
}
.el-select-dropdown__item.selected[data-v-09d59a6c] {
  font-weight: normal;
}
ul li[data-v-09d59a6c]::-webkit-scrollbar-track {
  border-radius: 0;
  background-color: #fff;
}
ul li[data-v-09d59a6c] .el-tree .el-tree-node__content {
  height: auto;
  padding: 0 20px;
}
.el-tree-node__label[data-v-09d59a6c] {
  font-weight: normal;
}
.el-tree.single[data-v-09d59a6c] .is-current .el-tree-node__label {
  color: #409eff;
  font-weight: 700;
}
.el-tree.single[data-v-09d59a6c] .is-current .el-tree-node__content .custom-tree-node {
  color: #409eff;
  font-weight: 700;
}
.el-tree.single[data-v-09d59a6c] .is-current .el-tree-node__content .custom-tree-node.is-disabled {
  color: #606266;
  font-weight: normal;
}
.el-tree.single[data-v-09d59a6c] .is-current .el-tree-node__children .custom-tree-node {
  color: #606266;
  font-weight: normal;
}
.el-tree.single[data-v-09d59a6c] .is-current .el-tree-node__children .el-tree-node__label {
  color: #606266;
  font-weight: normal;
}
.empty-text[data-v-09d59a6c] {
  margin: 0;
  text-align: center;
  color: #999;
  font-size: 14px;
  background: #fff;
  cursor: auto;
  padding: 0;
  line-height: 24px;
}