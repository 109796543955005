.basic-container {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.basic-container--block {
  height: 100%;
}
.basic-container--block .basic-container__card {
  height: 100%;
}
.basic-container__card {
  width: 100%;
}
.basic-container:first-child {
  padding-top: 0;
}