.UploadFile-container[data-v-04d653f6] {
  position: relative;
}
.UploadFile-container .el-upload__tip[data-v-04d653f6] {
  line-height: 1.2;
  color: #a5a5a5;
  margin-top: 5px;
}
.el-upload-list__item.el-upload-list__item_detail[data-v-04d653f6]:first-child {
  margin-top: 5px !important;
}
.el-upload-list__item .el-upload-list__item-name[data-v-04d653f6] {
  margin-right: 70px;
}
.el-upload-list__item:hover .el-upload-list__item-status-label.disabled[data-v-04d653f6] {
  display: block !important;
}
.el-upload-list__item .el-icon-download[data-v-04d653f6] {
  display: inline-block;
  position: absolute;
  top: 5px;
  right: 25px;
  cursor: pointer;
  opacity: 0.75;
  color: #606266;
}
.el-upload-list__item .el-icon-view[data-v-04d653f6] {
  display: inline-block;
  position: absolute;
  top: 5px;
  right: 45px;
  cursor: pointer;
  opacity: 0.75;
  color: #606266;
}