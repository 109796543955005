@charset "UTF-8";
.head-layout[data-v-7fe2fec1] {
  background-color: #ffffff;
  border-bottom: 1px solid #cccccc;
  height: 46px;
}
.headLabel[data-v-7fe2fec1] {
  font-size: 14px;
  margin-left: 6px;
  white-space: nowrap; /* 确保文本不会换行 */
  text-overflow: ellipsis; /* 使用省略号表示被截断的文本 */
}
.headLabelTooltip[data-v-7fe2fec1] {
  width: 500px;
  white-space: normal;
  word-break: break-all;
}