.searchFormBox {
  overflow: hidden;
}
.searchFormBox .avue-form__group {
  height: 30px !important;
}
.searchFormBox .searchFormRow {
  background-color: #FFFFFF;
  padding: 10px 12px;
}
.searchFormBox .el-button {
  height: 28px;
}
.searchFormBox .el-date-editor--daterange.el-input__inner {
  width: 100% !important;
}
.searchFormBox .el-range-editor--mini.el-input__inner {
  width: 100%;
}
.searchFormBox .el-input-number--mini {
  width: 100%;
}
.searchFormBox .el-input-number .el-input__inner {
  text-align: left !important;
}