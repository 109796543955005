
.hamburger[data-v-6c966e36] {
  display: inline-block;
  vertical-align: middle;
  font-size: 26px;
  color: #000000;
  cursor: pointer;
}
.hamburger.is-active[data-v-6c966e36] {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
