[data-v-217a6391].el-upload-list--picture-card .el-upload-list__item {
  width: 120px;
  height: 120px;
}
[data-v-217a6391].el-upload--picture-card {
  width: 120px;
  height: 120px;
  line-height: 120px;
}
.upload-btn[data-v-217a6391] {
  display: inline-block;
}