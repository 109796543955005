.jnpf-table-box[data-v-1ff74838] {
  margin-bottom: 0px;
}
.jnpf-table-box .row-action[data-v-1ff74838] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 32px;
}
.jnpf-table-box .row-action .el-icon-delete[data-v-1ff74838] {
  position: absolute;
  opacity: 0;
  z-index: -1;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  cursor: pointer;
  line-height: 3px;
  margin-left: 10px;
}
.jnpf-table-box .actions[data-v-1ff74838] {
  text-align: center;
  border: 1px solid #ebeef5;
  border-top: none;
}
.jnpf-table-box .actions .list-summary[data-v-1ff74838] {
  line-height: 24px;
  overflow: hidden;
  border: 1px solid #e5e5e5;
  border-top: none;
}
.jnpf-table-box.list .list-row[data-v-1ff74838] {
  padding: 18px 0 10px;
  text-align: left;
  border-bottom: 1px solid #e5e5e5;
  position: relative;
}
.jnpf-table-box.list .list-row:hover .delete-btn[data-v-1ff74838] {
  display: block;
}
.jnpf-table-box.list .list-row .delete-btn[data-v-1ff74838] {
  position: absolute;
  right: 10px;
  top: 20px;
  z-index: 999;
  cursor: pointer;
  display: none;
}
.jnpf-table-box.list .list-row .delete-btn[data-v-1ff74838]:hover {
  color: #000;
}
.jnpf-table-box.list .list-row .row-item[data-v-1ff74838] {
  margin-bottom: 18px;
  position: relative;
}
.jnpf-table-box.list .list-row .row-item.error .error-tip[data-v-1ff74838] {
  top: 74%;
  z-index: 1;
}
.jnpf-table-box.list .list-row .row-item.error[data-v-1ff74838] .el-input__inner {
  border-color: #f56c6c;
}
.jnpf-table-box.list .list-row .row-item > div[data-v-1ff74838]:first-child {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #606266;
  line-height: 32px;
  padding: 0 12px 0 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.jnpf-table-box.list .error-tip[data-v-1ff74838] {
  font-size: 12px;
  padding-left: 6px;
  color: #f56c6c;
  position: absolute;
  left: 100px;
  top: 0;
  z-index: -1;
  -webkit-transition: bottom 0.3s;
  transition: bottom 0.3s;
  min-height: auto;
}
.jnpf-table-box.table[data-v-1ff74838].el-table__row:hover .index {
  display: none;
}
.jnpf-table-box.table[data-v-1ff74838].el-table__row:hover .index.btn-disabled {
  display: block;
}
.jnpf-table-box.table[data-v-1ff74838].el-table__row:hover .index.btn-disabled + .el-icon-delete {
  opacity: 0;
  z-index: -1;
}
.jnpf-table-box.table[data-v-1ff74838].el-table__row:hover .el-icon-delete {
  z-index: 9;
  opacity: 1;
}
.jnpf-table-box.table[data-v-1ff74838].el-input-number {
  width: 100%;
  min-width: 120px;
}
.jnpf-table-box.table[data-v-1ff74838].el-table__header th {
  line-height: 1;
}
.jnpf-table-box.table[data-v-1ff74838].el-table .el-table__body td {
  padding: 2px 0;
  background: #fff !important;
  vertical-align: top;
}
.jnpf-table-box.table[data-v-1ff74838].el-table .el-table__body td .error-tip {
  font-size: 12px;
  padding-left: 6px;
  color: #f56c6c;
}
.jnpf-table-box.table[data-v-1ff74838].el-table .el-table__body td .cell {
  position: relative;
}
.jnpf-table-box.table[data-v-1ff74838].el-table .el-table__body td:not(:first-child)::after, .jnpf-table-box.table[data-v-1ff74838].el-table .el-table__body td:not(:first-child)::before {
  content: "";
  width: 10px;
  height: 10px;
  background: white;
  position: absolute;
  border: 2px solid transparent;
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s;
}
.jnpf-table-box.table[data-v-1ff74838].el-table .el-table__body td:not(:first-child)::after {
  border-top: none;
  border-right: none;
  left: 0;
  bottom: 0;
}
.jnpf-table-box.table[data-v-1ff74838].el-table .el-table__body td:not(:first-child)::before {
  border-bottom: none;
  border-left: none;
  right: 0;
  top: 0;
}
.jnpf-table-box.table[data-v-1ff74838].el-table .el-table__body td:not(:first-child):hover::after, .jnpf-table-box.table[data-v-1ff74838].el-table .el-table__body td:not(:first-child):hover::before {
  border-color: red;
}