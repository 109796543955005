
.el-dialog__wrapper[data-v-ae9d397c] {
  overflow: hidden;
}
[data-v-ae9d397c] .el-dialog {
  margin-top: 30px !important;
  height: 90%;
}
[data-v-ae9d397c] .el-dialog__body {
  height: 80%;
}
.chatList__msg--video[data-v-ae9d397c] {
  position: relative;
  max-width: 100%;
  /* min-width: 200px; */
  width: 100%;
  height: 100%;
  /* margin: 10px 0; */
  overflow: hidden;
  border-radius: 5px;
  cursor: pointer;
  display: block;
}

